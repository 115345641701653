import {
  Autocomplete, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Paper, Select,
  Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Tooltip, Typography,
  FormControlLabel, Checkbox
} from '@mui/material'
import React, { Component } from 'react'
import { Colors } from '../../../../Styles/Colors'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import { localGetItem } from '../../../../Utility/Services/CacheProviderService'
import { Days, FoodUsage, Medication_dosages, Months, TabletQty, Weeks, Year, language } from '../../../../Utility/Constants'
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTime } from 'luxon'
import { DataGrid } from '@mui/x-data-grid'
import { DeletePopup } from '../../../../Components/Common Components/CommonComponents'
import { formatDate } from '../../../../Components/CommonFunctions/CommonFunctions'
import ShowComponents from './ShowComponent';
import moment, { months, weekdays } from 'moment/moment'
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Loader from '../../../../Components/Loader'
const Eye_Type = [
  { value: "RE", label: "RE" },
  { value: "LE", label: "LE" },
  { value: "BE", label: "BE" }
]

const Usage_Type = [
  { value: "ES", label: "Empty Stomach" },
  { value: "BF", label: "Before Food" },
  { value: "WF", label: "With Food" },
  { value: "AF", label: "After Food" },
  { value: "EA", label: "External Application" },
  { value: "IA", label: "Internal Application" },
]

export default class DocMedication extends Component {
  constructor(props) {
    super(props)
    this.state = {
      prescriptionTemplateList: [],
      selectedTemplate: '',
      languageList: language,
      selectedLanguage: 'english',
      openPopupAddNew: false,
      tabletQty: TabletQty,
      foodUsage: FoodUsage,
      tempType: '',
      appointmentId: this.props.appointmentId,
      clinicId: this.props.clinicId,
      patientId: this.props.patientId,
      appointmentDate: this.props.appointmentDate,
      isLocked: false,
      prescriptionData: [],
      prescription: [],
      openPrescriptionPopUp: false,
      pharmacyList: [],
      selectedPharmacy: '',
      drugList: [],
      selectedDrug: '',
      searchedDrugList: [],
      searchedBrandName: null,
      searchedGenericName: null,
      genericList: [],
      dosageStrength: '',
      dosageType: '',
      morningQty: '0',
      afternoonQty: '0',
      eveningQty: '0',
      nightQty: '0',
      selectedUsage: '',
      selectedDays: '1',
      selectedDuration: 'Days',
      durationDays: Days,
      durationWeeks: Weeks,
      durationMonts: Months,
      durationYears: Year,
      prescriptionList: [],
      comments: '',
      favourite: false,
      selectedDrugId: null,
      prescriptionId: null,
      dupliPrescriptionData: '[]',
      prescriptionFav: [],
      openFav: false,
      selectedPrescripFav: [],
      openTransmit: false,
      open: false,
      selectedTransmitPharmacy: '',
      selectedFollowup: null,
      openPrevious: false,

      prevPrescripData: [],
      previousPrescripDate: [],
      selectedPrevDate: null,
      page: 0,
      pageSize: 10,
      templateName: '',
      deletePopup: false,
      removeData: false,
      selectedId: null,
      isEdit: false,
      selectedIndex: null,
      transmitted: false,

      openMedicPopUp: false,
      value: "One",
      isOPT: false,
      MedicationFav: [],
      MedicFavorite: [],
      age: "",
      MedDuration: "",
      TapperDrugList: [],
      MedicDrugList: [],
      tapp_Gen_Details: {},
      Medic_Gen_Details: {},
      taperFromDate: new Date(),
      Frequency: "",
      tapDuration: "",
      durationType: "Day(s)",
      TapperUnits: "",
      MedicUnits: "",
      unitType: "",
      reducedBy: "",
      tapperQty: "",
      tapperComm: "",
      is_MefiFav: false,
      is_TapperFav: false,
      taperAddedDetails: [],
      selectedEditIndex: null,
      TapperMedList: [],
      Medfrequency: "",
      frequencyTemplateList: [],
      NewText: "",
      selectedText: false,
      MedQty: "",
      medCommends: "",
      Morning: "",
      Afternoon: "",
      Evening: "",
      Night: "",
      MedUsage: "",
      MedicData: [],
      eyeType: "",
      no_of_Dosage: null,
      openPrevsMedic: false,
      MedicationTemplateData: [],
      MedicationTemplateList: [],
      previousMedicData: [],
      editItem: null,
      ExternalDrugAdd: false,
      External_Drug_Details: {
        DrugName: "",
        GenericName: "",
        DosageStrength: "",
      },
      EditIndex: null,
      AddClicked: false,
      FrequencyText: "",
      DrugSuggestionList: [],
      AllowEdit: false,
      Recalled: true,
      recallPopup: false,
      ExternalBrandName: null,
      ExternalDrugList: [],
      GenericSuggestionList: [],
      GenericNameSearch: null,
      GenericSearch: "",
      newMedication: false,
      selectedPrescripID: null,
      RecallPresId: null,
      frequency_tablet: "",
      frequency_list: [],

      // New Tapper Med Screen State
      newTapMedListData: [],
      newTapSearchDrug: null,
      newTapMedDate: new Date(),
      newTapMedOpenDate: false,
      newTapMedDrugListData: [],
      newTapMedDuration: '',
      newTapDurationType: 'Day(s)',
      newTapMedUnit: "",
      newTapMedUnitType: '',
      newTapMedFrequency: '',
      newTapMedReduceBy: '',
      newTapMedFreqTablet: null,
      newTapMedQty: '',
      newTapMedUsage: '',
      newTapMedComments: '',
      newTapMedGenList: null,
      isNewTapEdit: false,
      newTapMedSelectedIndex: null,
      leftSelectedIndex: null,
      dupTapMedDetails: '{}',
      newLftTapEdit: false,
      newTapMedId: null,
      newTapMedIsFav: false,
      AddClicked: false,
      isChange: false,
      
      tapUsage:'',
      drugSearchFor:1,
      editTemplate: true,
      templateId: null,
      optTemplateId: null,
      dupTapperMedList: '[]',
      dupPrescriptionData: '[]'
    }
  }

  componentDidMount(){
    let drugSearch = localGetItem('drug_search') !== 'undefined' ? JSON.parse(localGetItem('drug_search')) : 1
    this.setState({ drugSearchFor: drugSearch })
  }

  apiCalls = () => {
    this.getPrescriptionFavouriteData()
    let ServiceType = localGetItem("loggedInUserInfo")
    let myUser = JSON.parse(ServiceType)
    this.setState({
      isOPT: myUser.service_type === "OPT" ? true : false
    }, () => {
      this.getTapperMed()
      this.getMedicFrequency()
      this.getMedicationListData()
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLocked !== this.props.isLocked) {
      this.setState({ isLocked: this.props.isLocked })
    }
  }

  getPrescriptionListData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_PRESCRIPTION_TEMPLATE)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              prescriptionTemplateList: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getMedicationListData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_MEDIC_TEMPLATE_GET_POST)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              MedicationTemplateList: response.data.data
            }, () => {
              this.state.MedicationTemplateList?.forEach(element => element.template_line_id = element.id)
              this.setState({ MedicationTemplateList: this.state.MedicationTemplateList })
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  addFrequencyKey = (Data) => {
    const newArray = Data.map((obj) => {
      if (!obj.hasOwnProperty('no_of_drops')) {
        obj = { ...obj, no_of_drops: null };
      }

      return obj;
    });

    return newArray
  };

  PostNewMedicTemplate = () => {
    let states = this.state
    try {
      let data = {}
      if (this.state.tempType === 'Add') {
        data = {
          "template_name": states?.templateName,
          "prescription": this.addFrequencyKey(this.state.TapperMedList[this.state.TapperMedList.length - 1]?.prescription_lines),
          "pharmacy_id": states?.selectedPharmacy,
          "template_id": states?.templateId
        }
      } else {
        this.state.TapperMedList[this.state.TapperMedList.length - 1]?.prescription_lines?.forEach(element => {
          element.template_line_id = element.template_id === this.state.templateId ? element.template_line_id : null
        })
        let deletedIds = []
        this.state.deletedIds?.forEach(element => {
          this.state.TapperMedList[this.state.TapperMedList.length - 1]?.prescription_lines?.forEach(item => {
            if (element.template_id === item?.template_id) {
              deletedIds?.push(element?.template_line_id)
            }
          })
        })

        data = {
          "template_name": states?.templateName,
          "template_id": states?.templateId,
          'prescription': states.editTemplate ? this.addFrequencyKey(this.state.TapperMedList[this.state.TapperMedList.length - 1]?.prescription_lines) : [],
          'deleted_ids': deletedIds ? deletedIds : []
        }
      }
      if ((this.state.TapperMedList[this.state.TapperMedList.length - 1]?.prescription_lines?.length === 0 && this.state.tempType === 'Add')) {
        this.errorMessage("Template Should not be Empty")
      } else if (states?.templateName == "") {
        this.errorMessage("Enter Template Name");
      } else {
        RestAPIService.create(data, Serviceurls.DOC_MEDIC_TEMPLATE_GET_POST)
          .then((response) => {
            if (response.data.status === 'success') {
              this.successMessage(response.data.message)
              this.getTapperMed()
              this.getMedicationListData()
              this.clearTapperDetails()
              this.setState({ openMedicPopUp: false, openPopupAddNew: false, editTemplate: true })
              this.setState({ selectedTemplate: '', templateName: '', templateId: '' })
            }
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.errorMessage(error.response.data.message)
            } else {
              this.errorMessage(error.message)
            }
          })
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  modifyTemplate = (Array) => {
    const keysToRemove = ["id"];
    var newArrayWithoutKey = Array.map(obj => {
      obj.opt_template_line_id = obj.line_id
      const newObj = Object.keys(obj).reduce((accumulator, key) => {
        if (!keysToRemove.includes(key)) {
          accumulator[key] = obj[key];
        }
        return accumulator;
      }, {});

      newObj["frequency"] = obj["frequency"] == undefined ? null : obj["frequency"];
      return newObj;
    })
    return newArrayWithoutKey
  }

  MedicationTemplateSave = () => {
    let states = this.state
    try {

      var NewArray = this.modifyTemplate(this.state.MedicationTemplateData)
      let data = {
        "appointment_id": states?.appointmentId,
        "patient_id": states?.patientId,
        "line_items": this.state.TapperMedList?.prescription_lines?.length > 0 ? [...NewArray, ...this.state.TapperMedList?.prescription_lines] : NewArray,
        "followup_date": "",
        "deleted_line_ids": [],
        "pharmacy_id": states?.selectedPharmacy,
        "from_temp": true
      }
      if (this.state.prescriptionId) {
        data["prescription_id"] = this.state.prescriptionId
      }
      if (this.state.MedicationTemplateData.length > 0) {
        RestAPIService.create(data, Serviceurls.DOC_OPT_TAPPER_DETAILS_GET_POST)
          .then((response) => {
            if (response.data.status === 'success') {
              this.successMessage(response.data.message)
              this.getTapperMed()
              this.clearTapperDetails()
              this.setState({ openMedicPopUp: false })
            }
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.errorMessage(error.response.data.message)
            } else {
              this.errorMessage(error.message)
            }
          })
      } else {
        this.errorMessage("Template Should not be Empty");
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getMedicationTemplateData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_MEDIC_TEMPLATE_GET_POST + "?template_id=" + this.state.selectedTemplate?.id)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              MedicationTemplateData: response.data.data
            }, () => {
              this.MedicationTemplateSave()
                          })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getMedicFrequency = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_OPT_MEDICATION_FREQUENCY)
        .then((response) => {
          if (response?.data?.status === 'success') {
            let data = response.data.data
            const uniqueArray = [...new Set(data)];
            this.setState({
              frequencyTemplateList: uniqueArray,
              frequency_list: response.data?.tapper_data ? response.data?.tapper_data : []
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  FindTransmittedIndex(data) {
    for (let i = 0; i < data.length; i++) {
      if (!data[i].is_transmitted) {
        return i;
      }
    }
    return -1;
  }

  getTapperMed = (FromPopup) => {
    try {
      this.setState({ DisableBtn: true })
      RestAPIService.getAll(Serviceurls.DOC_OPT_TAPPER_DETAILS_GET_POST + `?patient_id=${this.props.patientId}&appointment_id=${this.props.appointmentId}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            let Index = this.FindTransmittedIndex(response.data.data)
            const tapperData = response.data.data;
            const pharmacyId = tapperData[0]?.prescription_lines[0]?.pharmacy_id !== this.state.selectedPharmacy ?
              tapperData[0]?.prescription_lines[0]?.pharmacy_id : this.state.selectedPharmacy;
            this.setState({
              prescriptionData: tapperData,
              TapperMedList: tapperData,
              prescriptionId: tapperData[Index]?.prescription_id,
              transmitted: tapperData[tapperData?.length - 1]?.is_transmitted,
              Recalled: tapperData[tapperData?.length - 1]?.is_billed_in_pharmacy,
              DisableBtn: false,
              selectedPharmacy: pharmacyId,
              selectedTransmitPharmacy: pharmacyId,
              dupTapperMedList: JSON.stringify(tapperData),
              dupPrescriptionData: JSON.stringify(tapperData),
            }, () => {
              if (FromPopup) {
                let LineItems = []
                if (this.state.prescriptionData?.prescription_lines) {
                  this.state.prescriptionData?.prescription_lines?.forEach((item, index) => {
                    if (item?.tapper_meds?.length === 0) {
                      LineItems.push(item)
                    }
                  })
                }
                this.setState({ MedicData: LineItems, AddClicked: true }, () => { this.clearTapperDetails() })
              }
              if (response.data.data?.is_transmitted) {
                this.props.MedicTransmited(true)
              } else if (response.data.data?.is_transmitted == false) {
                this.props.MedicTransmited(false)
              }
            })
          }
        }).catch((error) => {
          this.setState({ DisableBtn: false })
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getPharmacyList = (prevPrescrip = false, formTransmit = false, PrescriptionItem) => {
    try {
      // RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_PHARMACY_LIST_GET + `?clinic_id=${this.props.clinicId}&suggest=true`)
      //   .then((response) => {
      //     if (response?.data?.status === 'success') {
      //       if (formTransmit) {
      //         this.setState({
      //           pharmacyList: response.data?.data,
      //           selectedPharmacy: response?.data?.data[0]?.pharmacy_id
      //         }, () => {
      //           if (response.data?.data?.length > 1) {
      //             this.setState({
      //               openTransmit: true
      //             })
      //           } else {
      //             if (!this.state.DisableBtn) { this.postPrescriptionTransmit() }
      //           }
      //         })
      //       } else {
      //         let prescriptionData = this.state.prescriptionData[this.state.prescriptionData.length - 1]?.prescription_id ? this.state.prescriptionData[this.state.prescriptionData.length - 1]?.prescription_id : this.state.selectedPharmacy
      //         if (prescriptionData) {
      //           if (this.state.tempType == "Add") {
      //             this.setState({
      //               pharmacyList: response.data?.data,
      //               selectedPharmacy: response?.data?.data[0]?.pharmacy_id,
      //               prescriptionList: this.state.prescriptionData?.prescription_lines ? this.state.prescriptionData?.prescription_lines : this.state.prescriptionData ? this.state.prescriptionData : []
      //             })
      //           } else if (this.state.isOPT) {
      //             this.setState({
      //               openMedicPopUp: true,
      //               pharmacyList: response.data?.data,
      //               selectedPharmacy: response?.data?.data[0]?.pharmacy_id,
      //               prescriptionList: PrescriptionItem?.prescription_lines ? PrescriptionItem?.prescription_lines : PrescriptionItem ? PrescriptionItem : []
      //             }, () => {
      //               if (this.state.editItem) {
      //                 this.onEditMedic(this.state.editItem)
      //               } else {
      //                 let LineItems = []
      //                 if (PrescriptionItem?.prescription_lines) {
      //                   PrescriptionItem?.prescription_lines?.forEach((item, index) => {
      //                     if (item?.tapper_meds?.length === 0) {
      //                       LineItems.push(item)
      //                     }
      //                   })
      //                 }
      //                 this.setState({ MedicData: LineItems, AddClicked: true })
      //               }
      //               this.getMedicationFavouriteData()
      //               this.getMedFavouriteData()
      //             })
      //           }
      //         } else if (prevPrescrip) {
      //           if (this.state.isOPT) {
      //             this.setState({
      //               openPrevious: false,
      //               openPrevsMedic: false,
      //               openMedicPopUp: true,
      //               pharmacyList: response.data?.data,
      //               selectedPharmacy: response?.data?.data[0]?.pharmacy_id
      //             }, () => {
      //               this.getMedicationFavouriteData()
      //               this.getMedFavouriteData()
      //             })
      //           } else {
      //             this.setState({
      //               openPrevious: false,
      //               openPrevsMedic: false,
      //               openPrescriptionPopUp: true,
      //               pharmacyList: response.data?.data,
      //               selectedPharmacy: response?.data?.data[0]?.pharmacy_id
      //             }, () => {
      //               this.getPrescriptionFrequentList()
      //             })
      //           }
      //         } else {
      //           if (this.state.isOPT) {
      //             this.setState({
      //               openMedicPopUp: true,
      //               AddClicked: true
      //             }, () => {
      //               this.setState({
      //                 pharmacyList: response.data?.data,
      //                 selectedPharmacy: response?.data?.data[0]?.pharmacy_id
      //               }, () => {
      //                 this.getMedicationFavouriteData()
      //                 this.getMedFavouriteData()
      //               })
      //             })
      //           } else {
      //             this.setState({
      //               openPrescriptionPopUp: true
      //             }, () => {
      //               this.setState({
      //                 pharmacyList: response.data?.data,
      //                 selectedPharmacy: response?.data?.data[0]?.pharmacy_id
      //               }, () => {
      //                 this.getPrescriptionFrequentList()
      //               })
      //             })
      //           }
      //         }
      //       }
      //     }
      //   }).catch((error) => {
      //     if (error?.response?.data?.message) {
      //       this.errorMessage(error.response.data.message)
      //     } else {
      //       this.errorMessage(error.message)
      //     }
      //   })
      let LoggedData = localGetItem("loggedInUserInfo") ? JSON.parse(localGetItem("loggedInUserInfo")) : null;
      let pharmaList = LoggedData?.pharmacy_data ? JSON.parse(JSON.stringify(LoggedData?.pharmacy_data)) : [];
      if (pharmaList?.length) {
        if (formTransmit) {
          this.setState({
            pharmacyList: pharmaList,
            selectedPharmacy: pharmaList[0]?.pharmacy_id
          }, () => {
            if (pharmaList?.length > 1) {
              this.setState({
                openTransmit: true
              })
            } else {
              if (!this.state.DisableBtn) { this.postPrescriptionTransmit() }
            }
          })
        } else {
          let prescriptionData = this.state.prescriptionData[this.state.prescriptionData.length - 1]?.prescription_id ?
            this.state.prescriptionData[this.state.prescriptionData.length - 1]?.prescription_id : this.state.selectedPharmacy
          if (prescriptionData) {
            if (this.state.tempType == "Add") {
              this.setState({
                pharmacyList: pharmaList,
                selectedPharmacy: pharmaList[0]?.pharmacy_id,
                prescriptionList: this.state.prescriptionData?.prescription_lines ?
                  this.state.prescriptionData?.prescription_lines : this.state.prescriptionData ? this.state.prescriptionData : []
              })
            } else if (this.state.tempType === 'rename') {
              this.setState({
                selectedPharmacy: pharmaList[0]?.pharmacy_id
              }, () => {
                this.setState({
                  openPopupAddNew: true
                })
              })
            } else if (this.state.isOPT) {
              this.setState({
                openMedicPopUp: true,
                pharmacyList: pharmaList,
                selectedPharmacy: pharmaList[0]?.pharmacy_id,
                prescriptionList: PrescriptionItem?.prescription_lines ?
                  PrescriptionItem?.prescription_lines : PrescriptionItem ? PrescriptionItem : []
              }, () => {
                if (this.state.editItem) {
                  this.onEditMedic(this.state.editItem)
                } else {
                  let LineItems = []; let tapMedLineItem = [];
                  if (PrescriptionItem?.prescription_lines) {
                    PrescriptionItem?.prescription_lines?.forEach((item, index) => {
                      if (item?.tapper_meds?.length === 0) {
                        LineItems.push(item)
                      } else {
                        tapMedLineItem?.push(item)
                      }
                    })
                  }
                  this.setState({
                    MedicData: LineItems,
                    AddClicked: true,
                    selectedPharmacy: LineItems?.length > 0 ? LineItems[0]?.pharmacy_id :
                      this.state.selectedPharmacy,
                    newTapMedListData: tapMedLineItem
                  })
                  this.getMedicationFavouriteData()
                this.getMedFavouriteData()
                }})
              } else if (prevPrescrip) {
            if (this.state.isOPT) {
              this.setState({
                openPrevious: false,
                openPrevsMedic: false,
                openMedicPopUp: true,
                pharmacyList: pharmaList,
                selectedPharmacy: pharmaList[0]?.pharmacy_id
              }, () => {
                this.getMedicationFavouriteData()
                this.getMedFavouriteData()
              })
            } else {
              this.setState({
                openPrevious: false,
                openPrevsMedic: false,
                openPrescriptionPopUp: true,
                pharmacyList: pharmaList,
                selectedPharmacy: pharmaList[0]?.pharmacy_id
              }, () => {
                this.getPrescriptionFrequentList()
              })
            }
          } else {
            if (this.state.isOPT) {
              this.setState({
                openMedicPopUp: true,
                AddClicked: true
              }, () => {
                this.setState({
                  pharmacyList: pharmaList,
                  selectedPharmacy: pharmaList[0]?.pharmacy_id
                }, () => {
                  this.getMedicationFavouriteData()
                  this.getMedFavouriteData()
                })
              })
            } else {
              this.setState({
                openPrescriptionPopUp: true
              }, () => {
                this.setState({
                  pharmacyList: pharmaList,
                  selectedPharmacy: pharmaList[0]?.pharmacy_id
                }, () => {
                  this.getPrescriptionFrequentList()
                })
              })
            }
          }
        }}
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getPrescriptionFrequentList = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_PRESCRIPTION_FEQ + `?pharmacy_id=${this.state.selectedPharmacy}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              drugList: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getPharmacyDrugSearchList = (brandName) => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_DRUG_SEARCH + `?brand_name=${brandName}&generic_name=${this.state.searchedGenericName}&clinic_id=${this.props.clinicId}&pharmacy_id=${this.state.selectedPharmacy}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              searchedDrugList: response?.data?.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getTapperDrugSearchList = (brandName) => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_OPT_FREQUENT_MEDICATION + `?brand_name=${brandName}&clinic_id=${this.props.clinicId}&pharmacy_id=${this.state.selectedPharmacy}&is_tapper_meds=true`)
        .then((response) => {
          if (response.data.status === 'success') {
            let BrandName = response?.data?.data.map(item => item?.drug_name)
            this.setState({
              TapperDrugList: BrandName
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getExternalDrugSearchList = (brandName, genericName) => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_OPT_EXTERNAL_DRUG + `?brand_name=${brandName}&generic_name=${genericName}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              ExternalDrugList: response?.data?.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getMedicDrugSearchList = (brandName, genericName) => {
    try {
      let params = ''
      if(this.state.drugSearchFor === 1){
        params = `?brand_name=${brandName}&generic_name=${genericName}&clinic_id=${this.props.clinicId}&pharmacy_id=${this.state.selectedPharmacy}`
      }else if(this.state.drugSearchFor === 2){
        params = `?brand_name=&generic_name=${brandName}&clinic_id=${this.props.clinicId}&pharmacy_id=${this.state.selectedPharmacy}`
      }else{
        params = `?search_key=${brandName}&brand_name=&generic_name=&clinic_id=${this.props.clinicId}&pharmacy_id=${this.state.selectedPharmacy}`
      }
      RestAPIService.getAll(Serviceurls.DOC_OPT_FREQUENT_MEDICATION + params)
        .then((response) => {
          if (response.data.status === 'success') {
            if (brandName) {
              let Newarray = response?.data?.data
              // const uniqueArray = Newarray.filter((item, index, self) => index === self.findIndex((t) => t.brand === item.brand));
              this.setState({ MedicDrugList: Newarray, searchedBrandName: "" })
            } else {
              this.setState({ GenericSuggestionList: response?.data?.data, GenericNameSearch: "" })
            }
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getTapperDrugDetails = (isForEdit = false, FromGeneric, DosageType) => {
    try {
      let brandName = this.state.selectedDrug ? this.state.selectedDrug : this.state.newTapSearchDrug ? this.state.newTapSearchDrug : ''
      RestAPIService.getAll(Serviceurls.DOC_OPT_MEDICATION_DETAILS + `?brand_name=${brandName}&clinic_id=${this.props.clinicId}&pharmacy_id=${this.state.selectedPharmacy}`)
        .then((response) => {
          if (response.data.status === 'success') {
            var strength = response?.data?.data[0]['dosage_strength'] ? response?.data?.data[0]['dosage_strength'] : '';
            var numericAlone = strength.match(/(\d+)/);
            if (this.state.value === "One") {
              if (FromGeneric && DosageType) {
                const results = response?.data?.data.filter(item => (item?.generic_name?.toLowerCase() === FromGeneric?.toLowerCase() && item?.dosage_type?.toLowerCase() === DosageType?.toLowerCase()));
                this.setState({
                  searchedBrandName: { drug_name: results[0]?.drug_name, generic_name: results[0]?.generic_name },
                  GenericNameSearch: { drug_name: results[0]?.drug_name, generic_name: results[0]?.generic_name },
                }, () => {
                  if (isForEdit) {
                    this.setState({
                      DrugSuggestionList: response?.data?.data,
                    })
                  } else {
                    this.setState({
                      DrugSuggestionList: response?.data?.data,
                      selectedDrugId: response?.data?.data?.length > 0 ? results[0]?.drug_id : this.state.selectedDrugId,
                      Medic_Gen_Details: response?.data?.data?.length > 0 ? results[0] : this.state.Medic_Gen_Details,
                    }, () => {
                      this.autofetchDerugDetails(this.state.Medic_Gen_Details)
                    })
                  }
                })
              }
              else {
                this.setState({
                  searchedBrandName: { drug_name: response?.data?.data[0]?.drug_name, generic_name: response?.data?.data.length > 1 ? "" : response?.data?.data[0]?.generic_name },
                  GenericNameSearch: { drug_name: response?.data?.data[0]?.drug_name, generic_name: response?.data?.data.length > 1 ? "" : response?.data?.data[0]?.generic_name },
                  GenericSuggestionList: response?.data?.data.length > 1 ? response?.data?.data : []
                }, () => {
                  if (isForEdit) {
                    this.setState({
                      DrugSuggestionList: response?.data?.data,
                    })
                  } else {
                    this.setState({
                      DrugSuggestionList: response?.data?.data,
                      selectedDrugId: response?.data?.data?.length > 0 ? response?.data?.data[0]?.drug_id : this.state.selectedDrugId,
                      Medic_Gen_Details: response?.data?.data?.length > 1 ? null : response?.data?.data?.length == 1 ? response?.data?.data[0] : this.state.Medic_Gen_Details,
                    }, () => {
                      this.autofetchDerugDetails(this.state.Medic_Gen_Details)
                    })
                  }
                })
              }
            } else if (this.state.value === "Two") {
              this.setState({
                tapp_Gen_Details: response?.data?.data[0],
                TapperUnits: this.state.isEdit ? "" : (response?.data?.data[0]['dosage_type'] ? (response?.data?.data[0]['dosage_type']).toUpperCase() === "STEROID TABLETS" ? numericAlone[0] : (response?.data?.data[0]['dosage_type']) === "Drop" ? "1" : "" : ""),
                is_TapperFav: response?.data?.data[0]['is_favorite'],

                newTapMedGenList: response?.data?.data[0],
                newTapMedUnit: this.state.isEdit ? "" : (response?.data?.data[0]['dosage_type'] ? (response?.data?.data[0]['dosage_type']).toUpperCase() === "STEROID TABLETS" ? numericAlone[0] : (response?.data?.data[0]['dosage_type']) === "Drop" ? "1" : "" : ""),
                newTapMedIsFav: response?.data?.data[0]['is_favorite']
              }, () => { this.onClearLeftTapperDetails() })
            }
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  autofetchDerugDetails = (item) => {
    let Pre_durgList = item?.latest_prescription ? item?.latest_prescription : {}
    if (Object.keys(Pre_durgList).length > 0) {
      const [numStr, textStr] = Pre_durgList?.duration.split(" ");
      this.setState({
        eyeType: Pre_durgList?.eye_type,
        Medfrequency: Pre_durgList?.frequency__description,
        MedDuration: numStr,
        selectedDuration: textStr,
        MedUsage: Pre_durgList?.instructions,
        MedQty: Pre_durgList?.quantity,
        medCommends: Pre_durgList?.comments,
      })
    }
  }

  getGenericNameList = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_GENERIC_NAME_GET + `?brand_name=${this.state.selectedDrug}&generic_name=&clinic_id=${this.props.clinicId}&pharmacy_id=${this.state.selectedPharmacy}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              genericList: response.data.data,
              searchedGenericName: response?.data?.data?.length === 1 ? response?.data?.data[0] : '',
            }, () => {
              this.setState({
                dosageStrength: this.state.searchedGenericName?.dosage_strength,
                dosageType: this.state.searchedGenericName?.dosage_type
              })
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getPrescriptionPrint = () => {
    try {
      this.setState({ DisableBtn: true })
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_PRESCRIPTION_PRINT + `?prescription_id=${this.state.prescriptionId}&clinic_id=${this.props.clinicId}&language=${this.state.selectedLanguage}&appointment_id=${this.props.appointmentId}`)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({ DisableBtn: false })
        }).catch((error) => {
          this.setState({ DisableBtn: false })
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getMedicationPrint = (id) => {
    try {
      this.setState({ DisableBtn: true })
      RestAPIService.getAllTwo(Serviceurls.OPT_PRESCRIPTION_PRINT + `?prescription_id=${id}&clinic_id=${this.props.clinicId}&language=${this.state.selectedLanguage}&appointment_id=${this.props.appointmentId}`)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({ DisableBtn: false })
        }).catch((error) => {
          this.setState({ DisableBtn: false })
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getPrescriptionFavouriteData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_PRESCRIPTION_FAV)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              prescriptionFav: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getMedicationFavouriteData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_OPT_FREQUENT_MEDICATION + `?pharmacy_id=${this.state.selectedPharmacy}&is_tapper_meds=true`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            let BrandName = response?.data?.data.map(item => item?.drug_name)
            this.setState({
              MedicationFav: BrandName
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getMedFavouriteData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_OPT_FREQUENT_MEDICATION + `?pharmacy_id=${this.state.selectedPharmacy}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            let Newarray = response?.data?.data
            const uniqueArray = Newarray.filter((item, index, self) =>
              index === self.findIndex((t) => t.drug_name === item.drug_name)
            );
            let BrandName = uniqueArray.map(item => item?.drug_name)
            this.setState({
              MedicFavorite: BrandName
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getPreviousPrescriptionDates = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_PREVIOUS_PRESCRIPTION_APPOINTMENT_DATE_GET + `?patient_id=${this.props.patientId}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              previousPrescripDate: response.data.prescription,
              selectedPrevDate: response?.data?.prescription[0],
              selectedPrevAppointmentId: response?.data?.prescription[0]?.appointment
            }, () => {
              if (this.state.previousPrescripDate?.length > 0) {
                this.getPreviousPrescriptionDatas()
              }
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getPreviousMedicDatas = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_OPT_MEDICATION_HISTORY + `?patient_id=${this.props.patientId}&appointment_id=${this.props.appointmentId}&appointment_date=${this.props.appointmentDate}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              previousMedicData: response.data.data,
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getPreviousPrescriptionDatas = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_PREVIOUS_PRESCRIPTION_APPOINTMENT + `?patient_id=${this.props.patientId}&appointment_id=${this.state.selectedPrevDate?.appointment}&date=${this.state.selectedPrevDate?.prescription_date}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              prevPrescripData: response.data.prescription_lines
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getPreviousPrescriptionPrint = () => {
    try {
      let appointmentId = this.state.selectedPrevDate?.appointment ? this.state.selectedPrevDate?.appointment : this.props.appointmentId
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_PREVIOUS_PRESCRIPTION_APPOINTMENT + `?patient_id=${this.props.patientId}&appointment_id=${appointmentId}&date=${this.state.selectedPrevDate?.prescription_date}&export_type=pdf`)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onDeleteMedic = (item, FromPopup = false) => {
    try {
      RestAPIService.deleteAll(`${Serviceurls.DOC_MEDICATION_DLT}?id=${item?.id}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response?.data?.message)
            this.getTapperMed(FromPopup)
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  postTemplate = () => {
    try {
      let data = {}
      if (this.state.tempType === 'Add') {
        let prescriptionList = []
        this.state.prescriptionData?.prescription_lines?.forEach(element => {
          prescriptionList?.push({
            afternoon: +element?.afternoon,
            dosage_strength: element?.dosage_strength,
            dosage_type: element?.dosage_type,
            drug_name: element?.brand_name,
            duration: element?.duration,
            duration_mode: element?.duration_mode,
            evening: +element?.evening,
            generic_name: element?.generic_name,
            instructions: element?.instructions,
            morning: +element?.morning,
            night: +element?.night
          })
        })
        data = {
          pharmacy_id: this.state.selectedPharmacy,
          template_name: this.state.templateName,
          prescription: prescriptionList
        }
      }
      if (this.state.templateName === '') {
        this.errorMessage('Enter Template Name')
      } else {
        RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_PRESCRIPTION_TEMPLATE)
          .then((response) => {
            if (response.data.status === 'success') {
              this.successMessage(response.data.message)
              this.getPrescriptionListData()
              this.setState({ tempType: '', openPopupAddNew: false, selectedTemplate: '', templateName: '' })
              this.getPrescriptionData()
            }
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.errorMessage(error.response.data.message)
            } else {
              this.errorMessage(error.message)
            }
          })
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  removedPrescription = () => {
    try {
      let removedIds = []; let prescriptionHash = {};
      let duplicate = JSON.parse(this.state.dupliPrescriptionData)
      this.state.prescriptionList?.forEach(element => {
        prescriptionHash[element.id] = element
      })

      duplicate?.prescription_lines?.forEach(element => {
        if (!prescriptionHash[element.id]) {
          removedIds.push(element.id)
        }
      })

      return removedIds;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  postPrescriptionData = (fromFav = false, fromTemplate = false) => {
    try {
      let removedIds = []
      if (this.state.selectedPrescripFav?.length < 0) {
        removedIds = this.removedPrescription()
      } else if (this.state.prescriptionId) {
        removedIds = this.removedPrescription()
      }
      let date = this.state.selectedFollowup ? new Date(this.state.selectedFollowup) : null
      let followUpDate = this.state.selectedFollowup ? DateTime.fromJSDate(date).toFormat('yyyy-MM-dd') : null
      let data = {
        appointment_id: this.props.appointmentId,
        deleted_line_ids: removedIds,
        followup_date: this.state.selectedFollowup ? followUpDate : null,
        patient_id: this.props.patientId,
      }
      if (this.state.prescriptionId) {
        data.prescription_id = this.state.prescriptionId
      } else {
        data.pharmacy_id = this.state.selectedPharmacy
      }
      if (this.state.selectedPrescripFav?.length > 0 && fromFav) {
        data.line_items = this.state.selectedPrescripFav
      } else if (this.state.selectedTemplate && fromTemplate) {
        let prescriptionData = []
        this.state.prescriptionData?.forEach(element => {
          prescriptionData?.push({
            afternoon: element?.afternoon ? (element?.afternoon)?.toString() : '0',
            brand_name: element?.brand_name ? element?.brand_name : '',
            comments: element?.comments ? element?.comments : '',
            dosage_strength: element.dosage_strength ? element.dosage_strength : '',
            dosage_type: element.dosage_type ? element.dosage_type : '',
            drug_id: element.drug_id ? element.drug_id : '',
            duration: element.duration ? element.duration : '',
            duration_mode: element.duration_mode ? element.duration_mode : '1',
            evening: element.evening ? (element.evening).toString() : '0',
            generic_name: element.generic_name ? element.generic_name : '',
            instructions: element.instructions ? element.instructions : '',
            is_favorite: element.is_favorite,
            morning: element.morning ? (element.morning.toString()) : '0',
            night: element.night ? (element.night).toString() : '0'
          })
        })
        data.line_items = prescriptionData
      } else {
        data.line_items = this.state.prescriptionList
      }
      this.setState({ DisableBtn: true })
      RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_PRESCRIPTION)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response.data.message)
            this.setState({
              openPrescriptionPopUp: false,
              prescriptionList: [],
              selectedPrescripFav: [],
              openFav: false,
              selectedId: null,
              DisableBtn: false
            })
            if (fromFav || fromTemplate) {
              this.setState({ selectedTemplate: '' })
            }
            this.getPrescriptionData()
            this.getPrescriptionFavouriteData()
            removedIds = []
          }
        }).catch((error) => {
          this.setState({ DisableBtn: false })
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  postPrescriptionTransmit = (recalled) => {
    try {
      let data = {}
      if (recalled) {
        data = {
          appointment_id: this.props.appointmentId,
          prescription_id: this.state.RecallPresId,
          is_recall: true,
          patient_id: this.props.patientId
        }
      } else {
        data = {
          appointment_id: this.props.appointmentId,
          patient_id: this.props.patientId,
          pharmacy_id: this.state?.selectedTransmitPharmacy ? this.state?.selectedTransmitPharmacy : this.state.selectedPharmacy,
          prescription_id: this.state.prescriptionId
        }
      }
      this.setState({ DisableBtn: true })
      RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_PRESCRIPTION_TRANSMIT)
        .then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.setState({ openTransmit: false, selectedTransmitPharmacy: '', transmitted: true, recallPopup: false, DisableBtn: false, RecallPresId: null }, () => {
              this.getTapperMed()
            })
          }
        }).catch((error) => {
          this.setState({ DisableBtn: false })
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  deleteTemplate = () => {
    try {
      let data = {}
      this.setState({ removeData: true })
      RestAPIService.delete(Serviceurls.DOC_MEDIC_TEMPLATE_GET_POST + `?template_id=${this.state.selectedTemplate?.id}`, data)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response.data.message)
            this.setState({
              removeData: false,
              deletePopup: false,
              tempType: '',
              openPopupAddNew: false,
              selectedTemplate: ''
            })
            this.getMedicationListData()
            this.getPrescriptionListData()
            this.getTapperMed()
            this.getPrescriptionData()

          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ removeData: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ removeData: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ removeData: false })
      this.errorMessage(error.message)
    }
  }

  deleteFrequencysugg = () => {
    try {
      let data = {}
      this.setState({ removeData: true })
      RestAPIService.delete(`${Serviceurls.DOC_OPT_MEDICATION_FREQUENCY}?description=${this.state.SelectedDeleteChip}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response?.data?.message)
            this.getMedicFrequency()
            this.setState({ deletePopup: false, SelectedDeleteChip: null, removeData: false })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ removeData: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ removeData: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ removeData: false })
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  popupClose = () => {
    try {
      this.setState({
        deletePopup: false
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  checkValidation = () => {
    try {
      let message = null;
      let states = this.state
      if (states.selectedDrug === '') {
        message = 'Select Brand name'
      } else if (states.searchedGenericName === null) {
        message = 'Select Generic Name'
      } else if (states.dosageStrength === '' || states.dosageType === '') {
        message = 'Dosage Strength and Dosage Type is Empty'
      } else if ((states.selectedDrug !== '') && (states.searchedGenericName === null) && (states.dosageStrength === '') && (states.dosageType === '')) {
        if ((states.morningQty !== '0') || (states.afternoonQty !== '0') || (states.eveningQty !== '0') || (states.nightQty !== '0')) {
          message = null
        } else {
          message = 'Please Select MAEN Dosage Quantity'
        }
      } else if (states.selectedDays === '' || states.selectedDuration === '') {
        message = 'Select duration'
      } else if (states.selectedUsage === '') {
        message = 'Select Usage'
      }

      return message;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderRecallPopup = () => {
    return (
      <Dialog
        open={this.state.recallPopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '0.5vw', marginBottom: '0vw' }}>
          <img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt='Close' onClick={() => { this.setState({ recallPopup: false }) }} />
        </div>
        <DialogTitle id="alert-dialog-title">
          {`Are you sure ? Do you want to Edit Medication`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: '0.8vw' }} id="alert-dialog-description">
            {""}
          </DialogContentText>
        </DialogContent>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: "flex-end" }}>
          <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button size="small" variant="outlined" onClick={() => { this.setState({ recallPopup: false }) }}>
              {"CLOSE"}
            </Button>
            <Button size="small" variant="contained" onClick={() => { this.postPrescriptionTransmit(true) }}>
              {"RECALL"}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    )
  }

  handleDatePicker = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-GB');
    return formattedDate.split("/").reverse().join("-");
  }

  taperDetailAdd = () => {
    var data = {}
    var states = this.state
    var validDate = false
    var timeValid = true
    var taperAddedDetail = states.taperAddedDetails
    var frequency = (states.tapp_Gen_Details?.dosage_type)?.toUpperCase() === "STEROID TABLETS" ? "" : states.Frequency
    var fromDate = this.handleDatePicker(states.taperFromDate)
    var reducedBy = (states.tapp_Gen_Details?.dosage_type)?.toUpperCase() === "STEROID TABLETS" ? states.reducedBy : ""
    var d = new Date(this.handleDatePicker(states.taperFromDate))
    if (d.toString() != "Invalid Date") {
      validDate = true
    }
    if (Object.keys(this.state.tapp_Gen_Details).length > 0) {
      if (validDate && ((states.TapperUnits)?.trim() != "") && ((states.tapDuration)?.trim() != "") &&
        (((states.tapp_Gen_Details?.dosage_type)?.toUpperCase() != "STEROID TABLETS" && (states.Frequency)?.trim() != "") || ((states.tapp_Gen_Details?.dosage_type)?.toUpperCase() === "STEROID TABLETS" && (states.reducedBy)?.trim() != "")) && timeValid
      ) {

        var duration = states.tapDuration

        if (states.durationType === "Week(s)") {
          duration = parseInt(states.tapDuration) * 7
        } else if (states.durationType === "Month(s)") {
          duration = parseInt(states.tapDuration) * 30
        }

        var date = new Date(fromDate);
        date.setDate(date.getDate() + parseInt(duration));

        var dosageCountLabel = (states.tapp_Gen_Details?.dosage_type).toUpperCase() === "STEROID TABLETS" ? 'Mg' : 'Drop(s)'

        var frequencyLabel = dosageCountLabel === "Drop(s)" ? (frequency == 1) ? ' time a day' : ' times a day' : this.state.frequency_tablet

        data = {
          start_date: fromDate,
          no_of_dosage: states.TapperUnits + " " + dosageCountLabel,
          duration: states.tapDuration + " " + states.durationType,
          end_date: moment(date).format("YYYY-MM-DD"),
          frequency: frequency + frequencyLabel,
          reducedBy: reducedBy,
          instructions : states.tapUsage ? states.tapUsage : '',
          pharmacy_id: states.selectedPharmacy || ""
        }

        if ((states.selectedEditIndex || states.selectedEditIndex == 0)) {
          taperAddedDetail[states.selectedEditIndex]["start_date"] = fromDate
          taperAddedDetail[states.selectedEditIndex]["no_of_dosage"] = states.TapperUnits + " " + dosageCountLabel
          taperAddedDetail[states.selectedEditIndex]["duration"] = states.tapDuration + " " + states.durationType
          taperAddedDetail[states.selectedEditIndex]["end_date"] = moment(date).format("YYYY-MM-DD")
          taperAddedDetail[states.selectedEditIndex]["frequency"] = (states.tapp_Gen_Details?.dosage_type)?.toUpperCase() === "STEROID TABLETS" ? states.frequency_tablet : states.frequency
          taperAddedDetail[states.selectedEditIndex]["reducedBy"] = states.reducedBy
          taperAddedDetail[states.selectedEditIndex]['instructions'] = states.tapUsage ? states.tapUsage : ''
          taperAddedDetail[states.selectedEditIndex]["pharmacy_id"] = states.selectedPharmacy || ""

        } else if (!states.selectedEditIndex && states.selectedEditIndex != 0) {
          taperAddedDetail.push(data)
        }

        if (frequency == 1) {
          frequency = frequency
        } else if (frequency == 12) {
          frequency = 10
        } else if (frequency == 10) {
          frequency = 6
        } else {
          frequency = parseInt(frequency) - 1
        }

        var unitVal = ""
        var calc = parseInt(states.TapperUnits) - parseInt(states.reducedBy)
        if (dosageCountLabel === "Mg" && calc > 0) {
          unitVal = calc
        } else {
          unitVal = states.TapperUnits
        }

        var nextStartDate = new Date(date)
        nextStartDate.setDate(nextStartDate.getDate() + 1)
        fromDate = moment(nextStartDate).format("YYYY-MM-DD")


        this.setState({
          taperAddedDetails: taperAddedDetail,
          taperFromDate: moment(fromDate).format("YYYY-MM-DD"),
          TapperUnits: unitVal + "",
          hour12Formet: "00",
          minutes: "00",
          taperInterval: "",
          Frequency: frequency + "",
          selectedEditIndex: null,
        })
      } else {
        if (!timeValid) {
          this.errorMessage("Invalid Time Interval");
        } else if (!validDate) {
          this.errorMessage("Invalid Date");
        } else {
          this.errorMessage("Invalid Details can't be added");
        }
      }
    } else {
      this.errorMessage("Select Drug Name");
    }

  }

  MedicTableDetails = () => {
    let states = this.state
    try {
      let DosageType = states?.ExternalDrugAdd ? (this.state.External_Drug_Details?.dosage_type ? this.state.External_Drug_Details?.dosage_type : "") : (states.Medic_Gen_Details?.dosage_type ? states.Medic_Gen_Details?.dosage_type : "");
      let Duration = `${this.state.MedDuration} ${this.state.selectedDuration == "Until_the_Next_Visit" ? "Until the Next Visit" : this.state.selectedDuration == "Long_Term" ? "Long Term" : this.state.selectedDuration}`

      let Medication = [{
        "eye_type": this.state.eyeType ? this.state.eyeType : "",
        "brand_name": states?.ExternalDrugAdd ? this.state.External_Drug_Details?.DrugName : (states?.Medic_Gen_Details?.drug_name ? states?.Medic_Gen_Details?.drug_name : states?.Medic_Gen_Details?.brand_name),
        "generic_name": states?.ExternalDrugAdd ? this.state.External_Drug_Details?.GenericName : states?.Medic_Gen_Details?.generic_name,
        "dosage_strength": states?.ExternalDrugAdd ? this.state.External_Drug_Details?.DosageStrength : states.Medic_Gen_Details?.dosage_strength,
        "drug_id": states?.ExternalDrugAdd ? null : (states.Medic_Gen_Details?.drug_ms_id ? states.Medic_Gen_Details?.drug_ms_id : states.Medic_Gen_Details?.drug_id),
        "dosage_type": DosageType === 'Drops' ? 'Drop' : DosageType,
        "frequency": this.state.Medfrequency,
        "duration": Duration,
        "instructions": states?.MedUsage,
        "comments": this.state.medCommends,
        "quantity": this.state.MedQty,
        "tapper_meds": [],
        "pharmacy_id": this.state.selectedPharmacy,
        "isAddNewClick": false,
        "is_favorite": states?.ExternalDrugAdd ? false : (this.state.Medic_Gen_Details?.is_favorite ? this.state.Medic_Gen_Details?.is_favorite : false),
        "morning": states?.Morning ? states?.Morning : 0,
        "afternoon": states?.Afternoon ? states?.Afternoon : 0,
        "evening": states?.Evening ? states?.Evening : 0,
        "night": states?.Night ? states?.Night : 0,
        "no_of_dosage": states?.ExternalDrugAdd ? (states.External_Drug_Details?.no_of_dosage ? states.External_Drug_Details?.no_of_dosage : 0) : (states.Medic_Gen_Details?.no_of_dosage ? states.Medic_Gen_Details?.no_of_dosage : 0),
        "opt_template_line_id": states.optTemplateId ? states.optTemplateId : null
      }]
      let data = {
        "appointment_id": this.props?.appointmentId,
        "patient_id": this.props?.patientId,
        "line_items": this.state.AddClicked ? this.state?.MedicData : Medication,
        "followup_date": "",
        "deleted_line_ids": [],
        "pharmacy_id": states?.selectedPharmacy
      }

      if (this.state?.medicId && this.state?.isEdit) {
        Medication[0]["id"] = this.state.medicId
      }
      if (this.state.prescriptionId) {
        data["prescription_id"] = this.state.prescriptionId
      }
      this.setState({ DisableBtn: true })
      RestAPIService.create(data, Serviceurls.DOC_OPT_TAPPER_DETAILS_GET_POST)
        .then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.getTapperMed()
            this.onClearHandler()
            this.clearTapperDetails()
            this.getPrescriptionFavouriteData()
            this.setState({ openMedicPopUp: false, EditIndex: null, AddClicked: false, DisableBtn: false })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ DisableBtn: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ DisableBtn: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  TapperTableDetails = (Favourite, removeID) => {
    let states = this.state
    var tapper = []
    try {
      if (removeID && Favourite) {
        var NewArray = this.modifyHistory(Favourite)
      }
      if (!Favourite) {
        let dosage_type = states.tapp_Gen_Details?.dosage_type ? states.tapp_Gen_Details?.dosage_type : "";
        let frequency = this.state.Frequency ? this.state.Frequency : "";

        tapper = [{
          "eye_type": this.state.eyeType ? this.state.eyeType : "",
          "brand_name": states?.tapp_Gen_Details?.drug_name,
          "generic_name": states?.tapp_Gen_Details?.generic_name,
          "dosage_strength": states.tapp_Gen_Details?.dosage_strength,
          "drug_id": states.tapp_Gen_Details?.drug_ms_id,
          "dosage_type": states.tapp_Gen_Details?.dosage_type === 'Drops' ? 'Drop' : dosage_type,
          "frequency": frequency,
          "duration": this.state.durationType == "Day(s)" ? "Days" : this.state.durationType == "Week(s)" ? "Weeks" : "Months",
          "instructions": this.state.tapUsage ? this.state.tapUsage : '',
          "comments": this.state.tapperComm,
          "quantity": this.state.tapperQty,
          "tapper_meds": this.state.taperAddedDetails,
          "pharmacy_id": this.state.selectedPharmacy,
          "isAddNewClick": false,
          "is_favorite": this.state.is_TapperFav,
          "morning": 0,
          "afternoon": 0,
          "evening": 0,
          "night": 0,
          "no_of_dosage": 0,
          "is_tapper_meds": states.tapp_Gen_Details?.dosage_type === 'Drop' ? false : true,
          "opt_template_line_id": states.optTemplateId ? states.optTemplateId : null
        }];
      }

      let data = {
        "appointment_id": this.props?.appointmentId,
        "patient_id": this.props?.patientId,
        "line_items": removeID ? NewArray : Favourite ? Favourite : tapper,
        "followup_date": "",
        "deleted_line_ids": [],
        "pharmacy_id": states?.selectedPharmacy,

      }
      if (removeID) {
        data["from_history"] = true
      }
      if (this.state?.tapperID && this.state?.isEdit) {
        tapper[0]["id"] = this.state.tapperID
      }
      if (this.state.prescriptionId) {
        data["prescription_id"] = this.state.prescriptionId
      }

      if ((Object.keys(this.state.tapp_Gen_Details).length > 0 && this.state.taperAddedDetails.length > 0) || Favourite) {
        this.setState({ DisableBtn: true })
        RestAPIService.create(data, Serviceurls.DOC_OPT_TAPPER_DETAILS_GET_POST)
          .then((response) => {
            if (response.data.status === 'success') {
              this.successMessage(response.data.message)
              this.getTapperMed()
              this.clearTapperDetails()
              this.getPrescriptionFavouriteData()
              this.getMedicFrequency()
              this.setState({ openMedicPopUp: false, DisableBtn: false, optTemplateId: null })
            }
          }).catch((error) => {
            this.setState({ DisableBtn: false })
            if (error?.response?.data?.message) {
              this.errorMessage(error.response.data.message)
            } else {
              this.errorMessage(error.message)
            }
          })
      } else {
        this.errorMessage("Enter Tapper Details");
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  postNewTapperMedData = () => {
    try {
      let states = this.state
      let data = {
        "appointment_id": this.props?.appointmentId,
        "patient_id": this.props?.patientId,
        "line_items": this.state.newTapMedListData,
        "followup_date": "",
        "deleted_line_ids": [],
        "pharmacy_id": states?.selectedPharmacy,
      }
      if (this.state.prescriptionId) {
        data["prescription_id"] = this.state.prescriptionId
      }
      RestAPIService.create(data, Serviceurls.DOC_OPT_TAPPER_DETAILS_GET_POST)
        .then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.getTapperMed()
            this.getPrescriptionFavouriteData()
            this.getMedicFrequency()
            this.setState({
              openMedicPopUp: false,
              newTapMedListData: [],
              prescriptionId: null,
              editItem: null,
              AllowEdit: false,
              disableMedic: false,
              isEdit: false,
              value: 'One'
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  addPrescriptionData = () => {
    try {
      let states = this.state
      let list = {
        afternoon: states.afternoonQty ? states.afternoonQty : '0',
        brand_name: states.selectedDrug,
        comments: states.comments,
        dosage_strength: states.dosageStrength,
        dosage_type: states.dosageType,
        drug_id: states.searchedGenericName?.drug_id ? states.searchedGenericName?.drug_id : states.selectedDrugId,
        duration: states.selectedDuration,
        duration_mode: states.selectedDays,
        evening: states.eveningQty ? states.eveningQty : '0',
        generic_name: states.searchedGenericName?.generic_name ? states.searchedGenericName?.generic_name : states.searchedGenericName,
        instructions: states.selectedUsage,
        is_favorite: JSON.parse(JSON.stringify(states.favourite)),
        morning: states.morningQty ? states.morningQty : '0',
        night: states.nightQty ? states.nightQty : '0',
        id: states.selectedId ? states.selectedId : null,
        opt_template_line_id: states.optTemplateId ? states.optTemplateId : null
      }
      let checkValidation = this.checkValidation()
      if (checkValidation === null) {
        let data = CommonValidation.updateOrInsertList(states.prescriptionList, list, states.selectedIndex)
        this.setState({
          prescriptionList: data
        }, () => this.onClearHandler())
      } else {
        this.errorMessage(checkValidation)
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onEditHandler = (item) => {
    try {
      this.setState({
        afternoonQty: typeof (item?.afternoon) === 'string' ? item?.afternoon : (item?.afternoon).toString(),
        selectedDrug: item?.brand_name,
        comments: item?.comments,
        dosageStrength: item?.dosage_strength,
        dosageType: item?.dosage_type,
        selectedDuration: item?.duration,
        selectedDays: item?.duration_mode,
        eveningQty: typeof (item?.evening) === 'string' ? item?.evening : (item?.evening).toString(),
        nightQty: typeof (item?.night) === 'string' ? item?.night : (item?.night).toString(),
        morningQty: typeof (item?.morning) === 'string' ? item?.morning : (item?.morning).toString(),
        favourite: JSON.parse(JSON.stringify(item?.is_favorite)),
        searchedGenericName: item?.generic_name,
        selectedUsage: item?.instructions,
        selectedDrugId: item?.drug_id,
        selectedId: item?.id ? item?.id : null,
        isEdit: true
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onClearHandler = () => {
    try {
      this.setState({
        selectedDrug: '',
        searchedDrugList: [],
        searchedBrandName: null,
        searchedGenericName: '',
        genericList: [],
        dosageStrength: '',
        dosageType: '',
        morningQty: '0',
        afternoonQty: '0',
        eveningQty: '0',
        nightQty: '0',
        selectedUsage: '',
        selectedDays: '1',
        selectedDuration: '',
        comments: '',
        favourite: false,
        selectedDrugId: null,
        isEdit: false,
        selectedIndex: null,
        selectedId: null,
        disableTapper: false,
        disableMedic: false,
        Morning: "",
        Afternoon: "",
        Evening: "",
        Night: "",
        no_of_Dosage: null,
        selectedDuration: "Days",
        MedUsage: "",
        MedDuration: "",
        MedQty: "",
        medCommends: "",
        MedicData: [],
        is_MefiFav: false,
        is_TapperFav: false,
        drugList: [],
        GenericNameSearch: null,
        frequency_tablet:"",
        tempType: ""
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderNewTemplatePopup = () => {
    try {
      let { tempType } = this.state
      return (
        <Box component={'div'}>
          <Modal open={this.state.openPopupAddNew}>
            <Box className='eMed_Doc_Notes_Test_Trans_Pop_Up'>
              <Box component={'div'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={'0.5vw'}>
                <Typography fontWeight={600} color={Colors.SecondaryText}>{tempType === 'Add' ? 'Add New Template' : tempType === 'rename' ? 'Update Template' : 'Delete Template'}</Typography>
              </Box>
              <Box component={'div'}>
                <Box component={'div'} display={'flex'} justifyContent={'center'}>
                  {tempType === 'Add' ?
                    <Box component={'div'} display={'flex'}>
                      <FormControl size='small' sx={{ width: '12vw', mr: '0.5vw' }}>
                        <InputLabel>Select Pharmacy</InputLabel>
                        <Select
                          label='select Pharmacy'
                          disabled={(this.state.TapperMedList?.length > 0 &&
                            this.state.TapperMedList[0]?.prescription_lines[0]?.pharmacy_id === this.state.selectedPharmacy)}
                          value={this.state.selectedPharmacy}
                          onChange={(e) => this.setState({ selectedPharmacy: e.target.value })}
                        >
                          {this.state.pharmacyList?.length > 0 && this.state.pharmacyList?.map((item, index) => (
                            <MenuItem value={item?.pharmacy_id} key={index}>{item?.pharmacy_name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <TextField
                        size='small'
                        sx={{ width: '12vw' }}
                        value={this.state.templateName}
                        label='Enter Template Name'
                        onChange={(e) => this.setState({ templateName: e.target.value })}
                      />
                    </Box>
                    : <TextField
                      size='small'
                      sx={{ width: '15vw', marginTop: "0.65vw" }}
                      value={this.state.templateName}
                      label='Enter Template Name'
                      onChange={(e) => this.setState({ templateName: e.target.value })}
                    />
                  }
                </Box>
                {tempType === 'rename' ?
                  <Box component={'div'} display={'flex'} justifyContent={'center'}>
                    <FormControlLabel control={<Checkbox size='small' checked={this.state.editTemplate} onChange={(e) => this.setState({ editTemplate: e.target.checked })} />} label="Do you need to update current data for template ?" />
                  </Box> : null
                }
                <Box component={'div'} display={'flex'} justifyContent={'center'} mt={tempType === 'Add' ? '0.5vw' : ''}>
                  <Button variant='contained'
                    sx={{ width: '5vw', height: '2vw', textTransform: 'capitalize', mr: '1vw' }}
                    onClick={() => { this.PostNewMedicTemplate() }}
                  >Add</Button>
                  <Button variant='outlined'
                    sx={{ width: '5vw', height: '2vw', textTransform: 'capitalize' }}
                    onClick={() => this.setState({ tempType: '', openPopupAddNew: false, templateName: "" })}
                  >Close</Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onEditTapper = (item, index) => {
    console.log(item)
    
    this.setState({
      tapDuration: item?.duration ? item?.duration?.split(" ")[0] : "",
      durationType: item?.duration ? item?.duration?.split(" ")[1] : "",
      TapperUnits: item?.no_of_dosage ? item?.no_of_dosage?.split(" ")[0] : "",
      Frequency: item?.frequency,
      reducedBy: item?.reducedBy,
      taperFromDate: moment(item?.start_date),
      selectedEditIndex: index,
      frequency_tablet:item?.frequency,
      tapUsage:item?.instructions ? item?.instructions : ''
    })
  }

  onDltTapper = (indexToRemove) => {
    let { taperAddedDetails } = this.state
    let newArray = taperAddedDetails.filter((_, index) => index !== indexToRemove);
    this.setState({
      taperAddedDetails: []
    }, () => {
      this.setState({
        taperAddedDetails: newArray
      })
    })
  }

  renderChip = (values, key, Type) => {
    let states = this.state
    return (
      <div>
        <div style={{ display: "flex", flexWrap: "wrap", marginTop: "0.5vw" }}>
          {
            values && values.map((item, index) => (
              <Chip
                className={`eMed_chip ${Type == "EyeChip" ? (item.label === key ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border') : ((item === key ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'))}`}
                style={{ width: "7vw", display: 'flex', justifyContent: 'space-between' }}
                label={Type == "EyeChip" ? item?.label === "RE" ? "Right Eye (OD)" : item?.label === "LE" ? "Left Eye (OS)" : item?.label === "BE" ? "Both Eye (OU)" : item?.label : item}
                variant={Type == "EyeChip" ? (item.label === key ? 'contained' : 'outlined') : (item === key ? 'contained' : 'outlined')}
                key={index}
                onClick={() => {
                  if (Type == "EyeChip") {
                    if (item.label === key) {
                      states.eyeType = ""
                      this.setState({ states })
                    } else {
                      states.eyeType = item?.value
                      this.setState({ states })
                    }
                  } else {
                    if (item === key) {
                      states.Medfrequency = ""
                      states.FrequencyText = ""
                      this.setState({ states })
                    } else {
                      states.Medfrequency = item
                      states.FrequencyText = ""
                      this.setState({ states })
                    }
                  }
                }}
                clickable
                onDelete={Type === "Frequency" ? () => { this.setState({ deletePopup: true, SelectedDeleteChip: item }) } : null}
              />
            ))
          }
          {
            Type === "Frequency" ?
              <TextField
                size='small'
                value={this.state.FrequencyText}
                onChange={(e) => {
                  this.setState({ FrequencyText: e.target.value })
                }}
                inputProps={{ maxLength: 30, style: { height: "0.8vw" } }}
                sx={{ width: "10vw", marginLeft: "0.5vw", marginTop: '0.3vw' }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && this.state.FrequencyText !== "") {
                    let List = this.state.frequencyTemplateList
                    if (!List.includes(this.state.FrequencyText)) {
                      List.push(this.state.FrequencyText)
                    }
                    this.setState({
                      frequencyTemplateList: List,
                      Medfrequency: this.state.FrequencyText,
                      FrequencyText: ""
                    })
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        disabled={this.state.FrequencyText === ""}
                        onClick={() => {
                          let List = this.state.frequencyTemplateList
                          if (!List.includes(this.state.FrequencyText)) {
                            List.push(this.state.FrequencyText)
                          }
                          this.setState({
                            frequencyTemplateList: List,
                            Medfrequency: this.state.FrequencyText,
                            FrequencyText: ""
                          })
                        }}>
                        <AddIcon color={this.state.FrequencyText === "" ? 'grey' : 'primary'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }} /> : null
          }
        </div>
      </div>
    )
  }

  checkDuplicate = () => {
    try {
      let message = null
      let states = this.state
      states.drugList?.forEach(element => {
        if ((element?.name === states.searchedBrandName) || (element === states.searchedBrandName)) {
          message = 'Brand Name Already Exists'
        }
      })
      return message
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderNewText = (Heading, values, Tab) => {
    let states = this.state
    return (
      <div style={{ display: "flex", flexDirection: "row", marginLeft: "0.5vw" }}>
        <TextField
          label={(`Add ${Heading}`)}
          size="small"
          style={{ marginTop: "0.5vw", width: "20vw" }}
          variant="outlined"
          value={states.NewText}
          onChange={(event) => {
            let value = event.target.value
            this.setState({ NewText: value })
          }}
        />
        <div style={{ marginLeft: "0.5vw", marginTop: "0.65vw", cursor: "pointer" }}
          onClick={() => {
            if (this.state.NewText) {
              let data = CommonValidation.removeSpace(this.state.NewText)
              values.push(data)
              states[Tab] = this.state.NewText
              this.setState({ states }, () => { this.setState({ selectedText: false, NewText: "" }) })
            }
          }}>
          <img className="eMed_Tick_img" style={{ height: "2vw", width: "2vw" }} src={ImagePaths.Tick.default} alt={"Tick"} />
        </div>
        <div style={{ marginLeft: "0.5vw", marginTop: "0.65vw", cursor: "pointer" }}
          onClick={() => {
            this.setState({ selectedText: false, NewText: "" })
          }}>
          <img className="eMed_Tick_img" style={{ height: "2vw", width: "2vw" }} src={ImagePaths.CancelIcon.default} alt={"Close"} />
        </div>
      </div>
    )
  }

  AddExternalDrug = () => {
    let states = this.state
    return (
      <Box>
        {/* <TextField
          size='small'
          sx={{ marginTop: '0.5vw', width: '100%' }}
          value={this.state.External_Drug_Details?.DrugName ? this.state.External_Drug_Details?.DrugName : ""}
          label='Drug Name*'
          onChange={(e) => {
            let states = this.state.External_Drug_Details
            states["DrugName"] = e.target.value
            this.setState({ states })
          }}
        /> */}
        <Autocomplete
          size='small'
          sx={{ marginTop: '0.5vw', width: '19vw', mr: '0.5vw' }}
          clearIcon
          freeSolo
          autoSelect={false}
          options={this.state.ExternalDrugList?.map((item) => item?.drug_name)}
          value={this.state.ExternalBrandName ? this.state.ExternalBrandName : null}
          onChange={(e, value) => {
            let selectedDrug = this.state.ExternalDrugList.filter((item) => item?.drug_name == value)
            this.getExternalDrugSearchList(value, '')
            this.setState({
              ExternalBrandName: value
            }, () => {
              this.setState({
                External_Drug_Details: {
                  DrugName: selectedDrug[0]?.drug_name,
                  GenericName: selectedDrug[0]?.generic_name,
                  DosageStrength: selectedDrug[0]?.dosage_strength,
                  dosage_type: selectedDrug[0]?.dosage_type
                }
              })
            })
          }}
          renderInput={(params) => <TextField {...params}
            onChange={(e) => {
              if (e.target.value?.length > 0) {
                states.External_Drug_Details["DrugName"] = e.target.value
                this.setState({
                  states
                }, () => this.getExternalDrugSearchList(e.target.value, ''))
              } else {
                this.clearDrugDetails()
              }
            }}
            label='Drug Name*' />}
        />
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginTop: '0.5vw' }}>
          <TextField
            size='small'
            sx={{ width: '19vw' }}
            value={this.state.External_Drug_Details?.GenericName ? this.state.External_Drug_Details?.GenericName : ""}
            label='Generic Name'
            onChange={(e) => {
              let states = this.state.External_Drug_Details
              states["GenericName"] = e.target.value
              this.setState({ states })
            }}
          />
          <TextField
            size='small'
            value={this.state.External_Drug_Details?.DosageStrength ? this.state.External_Drug_Details?.DosageStrength : ""}
            label='Dosage Strength'
            onChange={(e) => {
              let states = this.state.External_Drug_Details
              states["DosageStrength"] = e.target.value
              this.setState({ states })
            }}
          />
        </Box>
      </Box>
    )
  }

  isDrugIsDuplicate() {
    try {
      let Flag = false
      let i = 0;
      let states = this.state;
      while (i < states.MedicData.length) {
        if (this.state.ExternalDrugAdd) {
          if (states.MedicData[i]?.brand_name === states.External_Drug_Details?.DrugName && states.MedicData[i]?.generic_name === states.External_Drug_Details?.GenericName && states.MedicData[i]?.dosage_strength === states.External_Drug_Details?.DosageStrength && states.MedicData[i]?.dosage_type === states.External_Drug_Details?.dosage_type && this.state.EditIndex !== i) {
            Flag = true;
            break;
          }
        } else {
          if ((states.MedicData[i]?.drug_id === states?.selectedDrugId || states.MedicData[i]?.drug_ms_id === states?.selectedDrugId) && this.state.EditIndex !== i) {
            Flag = true;
            break;
          }
        }
        i++;
      }
      return Flag
    }
    catch (e) {

    }
  }

  clearDrugDetails = () => {
    this.setState({
      TapperDrugList: [],
      Medic_Gen_Details: {},
      selectedDrugId: null,
      MedicDrugList: [],
      DrugSuggestionList: [],
      Medic_Gen_Details: {},
      selectedDrug: "",
      ExternalBrandName: null,
      GenericSuggestionList: []
    })
  }

  renderMedicationPopup = () => {
    let len = Object.keys(this.state?.tapp_Gen_Details).length
    var taperUnit = ""
    let states = this.state
    if (len > 0) {
      if ((this.state?.tapp_Gen_Details?.dosage_type).toUpperCase() === "STEROID TABLETS") {
        taperUnit = "Mg"
      } else {
        taperUnit = "Drop(s)"
      }
    }
    try {
      return (
        <Box component={'div'}>
          <Modal open={this.state.openMedicPopUp}>
            <Box component={'div'} className='eMed_Doc_Notes_Prescrip_Pop_Up' sx={{ width: "85vw", maxHeight: "47vw" }}>
              <Box component={'div'} p={'0.5vw'} height={'2.5vw'} display={'flex'} mt={"0.5vw"} justifyContent={'space-between'} alignItems={'center'}>
                                  <Typography style={{ paddingLeft: "0.5vw" }} fontWeight={600} color={Colors.SecondaryText}>Add Medication</Typography>
                                <Button sx={{ marginRight: "-0.5vw" }} onClick={() => this.setState({ openMedicPopUp: false, EditIndex: null, AddClicked: false, AllowEdit: false }, () => {
                  this.onClearHandler()
                  this.clearTapperDetails()
                  this.setState({
                    prescriptionList: [],
                    prescriptionData: this.state.prescriptionData,
                    selectedPharmacy: '',
                    selectedIndex: null,
                    editItem: null,
                    value: "One"
                  })
                  this.onClearRightTaperHandler()
                  this.onClearLeftTapperDetails()
                })}>
                  <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1.1vw'} width={'1.1vw'} />
                </Button>
              </Box>
              <Tabs
                value={this.state.value}
                onChange={(e, newValue) => this.setState({
                  value: newValue,
                  selectedDrug: "",
                  searchedBrandName: "",
                  drugList: [],
                  tapp_Gen_Details: {},
                  GenericNameSearch: ""
                }, () => {
                  if (this.state.isChange) {
                    this.state.value === 'One' ? this.getMedFavouriteData() : this.getMedicationFavouriteData()
                    setTimeout(() => { this.setState({ isChange: false }) }, 1000)
                  }
                  this.clearDrugDetails()
                })}
              >
                <Tab disabled={this.state.disableMedic} value="One" label={"Medication"} className='eMed_tab_header' />
                <Tab disabled={this.state.disableTapper} value="Two" label={"Taper Med"} className='eMed_tab_header' />
              </Tabs>
              {this.state.value === "One" ?
                <Box component={"div"} style={{ padding: "0.5vw", height: "80vh", overflow: "auto" }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box flex={0.6} sx={{ height: "70vh", overflow: "auto" }} visibility={(this.state.EditIndex !== null ? false : this.state.isEdit) ? 'hidden' : 'visible'}>
                      <div style={{ marginTop: "0.5vw" }}>
                        <Table stickyHeader size='small'>
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, color: Colors.SecondaryText, backgroundColor: Colors.Background }}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                  <div style={{ marginLeft: "3vw" }}>Brand Name</div>
                                </div></TableCell>
                              <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Strength</TableCell>
                              <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Frequency</TableCell>
                              <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Duration</TableCell>
                              <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, color: Colors.SecondaryText, backgroundColor: Colors.Background }} align='center'>Usage</TableCell>
                              <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, color: Colors.SecondaryText, backgroundColor: Colors.Background }} align='center'>Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.MedicData?.length > 0 ? this.state.MedicData?.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <Button sx={{ visibility: (item?.drug_id || item?.drug_ms_id) ? "visible" : "hidden", justifyContent: 'flex-start', paddingTop: '0.5vw', }}
                                      className='Common_Btn_Min_Width'
                                      onClick={() => {
                                        this.state.MedicData[index].is_favorite = !this.state.MedicData[index].is_favorite
                                        this.setState({ MedicData: this.state.MedicData })
                                      }}>
                                      {item?.is_favorite ?
                                        <Box component={'img'} src={ImagePaths.StarFill.default} height={'1.5vw'} width={'1.5vw'} />
                                        :
                                        <Box component={'img'} src={ImagePaths.StarUnfill.default} height={'1.5vw'} width={'1.5vw'} />
                                      }
                                    </Button>
                                    <div style={{ marginLeft: "0.65vw" }}>
                                      <div style={{ fontWeight: "600" }}>{item?.brand_name ? item?.brand_name : "-"}</div>
                                      <div>{`${item?.generic_name ? item?.generic_name : "-"} | ${item?.dosage_type}`}</div>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell>{item?.dosage_strength ? item?.dosage_strength : "-"}</TableCell>
                                <TableCell style={{ fontWeight: "600" }}>{item?.frequency ? item?.frequency : "-"}</TableCell>
                                <TableCell>{item?.duration ? (item?.duration.split(" ")[1] === "Until_the_Next_Visit") ? "Until the Next Visit" : (item?.duration.split(" ")[1] === "Long_Term") ? "Long Term" : item?.duration : "-"}</TableCell>
                                <TableCell align='center'>{item?.instructions ? item?.instructions : "-"}</TableCell>
                                <TableCell align='center'>
                                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    <Button
                                      className='Common_Btn_Min_Width'
                                      onClick={() => {
                                        this.setState({ editItem: item, EditIndex: index, AllowEdit: true }, () => {
                                          this.getPharmacyList()
                                        })
                                      }}
                                      disabled={this.state.isLocked || this.props.isLocked || this.state.transmitted}
                                    >
                                      <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                                    </Button>
                                    <Button
                                      className='Common_Btn_Min_Width'
                                      onClick={() => {
                                        if (this.state.AllowEdit) {
                                          this.errorMessage('You clicked edit button.So you cannot able to delete')
                                        } else {
                                          if (item?.id) {
                                                                                          this.onDeleteMedic(item, true)
                                                                                      } else {
                                            this.state.MedicData.splice(index, 1)
                                            this.setState({
                                              MedicData: this.state.MedicData
                                            }, () => { this.clearTapperDetails() })
                                          }
                                        }
                                      }}
                                      disabled={this.state.isLocked || this.props.isLocked || this.state.transmitted}
                                    >
                                      <Box component={'img'} src={ImagePaths.Delete.default} height={'1.2vw'} width={'1.2vw'} />
                                    </Button>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            )) : null}
                          </TableBody>
                        </Table>
                      </div>
                    </Box>
                    <Box flex={0.4} sx={{ height: "69vh", overflow: "scroll", paddingX: "0.5vw", }}>
                      <Box component={'div'} mt={'0.5vw'} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <FormControl size='small' sx={{ width: '15vw' }}>
                          <InputLabel>Select Pharmacy</InputLabel>
                          <Select
                            label='select Pharmacy'
                            value={this.state.selectedPharmacy}
                            disabled={(this.state.MedicData?.length > 0 && this.state.MedicData[0]?.pharmacy_id === this.state.selectedPharmacy) ||
                              (this.state.taperAddedDetails?.length > 0 && this.state.taperAddedDetails[0]?.pharmacy_id === this.state.selectedPharmacy) ||
                              this.state.AllowEdit}
                            onChange={(e) => this.setState({ selectedPharmacy: e.target.value, isChange: true }, () => {
                              this.getMedFavouriteData();
                            })}
                          >
                            {this.state.pharmacyList?.length > 0 && this.state.pharmacyList?.map((item, index) => (
                              <MenuItem value={item?.pharmacy_id} key={index}>{item?.pharmacy_name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Button variant='outlined' disabled={this.state.AllowEdit} onClick={() => {
                          this.setState({ ExternalDrugAdd: !this.state.ExternalDrugAdd }, () => { this.clearTapperDetails(true) })
                        }}>{this.state.ExternalDrugAdd ? "Pharmacy Drugs" : "External Drugs"}</Button>
                      </Box>
                      {
                        this.state.ExternalDrugAdd ? this.AddExternalDrug() :
                          <Box>
                            <Box component={'div'} display={'flex'}>
                              <Autocomplete
                                size='small'
                                sx={{ marginTop: '0.5vw', width: '19vw', mr: '0.5vw' }}
                                clearIcon
                                freeSolo
                                autoSelect={false}
                                options={this.state.MedicDrugList}
                                getOptionLabel={(item) => item.drug_name ? (this.state.drugSearchFor === 1 ? item.drug_name : `${item.drug_name} - ${item.generic_name}`) : ''}
                                value={this.state.searchedBrandName ? this.state.searchedBrandName : null}
                                onChange={(e, value) => {
                                  if (value?.drug_name) {
                                    this.state.drugList?.push(value?.drug_name)
                                    this.setState({
                                      searchedBrandName: value,
                                      GenericNameSearch: value,
                                      drugList: this.state.drugList,
                                      selectedDrug: value?.drug_name
                                    }, () => {
                                      this.setState({ selectedDrugId: null, MedicDrugList: [], DrugSuggestionList: [], Medic_Gen_Details: {} })
                                      this.getTapperDrugDetails()
                                    })
                                  } else {
                                    this.setState({
                                      MedicDrugList: [],
                                      searchedBrandName: { drug_name: value, generic_name: "" }
                                    })
                                  }
                                }}
                                renderInput={(params) => <TextField {...params}
                                  onChange={(e) => {
                                    if (e.target.value?.length > 0) {
                                      this.setState({
                                        searchedName: CommonValidation.removeSpace(e.target.value),
                                        selectedDrugId: null,
                                        MedicDrugList: [],
                                        DrugSuggestionList: [],
                                        Medic_Gen_Details: {},
                                        selectedDrug: "",
                                        MedicDrugList: [], GenericNameSearch: { drug_name: e.target.value, generic_name: "" }
                                      }, () => this.getMedicDrugSearchList(this.state.searchedName, ""))
                                    } else {
                                      this.setState({
                                        searchedBrandName: { drug_name: "", generic_name: "" }
                                      }, () => { })
                                      this.clearDrugDetails()
                                    }
                                  }}
                                  label='Search Drug Name' />}
                              />
                              <Box display={'flex'} alignItems={'center'}>
                                <Typography fontWeight={600}>Avl. Qty:<Typography component={'span'} fontWeight={600}>{this.state.Medic_Gen_Details?.quantity ? this.state.Medic_Gen_Details?.quantity : 0}</Typography></Typography>
                              </Box>
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginTop: '0.5vw' }}>
                              <FormControl size='small' sx={{ width: '19vw' }}>
                                {/* <InputLabel>Generic Name</InputLabel>
                                <Select
                                  label='Generic Name'
                                  value={this.state.selectedDrugId ? this.state.selectedDrugId : ""}
                                  disabled={this.state.DrugSuggestionList?.length === 0}
                                  onChange={(e) => {
                                    this.setState({
                                      selectedDrugId: e.target.value,
                                      Medic_Gen_Details: this.state.DrugSuggestionList.find((item) => (item?.drug_id === e.target.value))
                                    })
                                  }
                                  }
                                >
                                  {this.state.DrugSuggestionList?.length > 0 && this.state.DrugSuggestionList?.map((item, index) => (
                                    <MenuItem value={item?.drug_id} key={index}>{item?.generic_name}</MenuItem>
                                  ))}
                                </Select> */}
                                <Autocomplete
                                  size='small'
                                  sx={{ marginTop: '0.5vw', width: '19vw', mr: '0.5vw' }}
                                  clearIcon
                                  freeSolo
                                  readOnly={this.state.drugSearchFor === 1 ? false : true}
                                  autoSelect={false}
                                  options={this.state.GenericSuggestionList}
                                  getOptionLabel={(item) => (item.generic_name + (item.dosage_type ? (" - " + item.dosage_type) : ""))}
                                  value={this.state.GenericNameSearch ? this.state.GenericNameSearch : null}
                                  onChange={(event, newValue) => {
                                    if (newValue?.drug_name) {
                                      this.setState({
                                        GenericNameSearch: newValue,
                                        searchedBrandName: newValue,
                                        selectedDrug: newValue?.drug_name, searchedBrandName: null,
                                        selectedDrugId: null, GenericSuggestionList: [], DrugSuggestionList: [], Medic_Gen_Details: {}
                                      }, () => {
                                        this.getTapperDrugDetails(false, newValue?.generic_name, newValue?.dosage_type)
                                      })
                                    } else {
                                      this.setState({
                                        GenericSuggestionList: [],
                                        GenericNameSearch: { drug_name: "", generic_name: newValue }
                                      })
                                    }
                                  }}
                                  renderInput={(params) => <TextField {...params}
                                    onChange={(e) => {
                                      if (e.target.value?.length > 0) {
                                        this.setState({
                                          GenericSearch: e.target.value,
                                          Medic_Gen_Details: {},
                                          selectedDrugId: null,
                                          DrugSuggestionList: [],
                                          Medic_Gen_Details: {},
                                          selectedDrug: "",
                                          GenericSuggestionList: [], searchedBrandName: { drug_name: "", generic_name: e.target.value }
                                        }, () => this.getMedicDrugSearchList("", this.state.GenericSearch))
                                      } else {
                                        this.setState({ GenericNameSearch: { drug_name: "", generic_name: "" } }, () => {
                                          this.clearDrugDetails()
                                        })
                                      }
                                    }}
                                    label='Generic Name' />}
                                />
                              </FormControl>
                              <TextField
                                size='small'
                                value={this.state.Medic_Gen_Details?.dosage_strength ? this.state.Medic_Gen_Details?.dosage_strength : ""}
                                label='Dosage Strength'
                                disabled={true}
                              />
                            </Box>
                            <div style={{ marginTop: "0.65vw", display: "flex", flexWrap: "wrap", overflow: "scroll" }}>
                              {
                                this.state.MedicFavorite?.length > 0 && this.state.MedicFavorite?.map((item, index) => (
                                  <Chip
                                    className={`eMed_chip ${(item.name ? (item?.id === this.state.selectedDrugId && (item.name) === this.state.selectedDrug) : (item === this.state.selectedDrug)) ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                    label={item.name ? item?.name : item}
                                    style={{ width: "9vw" }}
                                    variant={(item.name ? (item?.id === this.state.selectedDrugId && (item.name) === this.state.selectedDrug) : (item === this.state.selectedDrug)) ? 'contained' : 'outlined'}
                                    key={index}
                                    onClick={() => {
                                      this.setState({ selectedDrug: item, searchedBrandName: "", selectedDrugId: null, MedicDrugList: [], DrugSuggestionList: [], Medic_Gen_Details: {}, GenericNameSearch: "" }, () => {
                                        this.getTapperDrugDetails()
                                      })
                                    }}
                                    clickable
                                  />
                                ))
                              }
                            </div>
                          </Box>
                      }
                      <Box component={'div'} style={{ display: "flex", flexDirection: "column", marginTop: "0.5vw" }}>
                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Dosage Type</Typography>
                        <div style={{ marginTop: "0.65vw", display: "flex", flexWrap: "wrap", overflow: "scroll" }}>
                          {
                            Medication_dosages?.length > 0 && Medication_dosages?.map((item, index) => {
                              let isSelected = this.state.ExternalDrugAdd ? item?.label === this.state.External_Drug_Details?.dosage_type : item?.label === this.state.Medic_Gen_Details?.dosage_type
                              if (item?.label === this.state.Medic_Gen_Details?.dosage_type && !this.state.Medic_Gen_Details?.no_of_dosage && item?.change_count) {
                                let states = this.state.Medic_Gen_Details
                                states["no_of_dosage"] = item?.change_count
                                this.setState({ states })
                              } else if (this.state.ExternalDrugAdd && item?.label === this.state.External_Drug_Details?.dosage_type && !this.state.External_Drug_Details?.no_of_dosage && item?.change_count) {
                                let states = this.state.External_Drug_Details
                                states["no_of_dosage"] = item?.change_count
                                this.setState({ states })
                              }
                              return (
                                <Box className={isSelected ? "eMed_Dosage_Type_Selected_Box" : this.state.ExternalDrugAdd ? "eMed_Dosage_Type_Box_Selectable" : "eMed_Dosage_Type_Box"}
                                  onClick={() => {
                                    if ((this.state.ExternalDrugAdd && this.state.External_Drug_Details?.no_of_dosage === undefined) || (this.state.ExternalDrugAdd && this.state.External_Drug_Details?.dosage_type !== item?.label)) {
                                      let states = this.state.External_Drug_Details
                                      states["dosage_type"] = item?.label
                                      states["no_of_dosage"] = item?.change_count
                                      this.setState({ states })
                                    }
                                  }}>
                                  {isSelected && item?.change_count != 0 ? <IconButton size='small'
                                    disabled={this.state.ExternalDrugAdd ? this.state.External_Drug_Details?.no_of_dosage == item?.change_count : this.state.Medic_Gen_Details?.no_of_dosage == item?.change_count}
                                    onClick={() => {
                                      if (this.state.ExternalDrugAdd) {
                                        let states = this.state.External_Drug_Details
                                        let Value = states?.no_of_dosage ? (states?.no_of_dosage - item?.change_count) : item?.change_count
                                        states["no_of_dosage"] = Value
                                        this.setState({ states })
                                      } else {
                                        let states = this.state.Medic_Gen_Details
                                        let Value = states?.no_of_dosage ? (states?.no_of_dosage - item?.change_count) : item?.change_count
                                        states["no_of_dosage"] = Value
                                        this.setState({ states })
                                      }

                                    }} ><RemoveCircleOutlineIcon sx={{ color: 'white', fontSize: '0.5vw' }} /></IconButton> : null}
                                  <Typography width={isSelected ? "5vw" : "auto"} fontSize={'0.75vw'} fontWeight={600} textAlign={'center'} color={isSelected ? "white" : "black"}>{`${isSelected ? (this.state.ExternalDrugAdd ? this.state.External_Drug_Details?.no_of_dosage ? this.state.External_Drug_Details?.no_of_dosage : "" : this.state.Medic_Gen_Details?.no_of_dosage ? this.state.Medic_Gen_Details?.no_of_dosage : "") : ""} ${item?.label}`}</Typography>
                                  {isSelected && item?.change_count != 0 ? <IconButton
                                    onClick={() => {
                                      if (this.state.ExternalDrugAdd) {
                                        let states = this.state.External_Drug_Details
                                        let Value = states?.no_of_dosage ? (states?.no_of_dosage + item?.change_count) : (item?.change_count)
                                        states["no_of_dosage"] = Value
                                        this.setState({ states })
                                      } else {
                                        let states = this.state.Medic_Gen_Details
                                        let Value = states?.no_of_dosage ? (states?.no_of_dosage + item?.change_count) : (item?.change_count)
                                        states["no_of_dosage"] = Value
                                        this.setState({ states })
                                      }
                                    }}
                                    size='small'><AddCircleOutlineIcon sx={{ color: 'white', fontSize: '0.5vw' }} /></IconButton> : null}
                                </Box>
                              )
                            })}
                          {!this.state.ExternalDrugAdd && this.state.Medic_Gen_Details?.dosage_type && Medication_dosages.every((item) => item.label !== this.state.Medic_Gen_Details?.dosage_type) ? <Box className={"eMed_Dosage_Type_Box"} bgcolor={"#04B7B1"}>
                            <Typography width={"auto"} fontSize={'0.75vw'} fontWeight={600} textAlign={'center'} color={"white"}>{this.state.Medic_Gen_Details?.dosage_type}</Typography>
                          </Box> : null}
                        </div>
                      </Box>
                      <Box component={'div'} style={{ display: "flex", flexDirection: "column", marginTop: "0.5vw" }}>
                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Select Eye</Typography>
                        <Box component={'div'} style={{ paddingLeft: "-0.5vw" }}>
                          {this.renderChip(Eye_Type, this.state.eyeType, "EyeChip")}
                        </Box>
                      </Box>
                      <Box component={'div'} style={{ display: "flex", flexDirection: "column", marginTop: "0.5vw" }}>
                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Frequency</Typography>
                        <Box component={'div'} style={{ paddingLeft: "-0.5vw" }}>
                          {this.renderChip(this.state.frequencyTemplateList, this.state.Medfrequency, "Frequency")}
                        </Box>
                      </Box>
                      <Box>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                          <Box component={'div'} style={{ display: "flex", flexDirection: "column", marginTop: "0.5vw", marginLeft: "0.5vw" }}>
                            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Duration</Typography>
                            <div style={{ display: "flex", flexDirection: "row", marginTop: "0.5vw" }}>
                              <TextField
                                size='small'
                                sx={{ width: '8vw' }}
                                value={this.state.MedDuration}
                                disabled={this.state?.selectedDuration === "Until_the_Next_Visit" || this.state?.selectedDuration === "Long_Term"}
                                label='Duration'
                                onChange={(e) => {
                                  let isValid = CommonValidation.numberOnly(e.target.value)
                                  if ((isValid && +e.target.value < 999) || e.target.value == "") {
                                    this.setState({ MedDuration: e.target.value })
                                  }
                                }}
                              />
                              <FormControl sx={{ minWidth: 80, marginLeft: "-0.1vw" }}>
                                <Select
                                  value={this.state.selectedDuration}
                                  onChange={(event) => { this.setState({ selectedDuration: event.target.value, MedDuration: "" }) }}
                                  displayEmpty
                                  sx={{ width: "7vw" }}
                                  inputProps={{ 'aria-label': 'Without label' }}
                                  size='small'
                                >
                                  <MenuItem value="Days">Days</MenuItem>
                                  <MenuItem value="Weeks">Weeks</MenuItem>
                                  <MenuItem value="Months">Months</MenuItem>
                                  <MenuItem value="Until_the_Next_Visit">Until the Next Visit</MenuItem>
                                  <MenuItem value="Long_Term">Long Term</MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                          </Box>
                          <Box sx={{ marginTop: "0.5vw" }}>
                            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Usage</Typography>
                            <div style={{ marginTop: "0.5vw" }}>
                              <FormControl sx={{ minWidth: 80 }}>
                                <Select
                                  value={this.state.MedUsage}
                                  onChange={(event) => { this.setState({ MedUsage: event.target.value }) }}
                                  displayEmpty
                                  style={{ width: '14vw', marginLeft: "0.5vw" }}
                                  inputProps={{ 'aria-label': 'Without label' }}
                                  size='small'
                                >
                                  <MenuItem value="">Select Usage</MenuItem>
                                  {Usage_Type.map((item, index) => (
                                    <MenuItem key={index} value={item?.value}>{item?.label}</MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                          </Box>
                        </div>
                      </Box>
                      <div style={{ marginTop: '0.5vw' }}>
                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Other Details</Typography>
                        <div style={{ marginTop: "0.5vw" }}>
                          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                            <TextField
                              size='small'
                              sx={{ width: '10vw', marginLeft: "0.5vw" }}
                              value={this.state.MedQty}
                              label='Quantity'
                              onChange={(e) => {
                                this.setState({ MedQty: e.target.value })
                              }}
                            />
                            <TextField
                              size='small'
                              sx={{ width: "22vw", marginLeft: "0.5vw" }}
                              value={this.state.medCommends}
                              label='comments'
                              onChange={(e) => {
                                this.setState({ medCommends: e.target.value })
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <Typography sx={{ display: this.state.ExternalDrugAdd ? "flex" : "none", justifyContent: 'center', paddingTop: '0.5vw', fontSize: "0.8vw", color: Colors.red, fontWeight: 600 }}>Note : This Drug is not available in our pharmacy</Typography>
                      <Box style={{ display: "flex", justifyContent: 'center', marginTop: "0.5vw" }}>
                        <Button
                          sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', ml: '1vw', visibility: (this.state.EditIndex !== null ? false : this.state.isEdit) ? 'hidden' : "visible" }}
                          variant='contained'
                          disabled={this.state.EditIndex !== null ? false : this.state.isEdit}
                          onClick={() => {
                            let states = this.state
                            let Duration = `${this.state.MedDuration} ${this.state.selectedDuration == "Until_the_Next_Visit" ? "Until the Next Visit" : this.state.selectedDuration == "Long_Term" ? "Long Term" : this.state.selectedDuration}`
                            let DosageType = states?.ExternalDrugAdd ? (this.state.External_Drug_Details?.dosage_type ? this.state.External_Drug_Details?.dosage_type : "") : (states.Medic_Gen_Details?.dosage_type ? states.Medic_Gen_Details?.dosage_type : "");
                            if ((Object.keys(this.state.Medic_Gen_Details).length > 0 || (this.state.ExternalDrugAdd && this.state.External_Drug_Details?.DrugName && this.state.External_Drug_Details?.dosage_type)) && (states.Medfrequency != "") && (states?.MedDuration != "" || (states?.selectedDuration === "Until_the_Next_Visit" || states?.selectedDuration === "Long_Term")) && !this.isDrugIsDuplicate()) {
                              let object = {
                                "eye_type": this.state.eyeType ? this.state.eyeType : "",
                                "brand_name": states?.ExternalDrugAdd ? this.state.External_Drug_Details?.DrugName : (states?.Medic_Gen_Details?.drug_name ? states?.Medic_Gen_Details?.drug_name : states?.Medic_Gen_Details?.brand_name),
                                "generic_name": states?.ExternalDrugAdd ? (this.state.External_Drug_Details?.GenericName ? this.state.External_Drug_Details?.GenericName : "") : states?.Medic_Gen_Details?.generic_name,
                                "dosage_strength": states?.ExternalDrugAdd ? (this.state.External_Drug_Details?.DosageStrength ? this.state.External_Drug_Details?.DosageStrength : null) : states.Medic_Gen_Details?.dosage_strength,
                                "drug_id": states?.ExternalDrugAdd ? null : (states.Medic_Gen_Details?.drug_ms_id ? states.Medic_Gen_Details?.drug_ms_id : states.Medic_Gen_Details?.drug_id),
                                "dosage_type": DosageType === 'Drops' ? 'Drop' : DosageType,
                                "frequency": this.state.Medfrequency,
                                "duration": Duration,
                                "instructions": states?.MedUsage,
                                "comments": this.state.medCommends,
                                "quantity": this.state.MedQty,
                                "tapper_meds": [],
                                "pharmacy_id": this.state.selectedPharmacy,
                                "isAddNewClick": false,
                                "is_favorite": states?.ExternalDrugAdd ? false : (this.state.Medic_Gen_Details?.is_favorite ? this.state.Medic_Gen_Details?.is_favorite : false),
                                "morning": states?.Morning ? states?.Morning : 0,
                                "afternoon": states?.Afternoon ? states?.Afternoon : 0,
                                "evening": states?.Evening ? states?.Evening : 0,
                                "night": states?.Night ? states?.Night : 0,
                                "no_of_dosage": states?.ExternalDrugAdd ? (states.External_Drug_Details?.no_of_dosage ? states.External_Drug_Details?.no_of_dosage : 0) : (states.Medic_Gen_Details?.no_of_dosage ? states.Medic_Gen_Details?.no_of_dosage : 0),
                                "opt_template_line_id": states?.optTemplateId ? states.optTemplateId : null
                              }
                              if (this.state.EditIndex !== null) {
                                if (states.MedicData[this.state.EditIndex].id) {
                                  object["id"] = states.MedicData[this.state.EditIndex].id
                                }
                                states.MedicData[this.state.EditIndex] = object
                              } else {
                                states?.MedicData.push(object)
                              }
                              this.setState({
                                states,
                                Medic_Gen_Details: {},
                                eyeType: "",
                                Morning: "",
                                Afternoon: "",
                                Evening: "",
                                Night: "",
                                MedUsage: "",
                                MedQty: "",
                                medCommends: "",
                                MedDuration: "",
                                Medfrequency: "",
                                selectedDuration: "Days",
                                searchedBrandName: { drug_name: "", generic_name: "" },
                                TapperDrugList: [],
                                MedicDrugList: [],
                                selectedDrugId: null,
                                selectedDrug: "",
                                ExternalDrugAdd: false,
                                External_Drug_Details: {
                                  DrugName: "",
                                  GenericName: "",
                                  DosageType: "",
                                  DosageStrength: "",
                                },
                                isEdit: false,
                                EditIndex: null,
                                AllowEdit: false,
                                disableTapper: false,
                                GenericNameSearch: { drug_name: "", generic_name: "" }
                              })
                            } else {
                              if (Object.keys(this.state.Medic_Gen_Details).length <= 0 && !this.state.ExternalDrugAdd) {
                                this.errorMessage("Select Drug Name and Generic Name")
                              }
                              else if (this.state.ExternalDrugAdd && !this.state.External_Drug_Details?.DrugName) { this.errorMessage("Enter External Drug Name") }
                              // else if (this.state.ExternalDrugAdd && !this.state.External_Drug_Details?.GenericName) { this.errorMessage("Enter External Generic Name") }
                              // else if (this.state.ExternalDrugAdd && !this.state.External_Drug_Details?.DosageStrength) { this.errorMessage("Enter External Doasge Strength") }
                              else if (this.state.ExternalDrugAdd && !this.state.External_Drug_Details?.dosage_type) { this.errorMessage("Select External Doasge Type") }
                              else if (states.Medfrequency == "") {
                                this.errorMessage("Select Frequency")
                              } else if (states?.MedDuration == "" && (states?.selectedDuration !== "Until_the_Next_Visit" || states?.selectedDuration !== "Long_Term")) {
                                this.errorMessage("Select Duration")
                              }
                              else if (this.isDrugIsDuplicate()) {
                                this.errorMessage("Drug Already Added")
                              }
                            }
                          }}
                        >Add</Button>
                      </Box>
                    </Box>
                  </Box>
                  <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'} padding={"0.5vw"}>
                    <Button
                      sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                      variant='contained'
                      disabled={this.disableFunction() || this.state.DisableBtn}
                      onClick={() => {
                        if (this.state.value === "One") {
                          if ((this.state.MedicData.length > 0 || (this.state.isEdit && (Object.keys(this.state.Medic_Gen_Details).length > 0 || this.state.External_Drug_Details?.DrugName !== "") && (states.Medfrequency != "") && states?.MedDuration != "" || (states?.selectedDuration === "Until_the_Next_Visit" || states?.selectedDuration === "Long_Term")))) {
                            this.MedicTableDetails()
                          } else if (Object.keys(this.state.Medic_Gen_Details).length < 0) {
                            this.errorMessage("Select Drug Name")
                          } else if ((states.Medfrequency == "")) {
                            this.errorMessage("Select Frequency")
                          } else if (states?.MedDuration == "") {
                            this.errorMessage("Select Duration")
                          }
                        } else {
                          if (Object.keys(this.state.tapp_Gen_Details).length > 0) { this.TapperTableDetails() } else {
                            this.errorMessage("Select Drug Name");
                          }
                        }
                      }}
                    >Add</Button>
                  </Box>
                </Box> :
                // <Box component={"div"} style={{ padding: "1vw", height: "75vh", overflow: "scroll" }}>
                //   <Box component={'div'} mt={'0.5vw'} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                //     <Autocomplete
                //       size='small'
                //       sx={{ width: '30vw' }}
                //       clearIcon
                //       freeSolo
                //       options={this.state.TapperDrugList?.map((item) => item)}
                //       value={this.state.searchedBrandName ? this.state.searchedBrandName : ""}
                //       onChange={(e, value) => {
                //         this.setState({
                //           searchedBrandName: value
                //         }, () => {
                //           if (this.state.searchedBrandName?.length > 0) {
                //             let check = this.checkDuplicate()
                //             this.setState({
                //               drugList: []
                //             }, () => {
                //               if (check === null) {
                //                 this.state.drugList?.push(this.state.searchedBrandName)
                //                 this.setState({
                //                   drugList: this.state.drugList,
                //                   selectedDrug: this.state.searchedBrandName
                //                 }, () => {
                //                   this.setState({ searchedBrandName: null, TapperDrugList: [] })
                //                   this.getTapperDrugDetails()
                //                 })
                //               } else {
                //                 this.errorMessage(check)
                //                 this.setState({ searchedBrandName: null, searchedDrugList: [] })
                //               }
                //             })
                //           } else {
                //             this.setState({
                //               TapperDrugList: []
                //             })
                //           }
                //         })
                //       }}
                //       renderInput={(params) => <TextField {...params}
                //         onChange={(e) => {
                //           if (e.target.value?.length > 0) {
                //             this.setState({
                //               searchedBrandName: e.target.value
                //             }, () => this.getTapperDrugSearchList(this.state.searchedBrandName))
                //           } else {
                //             this.setState({
                //               TapperDrugList: []
                //             })
                //           }
                //         }}
                //         label='Search Drug Name' />}
                //     />
                //     <Box component={'div'}>
                //       <Typography fontWeight={600}>Available Quantity :
                //         <Typography component={'span'} fontWeight={600}>{this.state.tapp_Gen_Details?.quantity ? this.state.tapp_Gen_Details?.quantity : 0}</Typography>
                //       </Typography>
                //     </Box>
                //     <FormControl size='small' sx={{ width: '15vw' }}>
                //       <InputLabel>Select Pharmacy</InputLabel>
                //       <Select
                //         label='select Pharmacy'
                //         value={this.state.selectedPharmacy}
                //         onChange={(e) => this.setState({ selectedPharmacy: e.target.value })}
                //       >
                //         {this.state.pharmacyList?.length > 0 && this.state.pharmacyList?.map((item, index) => (
                //           <MenuItem value={item?.pharmacy_id} key={index}>{item?.pharmacy_name}</MenuItem>
                //         ))}
                //       </Select>
                //     </FormControl>
                //   </Box>
                //   <div style={{ marginTop: "0.65vw", display: "flex", flexWrap: "wrap", height: "6vw" }}>
                //     {this.state.MedicationFav?.length > 0 && this.state.MedicationFav?.map((item, index) => (
                //       <Chip
                //         className={`eMed_chip ${(item.name ? (item?.id === this.state.selectedDrugId && (item.name) === this.state.selectedDrug) : (item === this.state.selectedDrug)) ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                //         label={item.name ? item?.name : item}
                //         style={{ width: "9vw" }}
                //         variant={(item.name ? (item?.id === this.state.selectedDrugId && (item.name) === this.state.selectedDrug) : (item === this.state.selectedDrug)) ? 'contained' : 'outlined'}
                //         key={index}
                //         onClick={() => {
                //           this.setState({ selectedDrug: item, drugList: [] }, () => {
                //             this.getTapperDrugDetails()
                //           })
                //         }}
                //         clickable
                //       />
                //     ))}
                //   </div>
                //   <div>
                //     <div>
                //       <Typography fontWeight={600} color={Colors.SecondaryText}>Drug Details</Typography>
                //     </div>
                //     {Object.keys(this.state.tapp_Gen_Details).length > 0 ?
                //       <div style={{ marginTop: "0.5vw", display: "flex", flexDirection: "row", alignItems: "center" }}>
                //         <Box component={'img'} src={ImagePaths.Tablet.default} height={'1.5vw'} width={'1.5vw'} />
                //         <div style={{ marginLeft: "1vw" }}>
                //           <div>{this.state?.tapp_Gen_Details?.drug_name}</div>
                //           <div>{`${this.state?.tapp_Gen_Details?.generic_name} | ${this.state?.tapp_Gen_Details?.dosage_type}`}</div>
                //         </div>
                //         <div style={{ marginLeft: "3vw" }}>
                //           <div>{this.state?.tapp_Gen_Details?.dosage_strength}</div>
                //           <div>Strength</div>
                //         </div>
                //         <div style={{ marginLeft: "1vw" }}>
                //           <Button className='Common_Btn_Min_Width' onClick={() => { this.setState({ is_TapperFav: !this.state?.is_TapperFav }) }}>
                //             {this.state?.is_TapperFav ?
                //               <Box component={'img'} src={ImagePaths.StarFill.default} height={'1.5vw'} width={'1.5vw'} />
                //               :
                //               <Box component={'img'} src={ImagePaths.StarUnfill.default} height={'1.5vw'} width={'1.5vw'} />
                //             }
                //           </Button>
                //         </div>
                //       </div> : <div style={{ height: "3.5vw", display: "flex", alignItems: "center", justifyContent: "center" }}>No Records To Be Shown</div>}
                //     {taperUnit == "Drop(s)" ? <div>
                //       <Box component={'div'} style={{ display: "flex", flexDirection: "column", marginTop: "0.5vw" }}>
                //         <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Select Eye</Typography>
                //         <Box component={'div'}>
                //           {this.renderChip(Eye_Type, this.state.eyeType, "EyeChip")}
                //         </Box>
                //       </Box>
                //     </div> : null}
                //     <div style={{ marginTop: "0.5vw" }}>
                //       <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                //         <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Tapper Details</Typography>
                //         <Button
                //           sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                //           variant='contained'
                //           onClick={() => this.taperDetailAdd()}
                //         >Add</Button>
                //       </div>
                //       <div style={{ display: "flex", flexDirection: "row", marginTop: "0.75vw" }}>
                //         <LocalizationProvider dateAdapter={AdapterDayjs}>
                //           <DatePicker
                //             value={this.state.taperFromDate ? moment(this.state.taperFromDate) : null}
                //             inputFormat='DD-MM-YYYY'
                //             style={{ width: '20%' }}
                //             label={"Select Date"}
                //             disabled={Object.keys(this.state.tapp_Gen_Details).length > 0 ? false : true}
                //             minDate={new Date()}
                //             onChange={
                //               (newDate) => {
                //                 if (newDate == null) {
                //                   this.setState({
                //                     taperFromDate: new Date()
                //                   });
                //                 } else {
                //                   this.setState({
                //                     taperFromDate: newDate.$d
                //                   });
                //                 }
                //               }}
                //             renderInput={(params) => <TextField size='small' onChange={(newDate) => { if (newDate.$d == "Invalid Date") { this.setState({ taperFromDate: this.state.taperFromDate }) } }} {...params} />}
                //           />
                //         </LocalizationProvider>
                //         <div style={{ display: "flex", flexDirection: "row", marginLeft: "0.5vw" }}>
                //           <TextField
                //             size='small'
                //             sx={{ width: '8vw' }}
                //             value={this.state.tapDuration}
                //             label='Duration'
                //             disabled={Object.keys(this.state.tapp_Gen_Details).length > 0 ? false : true}
                //             onChange={(e) => {
                //               let isValid = CommonValidation.numberOnly(e.target.value)
                //               if (isValid || e.target.value == "") {
                //                 this.setState({ tapDuration: e.target.value })
                //               }
                //             }}
                //           />
                //           <FormControl sx={{ minWidth: 80, marginLeft: "-0.1vw" }}>
                //             <Select
                //               value={this.state.durationType}
                //               onChange={(event) => { this.setState({ durationType: event.target.value }) }}
                //               displayEmpty
                //               disabled={Object.keys(this.state.tapp_Gen_Details).length > 0 ? false : true}
                //               inputProps={{ 'aria-label': 'Without label' }}
                //               size='small'
                //             >
                //               <MenuItem value="Day(s)">Days</MenuItem>
                //               <MenuItem value="Week(s)">Weeks</MenuItem>
                //               <MenuItem value="Month(s)">Months</MenuItem>
                //             </Select>
                //           </FormControl>
                //         </div>
                //         <div style={{ display: "flex", flexDirection: "row", marginLeft: "0.5vw" }}>
                //           <div>
                //             <TextField
                //               size='small'
                //               sx={{ width: '8vw' }}
                //               value={this.state.TapperUnits}
                //               label='Unit'
                //               disabled={Object.keys(this.state.tapp_Gen_Details).length > 0 ? false : true}
                //               onChange={(e) => {
                //                 let isValid = CommonValidation.numberOnly(e.target.value)
                //                 if (isValid || e.target.value == "") {
                //                   this.setState({ TapperUnits: e.target.value })
                //                 }
                //               }}
                //             />
                //           </div>
                //           <FormControl sx={{ minWidth: 80, marginLeft: "-0.1vw" }}>
                //             <Select
                //               value={this.state.unitType}
                //               onChange={(event) => { this.setState({ unitType: event.target.value }) }}
                //               displayEmpty
                //               disabled
                //               size='small'
                //               inputProps={{ 'aria-label': 'Without label' }}
                //             >
                //               <MenuItem value="">{taperUnit}</MenuItem>
                //             </Select>
                //           </FormControl>
                //         </div>
                //         {taperUnit == "Drop(s)" ?
                //           <div>
                //             <FormControl sx={{ minWidth: 80, marginLeft: "-0.1vw" }}>
                //               <Select
                //                 value={this.state.Frequency}
                //                 onChange={(event) => { this.setState({ Frequency: event.target.value }) }}
                //                 displayEmpty
                //                 disabled={Object.keys(this.state.tapp_Gen_Details).length > 0 ? false : true}
                //                 style={{ width: '14vw', marginLeft: "0.5vw" }}
                //                 inputProps={{ 'aria-label': 'Without label' }}
                //                 size='small'
                //               >
                //                 <MenuItem value="">Select Frequency</MenuItem>
                //                 <MenuItem value={"1"}>1 time a day</MenuItem>
                //                 <MenuItem value={"2"}>2 time a day</MenuItem>
                //                 <MenuItem value={"3"}>3 time a day</MenuItem>
                //                 <MenuItem value={"4"}>4 time a day</MenuItem>
                //                 <MenuItem value={"5"}>5 time a day</MenuItem>
                //                 <MenuItem value={"6"}>6 time a day</MenuItem>
                //                 <MenuItem value={"10"}>10 time a day</MenuItem>
                //                 <MenuItem value={"12"}>12 time a day</MenuItem>
                //               </Select>
                //             </FormControl>
                //           </div> :
                //           <div style ={{display: "inline-flex"}}>
                //             <TextField
                //               size='small'
                //               sx={{ width: '14vw', marginLeft: "0.5vw" }}
                //               value={this.state.reducedBy}
                //               label='Reduced by'
                //               disabled={Object.keys(this.state.tapp_Gen_Details).length > 0 ? false : true}
                //               onChange={(e) => {
                //                 let isValid = CommonValidation.numberOnly(e.target.value)
                //                 if (isValid || e.target.value == "") {
                //                   this.setState({ reducedBy: e.target.value })
                //                 }
                //               }}
                //             />

                //             <Autocomplete
                //             size='small'
                //             sx={{ width: '15vw', marginLeft: "0.5vw" }}
                //             clearIcon
                //             freeSolo
                //             options={this.state.frequency_list} 
                //             getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.frequency_name}
                //             value={this.state.frequency_tablet}
                //             onChange={(e, value) => {
                //               this.setState({
                //                 frequency_tablet: value
                //               })
                //             }}

                //             renderInput={(params) => <TextField {...params} 
                //             value ={this.state.frequency_tablet}
                //              onChange ={(e) => {
                //                 this.setState({
                //                   frequency_tablet : e.target.value
                //                 })
                //              }}
                //              inputProps={{ ...params.inputProps, maxLength: 50 }}
                //              label='Frequency' />}
                //           />
                //         </div>
                //           }

                //       </div>
                //       <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "0.65vw" }}>
                //         <div>
                //           <TextField
                //             size='small'
                //             sx={{ width: '17vw' }}
                //             value={this.state.tapperQty}
                //             label='Quantity'
                //             onChange={(e) => {
                //               this.setState({ tapperQty: e.target.value })
                //             }}
                //           />
                //         </div>
                //         <div>
                //           <TextField
                //             size='small'
                //             sx={{ width: '40vw', marginLeft: "0.5vw" }}
                //             value={this.state.tapperComm}
                //             label='comments'
                //             onChange={(e) => {
                //               this.setState({ tapperComm: e.target.value })
                //             }}
                //           />
                //         </div>
                //       </div>
                //       <div style={{ marginTop: "1vw", maxHeight: '12vw', overflow: "scroll" }}>
                //         <Table stickyHeader size='small'>
                //           <TableHead>
                //             <TableRow>
                //               <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, color: Colors.SecondaryText, backgroundColor: Colors.Background }}>
                //                 <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                //                   <Button className='Common_Btn_Min_Width'
                //                     onClick={() => { }}
                //                   >
                //                     <Box component={'img'} src={ImagePaths.Delete.default} height={'1.5vw'} width={'1.5vw'} />
                //                   </Button>
                //                   <div style={{ marginLeft: "0.5vw" }}>Days</div>
                //                 </div></TableCell>
                //               <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Unit</TableCell>
                //               <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Frequency</TableCell>
                //               <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Reduced By</TableCell>
                //               <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Action</TableCell>
                //             </TableRow>
                //           </TableHead>
                //           <TableBody>
                //             {this.state.taperAddedDetails?.length > 0 ? this.state.taperAddedDetails?.map((item, index) => (
                //               <TableRow key={index}>
                //                 <TableCell>
                //                   <div>
                //                     <div style={{ fontWeight: "600" }}>{item?.duration.split(" ")[1] == "Day(s)" ? "Days" : item?.duration.split(" ")[1] == "Week(s)" ? "Weeks" : "Months"}</div>
                //                     <div>{(item.start_date).split("-").reverse().join("-") + " - " + (item.end_date).split("-").reverse().join("-")}</div>
                //                   </div>
                //                 </TableCell>
                //                 <TableCell>{item?.no_of_dosage ? item?.no_of_dosage : "-"}</TableCell>
                //                 <TableCell>{item?.frequency ? item?.frequency : "-"}</TableCell>
                //                 <TableCell>{item?.reducedBy ? item?.reducedBy : "-"}</TableCell>
                //                 <TableCell>
                //                   <Box component={'div'} display={'flex'} justifyContent={'flex-start'} flexDirection={'row'} alignItems={'center'}>
                //                     <Button
                //                       className='Common_Btn_Min_Width'
                //                       onClick={() => {
                //                         this.onEditTapper(item, index)
                //                       }}
                //                       disabled={this.state.isLocked || this.props.isLocked}
                //                     >
                //                       <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                //                     </Button>
                //                     <Button
                //                       className='Common_Btn_Min_Width'
                //                       onClick={() => this.onDltTapper(index)}
                //                       disabled={this.state.isLocked || this.props.isLocked}
                //                     >
                //                       <Box component={'img'} src={ImagePaths.Delete.default} height={'1.2vw'} width={'1.2vw'} />
                //                     </Button>
                //                   </Box>
                //                 </TableCell>
                //               </TableRow>
                //             )) : null}
                //           </TableBody>
                //         </Table>
                //       </div>
                //     </div>
                //   </div>
                // </Box>
                this.renderNewTapperMedScreen()
              }
            </Box>
          </Modal>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderNewTapperMedScreen = () => {
    try {
      return (
        <Box component={'div'} display={'flex'} height={'80dvh'} overflow={'auto'}>
          <Box component={'div'} width={'54%'}>
            {this.renderLeftSideTapperMed()}
          </Box>
          <Box component={'div'} width={'46%'}>
            {this.renderRightSideTapperMed()}
          </Box>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderLeftSideTapperMed = () => {
    try {
      return (
        <Box component={'div'} m={'0.5dvw'}>
          <Box component={'div'} border={'1px solid lightgray'} maxHeight={'70dvh'} overflow={'auto'}>
            <Table size='small' stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ backgroundColor: Colors.Background, width: '20%' }}>
                    <Typography fontSize={'0.9dvw'} fontWeight={600}>Brand Name</Typography>
                  </TableCell>
                  <TableCell sx={{ backgroundColor: Colors.Background, width: '25%' }}>
                    <Typography fontSize={'0.9dvw'} fontWeight={600}>Tapper Details</Typography>
                  </TableCell>
                  <TableCell sx={{ backgroundColor: Colors.Background, width: '25%' }}>
                    <Typography fontSize={'0.9dvw'} fontWeight={600}>Frequency/Unit</Typography>
                  </TableCell>
                  <TableCell sx={{ backgroundColor: Colors.Background, width: '15%' }}>
                    <Typography fontSize={'0.9dvw'} fontWeight={600}>Quantity</Typography>
                  </TableCell>
                  <TableCell sx={{ backgroundColor: Colors.Background, width: '15%' }}>
                    <Typography fontSize={'0.9dvw'} fontWeight={600}>Action</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.newTapMedListData?.length > 0 ? this.state.newTapMedListData?.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        {item?.brand_name ? item?.brand_name?.length > 15 ?
                          <Tooltip placement='top' title={item?.brand_name}>
                            <Typography>{item?.brand_name?.slice(0, 15) + '...'}</Typography>
                          </Tooltip> :
                          <Typography fontSize={'0.8dvw'}>{item?.brand_name}</Typography> : '-'}
                      </TableCell>
                      <TableCell>
                        {item?.tapper_meds?.length > 0 ? item?.tapper_meds?.map((tapList, tapIndex) => {
                          return (
                            <Box component={'div'} key={tapIndex}>
                              <Typography fontSize={'0.8dvw'}>{`${tapList?.eye_type ? `${tapList?.eye_type} -` : ''} ${tapList?.duration ? tapList?.duration : '-'}`}</Typography>
                              <Typography fontSize={'0.8dvw'}>{`${tapList?.start_date ? tapList?.start_date : '-'} - ${tapList?.end_date ? tapList?.end_date : '-'}`}</Typography>
                            </Box>
                          )
                        }) : '-'}
                      </TableCell>
                      <TableCell>
                        {item?.tapper_meds?.length > 0 ? item?.tapper_meds?.map((tapList, tapIndex2) => {
                          return (
                            <Box component={'div'} key={tapIndex2}>
                              <Typography fontSize={'0.8dvw'}>{`${tapList?.frequency ? tapList?.frequency : '-'}`}</Typography>
                              <Typography fontSize={'0.8dvw'}>{`${tapList?.no_of_dosage ? tapList?.no_of_dosage : '-'}`}</Typography>
                            </Box>
                          )
                        }) : '-'}
                      </TableCell>
                      <TableCell>
                        <Typography fontSize={'0.8dvw'}>{item?.quantity ? item?.quantity : ''}</Typography>
                      </TableCell>
                      <TableCell>
                        <Box component={'div'} display={'flex'}>
                          <Button className='Common_Btn_Min_Width'
                            disabled={this.state.AddClicked && item?.id ? true : false}
                            onClick={() => { this.setState({ newLftTapEdit: true }, () => this.onLftTapMedEditHandler(item, index)) }}
                          >
                            <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.3dvw'} width={'1.3dvw'} />
                          </Button>
                          <Button className='Common_Btn_Min_Width' disabled={this.state.AddClicked && item?.id ? true : false || this.state.newLftTapEdit}
                            onClick={() => {
                              this.state.newTapMedListData?.splice(index, 1)
                              this.setState({ newTapMedListData: this.state.newTapMedListData })
                            }}
                          >
                            <Box component={'img'} src={ImagePaths.Delete.default} height={'1.3dvw'} width={'1.3dvw'} />
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                }) : <TableRow><TableCell align='center' colSpan={5}>No Records To Be Shown</TableCell></TableRow>}
              </TableBody>
            </Table>
          </Box>
          <Box component={'div'} mt={'1dvw'} display={'flex'} justifyContent={'center'}>
            {/* <Button
              sx={{ textTransform: 'capitalize', height: '2dvw', mr: '0.5dvw' }}
              variant='outlined'
            >Clear</Button> */}
            <Button
              sx={{ textTransform: 'capitalize', height: '2dvw' }}
              variant='contained'
              onClick={() => this.postNewTapperMedData()}
            >Save</Button>
          </Box>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onLftTapMedEditHandler = (item, index) => {
    try {
      this.setState({
        leftSelectedIndex: index,
        newTapMedDrugListData: item?.tapper_meds ? JSON.parse(JSON.stringify(item?.tapper_meds)) : [],
        newTapMedQty: item?.quantity ? item?.quantity : '',
        newTapMedUsage: item?.instructions ? item?.instructions : '',
        newTapMedComments: item?.comments ? item?.comments : '',
        newTapMedGenList: item ? JSON.parse(JSON.stringify(item)) : null,
        newTapSearchDrug: item?.brand_name ? item?.brand_name : '',
        eyeType: item?.eye_type ? item?.eye_type : '',
        prescriptionId: item?.prescription_id ? item?.prescription_id : null,
        newTapMedId: item?.id ? item?.id : null
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderRightSideTapperMed = () => {
    try {
      let len = this.state?.newTapMedGenList
      let taperUnit = ""
      let states = this.state
      if (this.state?.newTapMedGenList) {
        if ((this.state?.newTapMedGenList?.dosage_type).toUpperCase() === "STEROID TABLETS") {
          taperUnit = "Mg"
        } else {
          taperUnit = "Drop(s)"
        }
      }
      return (
        <Box component={'div'} m={'0.5dvw'}>
          <Box component={'div'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <FormControl size='small' sx={{ width: '15vw' }}>
              <InputLabel>Select Pharmacy</InputLabel>
              <Select
                disabled={this.state.newTapMedListData?.length > 0 ? (this.state.newTapMedListData[0]?.pharmacy_id === this.state.selectedPharmacy ? true : false) : false}
                label='select Pharmacy'
                value={this.state.selectedPharmacy}
                onChange={(e) => this.setState({ selectedPharmacy: e.target.value, isChange: true }, () => this.getMedicationFavouriteData())}
              >
                {this.state.pharmacyList?.length > 0 && this.state.pharmacyList?.map((item, index) => (
                  <MenuItem value={item?.pharmacy_id} key={index}>{item?.pharmacy_name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box component={'div'} pr={'0.5dvw'}>
              <Typography fontWeight={600}>Available Quantity :
                <Typography component={'span'} fontWeight={600}>{this.state.newTapMedGenList?.quantity ? this.state.newTapMedGenList?.quantity : 0}</Typography>
              </Typography>
            </Box>
          </Box>
          <Box component={'div'} mt={'1dvw'} height={'62dvh'} overflow={'auto'}>
            <Autocomplete
              clearIcon
              size='small'
              sx={{ width: '20vw', mt: '0.5vw' }}
              options={this.state.TapperDrugList}
              getOptionLabel={(option) => typeof (option) === 'string' ? option : option}
              value={this.state.newTapSearchDrug}
              onChange={(e, value) => {
                this.setState({ newTapSearchDrug: value }, () => {
                  if (this.state.newTapSearchDrug) {
                    this.setState({
                      selectedDrugId: null,
                      selectedDrug: '',
                      newTapMedUsage: '',
                      newTapMedQty: '',
                      newTapMedComments: ''
                    }, () => this.getTapperDrugDetails())
                  } else {
                    this.setState({
                      newTapMedGenList: null,
                      newTapMedUsage: '',
                      newTapMedQty: '',
                      newTapMedComments: '',
                      TapperDrugList: [],
                    }, () => this.onClearLeftTapperDetails())
                  }
                })
              }}
              renderInput={(params) => <TextField {...params} label={'Search Drug Name'}
                onChange={(e) => {
                  if (e.target.value?.length > 0) {
                    this.setState({
                      newTapSearchDrug: e.target.value,
                      newTapMedUsage: '',
                      newTapMedQty: '',
                      newTapMedComments: ''
                    }, () => this.getTapperDrugSearchList(this.state.newTapSearchDrug))
                  } else {
                    this.setState({
                      TapperDrugList: [],
                      newTapMedGenList: null,
                      newTapMedUsage: '',
                      newTapMedQty: '',
                      newTapMedComments: ''
                    }, () => this.onClearLeftTapperDetails())
                  }
                }}
              />}
            />
            <Box component={'div'} display={'flex'} flexWrap={'wrap'} mt={'0.5dvw'}>
              {this.state.MedicationFav?.length > 0 && this.state.MedicationFav?.map((item, index) => (
                <Chip
                  className={`eMed_chip ${(item.name ? (item?.id === this.state.selectedDrugId && (item.name) === this.state.selectedDrug) : (item === this.state.selectedDrug)) ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                  label={item.name ? item?.name : item}
                  style={{ width: "9vw" }}
                  variant={(item.name ? (item?.id === this.state.selectedDrugId && (item.name) === this.state.selectedDrug) : (item === this.state.selectedDrug)) ? 'contained' : 'outlined'}
                  key={index}
                  onClick={() => {
                    this.setState({
                      selectedDrug: item,
                      drugList: [],
                      newTapMedUsage: '',
                      newTapMedQty: '',
                      newTapMedComments: ''
                    }, () => {
                      this.getTapperDrugDetails()
                    })
                  }}
                  clickable
                />
              ))}
            </Box>
            <Box component={'div'} mt={'1dvw'}>
              <TextField
                autoComplete='off'
                size='small'
                sx={{ width: '8dvw', mr: '0.5dvw' }}
                value={this.state.newTapMedQty}
                label='Quantity'
                onChange={(e) => {
                  this.setState({ newTapMedQty: e.target.value })
                }}
              />
              <FormControl size='small' sx={{ width: '10dvw', mr: '0.5dvw' }}>
                <InputLabel>Select Usage</InputLabel>
                <Select
                  label='Select Usage'
                  value={this.state.newTapMedUsage}
                  onChange={(e) => this.setState({ newTapMedUsage: e.target.value })}
                >
                  {this.state.foodUsage?.length > 0 && this.state.foodUsage?.map((item, index) => (
                    <MenuItem value={item?.value} key={index}>{item?.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                autoComplete='off'
                size='small'
                sx={{ width: '17dvw' }}
                value={this.state.newTapMedComments}
                label='comments'
                onChange={(e) => {
                  this.setState({ newTapMedComments: e.target.value })
                }}
              />
            </Box>
            {this.state.newTapMedGenList ?
              <Box component={'div'} mt={'0.5dvw'}>
                <Typography fontWeight={600} color={Colors.SecondaryText}>Drug Details</Typography>
                <Box component={'div'} display={'flex'} alignItems={'center'}>
                  <Box component={'img'} src={ImagePaths.Tablet.default} height={'1.5vw'} width={'1.5vw'} />
                  <Box component={'div'} m={'0vw 0.5vw'}>
                    <Typography>{this.state?.newTapMedGenList?.drug_name}</Typography>
                    <Typography>{`${this.state?.newTapMedGenList?.generic_name} | ${this.state?.newTapMedGenList?.dosage_type}`}</Typography>
                  </Box>
                  <Box component={'div'} m={'0vw 0.5vw'}>
                    <Typography>{this.state?.newTapMedGenList?.dosage_strength}</Typography>
                    <Typography>Strength</Typography>
                  </Box>
                  <Box component={'div'}>
                    <Button className='Common_Btn_Min_Width' onClick={() => { this.setState({ newTapMedIsFav: !this.state?.newTapMedIsFav }) }}>
                      {this.state?.newTapMedIsFav ?
                        <Box component={'img'} src={ImagePaths.StarFill.default} height={'1.5vw'} width={'1.5vw'} />
                        :
                        <Box component={'img'} src={ImagePaths.StarUnfill.default} height={'1.5vw'} width={'1.5vw'} />}
                    </Button>
                  </Box>
                </Box>
              </Box> : null}
            {taperUnit == "Drop(s)" ? <Box>
              <Box component={'div'} style={{ display: "flex", flexDirection: "column", marginTop: "0.5vw" }}>
                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Select Eye</Typography>
                <Box component={'div'}>
                  {this.renderChip(Eye_Type, this.state.eyeType, "EyeChip")}
                </Box>
              </Box>
            </Box> : null}
            <Box component={'div'} mt={'0.5dvw'}>
              <Typography fontWeight={600} color={Colors.SecondaryText}>Tapper Details</Typography>
              <Box component={'div'} mt={'0.5dvw'}>
                <Box component={'div'} display={'flex'}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      open={this.state.newTapMedOpenDate}
                      onOpen={() => { this.setState({ newTapMedOpenDate: true }) }}
                      onClose={() => { this.setState({ newTapMedOpenDate: false }) }}
                      value={this.state.newTapMedDate}
                      inputFormat='DD-MM-YYYY'
                      label={"Select Date"}
                      disabled={this.state.newTapMedGenList ? false : true}
                      minDate={new Date()}
                      onChange={(value) => { this.setState({ newTapMedDate: value }) }}
                      renderInput={(params) => <TextField size='small' {...params}
                        sx={{ width: '10dvw' }}
                        onKeyDown={(e) => e.preventDefault()}
                        onClick={() => { this.setState({ newTapMedOpenDate: true }) }}
                      />}
                    />
                  </LocalizationProvider>
                  <Box component={'div'} ml={'0.5dvw'}>
                    <TextField
                      autoComplete='off'
                      size='small'
                      sx={{ width: '6dvw' }}
                      value={this.state.newTapMedDuration}
                      label='Duration'
                      disabled={this.state.newTapMedGenList ? false : true}
                      onChange={(e) => {
                        let isValid = CommonValidation.numberOnly(e.target.value)
                        if (isValid || e.target.value == "") {
                          this.setState({ newTapMedDuration: e.target.value })
                        }
                      }}
                    />
                    <FormControl sx={{ width: '6dvw' }}>
                      <Select
                        value={this.state.newTapDurationType}
                        onChange={(event) => { this.setState({ newTapDurationType: event.target.value }) }}
                        displayEmpty
                        disabled={this.state.newTapMedGenList ? false : true}
                        inputProps={{ 'aria-label': 'Without label' }}
                        size='small'
                      >
                        <MenuItem value="Day(s)">Days</MenuItem>
                        <MenuItem value="Week(s)">Weeks</MenuItem>
                        <MenuItem value="Month(s)">Months</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box component={'div'} ml={'0.5dvw'}>
                    <TextField
                      autoComplete='off'
                      size='small'
                      sx={{ width: '6dvw' }}
                      value={this.state.newTapMedUnit}
                      label='Unit'
                      disabled={this.state.newTapMedGenList ? false : true}
                      onChange={(e) => {
                        let isValid = CommonValidation.numberOnly(e.target.value)
                        if (isValid || e.target.value == "") {
                          this.setState({ newTapMedUnit: e.target.value })
                        }
                      }}
                    />
                    <FormControl sx={{ width: '7dvw' }}>
                      <Select
                        value={this.state.newTapMedUnitType}
                        onChange={(event) => { this.setState({ newTapMedUnitType: event.target.value }) }}
                        displayEmpty
                        disabled
                        size='small'
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem value="">{taperUnit}</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box component={'div'} display={'flex'} mt={'1dvw'}>
                  {taperUnit == "Drop(s)" ?
                    <Box component={'div'}>
                      <FormControl sx={{ minWidth: 80 }}>
                        <Select
                          value={this.state.newTapMedFrequency}
                          onChange={(event) => { this.setState({ newTapMedFrequency: event.target.value }) }}
                          displayEmpty
                          disabled={this.state.newTapMedGenList ? false : true}
                          style={{ width: '15vw', marginLeft: "0.5vw" }}
                          inputProps={{ 'aria-label': 'Without label' }}
                          size='small'
                        >
                          <MenuItem value="">Select Frequency</MenuItem>
                          <MenuItem value={"1"}>1 time a day</MenuItem>
                          <MenuItem value={"2"}>2 time a day</MenuItem>
                          <MenuItem value={"3"}>3 time a day</MenuItem>
                          <MenuItem value={"4"}>4 time a day</MenuItem>
                          <MenuItem value={"5"}>5 time a day</MenuItem>
                          <MenuItem value={"6"}>6 time a day</MenuItem>
                          <MenuItem value={"10"}>10 time a day</MenuItem>
                          <MenuItem value={"12"}>12 time a day</MenuItem>
                        </Select>
                      </FormControl>
                    </Box> :
                    <Box component={'div'} display={'flex'}>
                      <TextField
                        autoComplete='off'
                        size='small'
                        sx={{ width: '12dvw' }}
                        value={this.state.newTapMedReduceBy}
                        label='Reduced by'
                        disabled={this.state.newTapMedGenList ? false : true}
                        onChange={(e) => {
                          let isValid = CommonValidation.numberOnly(e.target.value)
                          if (isValid || e.target.value == "") {
                            this.setState({ newTapMedReduceBy: e.target.value })
                          }
                        }}
                      />
                      <Autocomplete
                        size='small'
                        sx={{ width: '12.3dvw', marginLeft: "0.5dvw" }}
                        clearIcon
                        options={this.state.frequency_list}
                        getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.frequency_name}
                        value={this.state.newTapMedFreqTablet}
                        onChange={(e, value) => {
                          this.setState({
                            newTapMedFreqTablet: value
                          })
                        }}
                        renderInput={(params) => <TextField {...params}
                          value={this.state.newTapMedFreqTablet}
                          onChange={(e) => {
                            this.setState({
                              newTapMedFreqTablet: e.target.value
                            })
                          }}
                          inputProps={{ ...params.inputProps, maxLength: 50 }}
                          label='Frequency' />}
                      />
                    </Box>}
                  <Button
                    sx={{ textTransform: 'capitalize', ml: '0.5dvw' }}
                    variant='contained'
                    onClick={() => this.onNewTappperMedAddHandler()}
                  >Add</Button>
                </Box>
              </Box>
            </Box>
            <Box component={'div'} border={'1px solid lightgray'} mt={'1dvw'}>
              <Table size='small' stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ backgroundColor: Colors.Background, width: '25%' }}>
                      <Typography fontWeight={600} fontSize={'0.9dvw'}>Days</Typography>
                    </TableCell>
                    <TableCell sx={{ backgroundColor: Colors.Background, width: '20%' }}>
                      <Typography fontWeight={600} fontSize={'0.9dvw'}>Unit</Typography>
                    </TableCell>
                    <TableCell sx={{ backgroundColor: Colors.Background, width: '20%' }}>
                      <Typography fontWeight={600} fontSize={'0.9dvw'}>Frequency</Typography>
                    </TableCell>
                    <TableCell sx={{ backgroundColor: Colors.Background, width: '20%' }}>
                      <Typography fontWeight={600} fontSize={'0.9dvw'}>Reduced By</Typography>
                    </TableCell>
                    <TableCell sx={{ backgroundColor: Colors.Background, width: '15%' }}>
                      <Typography fontWeight={600} fontSize={'0.9dvw'}>Action</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.newTapMedDrugListData?.length > 0 ? this.state.newTapMedDrugListData?.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <Typography fontSize={'0.8dvw'}>{`${item?.start_date} - ${item?.end_date}`}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize={'0.8dvw'}>{item?.no_of_dosage ? item?.no_of_dosage : '-'}</Typography>
                          <Typography fontSize={'0.8dvw'}>{item?.instructions ? item?.instructions : ''}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize={'0.8dvw'}>{item?.frequency ? item?.frequency : '-'}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize={'0.8dvw'}>{item?.reducedBy ? item?.reducedBy : '-'}</Typography>
                        </TableCell>
                        <TableCell>
                          <Box component={'div'} display={'flex'}>
                            <Button className='Common_Btn_Min_Width'
                              onClick={() => {
                                let obj = {
                                  dupDate: this.state.newTapMedDate,
                                  dupDuration: this.state.newTapMedDuration,
                                  dupDurType: this.state.newTapDurationType,
                                  dupUnit: this.state.newTapMedUnit,
                                  dupUnitType: this.state.newTapMedUnitType,
                                  dupRedBy: this.state.newTapMedReduceBy,
                                  dupFreq: this.state.newTapMedFrequency,
                                  dupFreqTab: this.state.newTapMedFreqTablet
                                }
                                this.setState({
                                  isNewTapEdit: true,
                                  dupTapMedDetails: JSON.stringify(obj)
                                }, () => { this.onNewTapEditHandler(item, index) })
                              }}
                            >
                              <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.3dvw'} width={'1.3dvw'} />
                            </Button>
                            <Button className='Common_Btn_Min_Width' disabled={this.state.isNewTapEdit}
                              onClick={() => {
                                this.state.newTapMedDrugListData?.splice(index, 1)
                                this.setState({ newTapMedDrugListData: this.state.newTapMedDrugListData })
                              }}
                            >
                              <Box component={'img'} src={ImagePaths.Delete.default} height={'1.3dvw'} width={'1.3dvw'} />
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )
                  }) : <TableRow><TableCell align='center' colSpan={6}>No Records To Be Shown</TableCell></TableRow>}
                </TableBody>
              </Table>
            </Box>
          </Box>
          <Box component={'div'} display={'flex'} justifyContent={'center'} mt={'1dvw'}>
            <Button
              sx={{ textTransform: 'capitalize', height: '2dvw', mr: '0.5dvw' }}
              variant='outlined'>Clear</Button>
            <Button
              sx={{ textTransform: 'capitalize', height: '2dvw' }}
              onClick={() => this.onAddDrugListHandler()}
              variant='contained'>Add</Button>
          </Box>
        </Box >
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onNewTappperMedAddHandler = () => {
    try {
      let states = this.state
      let startDate = new Date(states.newTapMedDate)
      let newTapStartDate = DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd')
      var duration = states.newTapMedDuration
      var frequency = (states.newTapMedGenList?.dosage_type)?.toUpperCase() === "STEROID TABLETS" ? "" : states.newTapMedFrequency
      var reducedBy = (states.newTapMedGenList?.dosage_type)?.toUpperCase() === "STEROID TABLETS" ? states.newTapMedReduceBy : ""

      if (states.newTapDurationType === "Week(s)") {
        duration = +(states.newTapMedDuration) * 7
      } else if (states.newTapDurationType === "Month(s)") {
        duration = +(states.newTapMedDuration) * 30
      }

      let date = new Date(startDate);
      date.setDate(date.getDate() + (+duration));

      let dosageCountLabel = (states.newTapMedGenList?.dosage_type).toUpperCase() === "STEROID TABLETS" ? 'Mg' : 'Drop(s)'
      let frequencyLabel = dosageCountLabel === "Drop(s)" ? ((+frequency == 1) ? ' time a day' : ' times a day') : this.state.newTapMedFreqTablet

      let tapperDetails = {
        start_date: newTapStartDate,
        no_of_dosage: states.newTapMedUnit + " " + dosageCountLabel,
        duration: states.newTapMedDuration + " " + states.newTapDurationType,
        end_date: DateTime.fromJSDate(date).toFormat('yyyy-MM-dd'),
        frequency: frequency + frequencyLabel,
        reducedBy: reducedBy,
        instructions: states.newTapMedUsage,
        eye_type: states.eyeType,
        unit: states.newTapMedUnit,
        unitType: dosageCountLabel,
        freq: (states.newTapMedGenList?.dosage_type)?.toUpperCase() === "STEROID TABLETS" ? states.newTapMedFreqTablet : states.newTapMedFrequency,
        duration1: states.newTapMedDuration,
        durationType: states.newTapDurationType
      }
      let chekNewTap = this.newCheckTapValidation()
      if (chekNewTap === null) {
        let data = CommonValidation.updateOrInsertList(this.state.newTapMedDrugListData, tapperDetails, this.state.newTapMedSelectedIndex)
        this.setState({ newTapMedDrugListData: data }, () => {
          if (this.state.isNewTapEdit) {
            let dupState = JSON.parse(this.state.dupTapMedDetails)
            this.setState({
              isNewTapEdit: false,
              newTapMedDate: dupState?.dupDate,
              newTapMedUnit: dupState?.dupUnit,
              newTapMedFrequency: dupState?.dupFreq,
              newTapMedFreqTablet: dupState?.dupFreqTab,
              newTapMedReduceBy: dupState?.dupRedBy,
              newTapMedUnitType: dupState?.dupUnitType,
              newTapMedDuration: dupState?.dupDuration,
              newTapDurationType: dupState?.dupDurType,
              newTapMedSelectedIndex: null,
            }, () => this.setState({ dupTapMedDetails: '{}' }))
          } else {
            let nextDate = new Date(date)
            nextDate.setDate(nextDate.getDate() + 1)
            if (frequency == 1) {
              frequency = frequency
            } else if (frequency == 12) {
              frequency = 10
            } else if (frequency == 10) {
              frequency = 6
            } else {
              frequency = +(frequency) - 1
            }
            this.setState({
              isNewTapEdit: false,
              newTapMedDate: nextDate,
              newTapMedUnit: +states.newTapMedReduceBy < +states.newTapMedUnit ? (+states.newTapMedUnit) - (+states.newTapMedReduceBy) : states.newTapMedUnit,
              newTapMedFrequency: frequency + "",
              newTapMedSelectedIndex: null
            })
          }
        })
      } else {
        this.errorMessage(chekNewTap)
      }

    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  newCheckTapValidation = () => {
    try {
      let message = null;
      let states = this.state
      let steroid = (states.newTapMedGenList?.dosage_type)?.toUpperCase() === "STEROID TABLETS" ? true : false
      if (states.newTapMedDuration === '') {
        message = 'Enter Duration'
      } else if (states.newTapMedUnit === '') {
        message = 'Enter Unit'
      } else if (states.newTapMedReduceBy === '' && steroid) {
        message = 'Enter Reduce By'
      }

      return message;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onNewTapEditHandler = (item, index) => {
    try {
      this.setState({
        newTapMedDate: item?.start_date,
        newTapMedDuration: item?.duration1,
        newTapDurationType: item?.durationType,
        newTapMedUnit: item?.unit,
        newTapMedUnitType: item?.unitType,
        newTapMedFrequency: item?.freq,
        newTapMedSelectedIndex: index,
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onAddDrugListHandler = () => {
    try {
      let states = this.state
      let list = {
        "eye_type": states.eyeType ? states.eyeType : '',
        "brand_name": states.newTapMedGenList?.drug_name ? states.newTapMedGenList?.drug_name : states.newTapMedGenList?.brand_name ? states.newTapMedGenList?.brand_name : '',
        "generic_name": states.newTapMedGenList?.generic_name ? states.newTapMedGenList?.generic_name : '',
        "dosage_strength": states.newTapMedGenList?.dosage_strength ? states.newTapMedGenList?.dosage_strength : '',
        "drug_id": states.newTapMedGenList?.drug_ms_id ? states.newTapMedGenList?.drug_ms_id : null,
        "dosage_type": states.newTapMedGenList?.dosage_type ? states.newTapMedGenList?.dosage_type : '',
        "frequency": "NaN",
        "duration": states.newTapMedDuration === 'Day(s)' ? 'Days' : states.newTapMedDuration === 'Week(s)' ? 'Weeks' : 'Months',
        "instructions": states.newTapMedUsage ? states.newTapMedUsage : '',
        "comments": states.newTapMedComments ? states.newTapMedComments : '',
        "quantity": states.newTapMedQty ? +states.newTapMedQty : '',
        "pharmacy_id": states.selectedPharmacy ? states.selectedPharmacy : null,
        "isAddNewClick": false,
        "is_favorite": states.newTapMedIsFav ? true : false,
        "morning": 0,
        "afternoon": 0,
        "evening": 0,
        "night": 0,
        "no_of_dosage": 0,
        "is_tapper_meds": true,
        "tapper_meds": states.newTapMedDrugListData ? states.newTapMedDrugListData : []
      }
      if (this.state.newTapMedId) {
        list['id'] = this.state.newTapMedId
      }

      let listData = CommonValidation.updateOrInsertList(this.state.newTapMedListData, list, this.state.leftSelectedIndex)
      this.setState({
        newTapMedListData: listData,
        newLftTapEdit: false,
        leftSelectedIndex: null,
        newTapMedId: null
      }, () => this.onClearRightTaperHandler())
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onClearRightTaperHandler = () => {
    try {
      this.setState({
        eyeType: '',
        newTapSearchDrug: null,
        newTapMedDate: new Date(),
        newTapMedOpenDate: false,
        newTapMedDrugListData: [],
        newTapMedDuration: '',
        newTapDurationType: 'Day(s)',
        newTapMedUnit: "",
        newTapMedUnitType: '',
        newTapMedFrequency: '',
        newTapMedReduceBy: '',
        newTapMedFreqTablet: null,
        newTapMedQty: '',
        newTapMedUsage: '',
        newTapMedComments: '',
        newTapMedGenList: null,
        isNewTapEdit: false,
        newTapMedSelectedIndex: null,
        leftSelectedIndex: null,
        selectedDrugId: null,
        selectedDrug: ''
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onClearLeftTapperDetails = () => {
    try {
      this.setState({
        newTapMedDate: new Date(),
        newTapMedDuration: '',
        newTapDurationType: 'Day(s)',
        newTapMedDrugListData: [],
        newTapMedUnit: "",
        newTapMedUnitType: '',
        newTapMedFrequency: '',
        newTapMedReduceBy: '',
        newTapMedFreqTablet: null,
        // newTapMedQty: '',
        // newTapMedUsage: '',
        // newTapMedComments: '',
        // newTapMedGenList: null
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  disableFunction = () => {
    if (this.state.value === "One") {
      if (this.state.MedicData.length < 0 && !this.state.isEdit) {
        return true
      } else {
        return false
      }
    } else {
      if (Object.keys(this.state.tapp_Gen_Details).length < 0) {
        return true
      } else {
        return false
      }
    }
  }

  clearTapperDetails = (FromButton = false) => {
    this.setState({
      tapp_Gen_Details: {},
      taperFromDate: new Date(),
      Frequency: "",
      tapDuration: "",
      durationType: "Day(s)",
      TapperUnits: "",
      unitType: "",
      TapperDrugList: [],
      reducedBy: "",
      tapperQty: "",
      is_MefiFav: false,
      is_TapperFav: false,
      taperAddedDetails: [],
      selectedEditIndex: null,
      selectedDrug: '',
      Medic_Gen_Details: {},
      eyeType: "",
      Medfrequency: "",
      searchedBrandName: null,
      MedicDrugList: [],
      tapperComm: "",
      openPrevsMedic: false,
      selectedDrugId: null,
      editItem: null,
      ExternalDrugAdd: FromButton ? this.state.ExternalDrugAdd : false,
      External_Drug_Details: {
        DrugName: "",
        GenericName: "",
        DosageType: "",
        DosageStrength: "",
      },
      EditIndex: null,
      DrugSuggestionList: [],
      AllowEdit: false,
      value: "One",
      drugList: [],
      ExternalBrandName: null,
      ExternalDrugList: [],
      GenericNameSearch: null,
      frequency_tablet:"",
      tapUsage:''
    })
  }

  renderPrescriptionPopup = () => {
    try {
      return (
        <Box component={'div'}>
          <Modal open={this.state.openPrescriptionPopUp}>
            <Box component={'div'} className='eMed_Doc_Notes_Prescrip_Pop_Up'>
              <Box component={'div'} p={'0.5vw'} height={'2.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography fontWeight={600} color={Colors.SecondaryText}>Add Prescription</Typography>
                <Button onClick={() => this.setState({ openPrescriptionPopUp: false }, () => {
                  this.onClearHandler()
                  this.setState({
                    prescriptionList: [],
                    prescriptionData: this.state.prescriptionData,
                    selectedPharmacy: '',
                    selectedIndex: null
                  })
                })}>
                  <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1.1vw'} width={'1.1vw'} />
                </Button>
              </Box>
              <Box component={'div'} m={'0.5vw'} pt={'0.5vw'} maxHeight={'45vw'} overflow={'auto'}>
                <Box component={'div'} display={'flex'} justifyContent={'space-between'}>
                  {/* <Box component={'div'}>
                    <Autocomplete
                      size='small'
                      clearIcon
                      sx={{ width: '20vw', mr: '0.5vw' }}
                      options={this.state.prescriptionTemplateList.map((item) => item.template_name)}
                      value={this.state.selectedTemplate}
                      limitTags={3}
                      autoComplete='off'
                      onChange={(event, newValue) => {
                        this.setState({
                          selectedTemplate: newValue
                        })
                      }}
                      renderInput={(params) => <TextField {...params} me label='Select or Add Template' />}
                      PaperComponent={({ children }) => {
                        return (
                          <Paper>
                            {children}
                            <Divider />
                            <Button
                              color="primary"
                              fullWidth
                              sx={{ justifyContent: "flex-start", pl: 2, textTransform: 'capitalize' }}
                              onMouseDown={(e) => {
                                this.setState({ openPopupAddNew: true, tempType: 'Add' })
                              }}
                            >
                              Add Template
                            </Button>
                            <Button
                              color="primary"
                              fullWidth
                              sx={{ justifyContent: "flex-start", pl: 2, textTransform: 'capitalize' }}
                              onMouseDown={(e) => {
                                this.setState({ openPopupAddNew: true, tempType: 'rename' })
                              }}
                            >
                              Rename
                            </Button>
                            <Button
                              color="primary"
                              fullWidth
                              sx={{ justifyContent: "flex-start", pl: 2, textTransform: 'capitalize' }}
                              onMouseDown={(e) => {
                                this.setState({ openPopupAddNew: true, tempType: 'delete' })
                              }}
                            >
                              Delete
                            </Button>
                          </Paper>
                        )
                      }}
                    />
                  </Box> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      open={this.state.open}
                      onOpen={() => this.setState({ open: true })}
                      onClose={() => { this.setState({ open: false }) }}
                      inputFormat={"DD-MM-YYYY"}
                      autoComplete="off"
                      InputProps={{
                        disableUnderline: true
                      }}
                      minDate={DateTime.now()}
                      value={this.state.selectedFollowup}
                      onChange={(newDate) => {
                        this.setState({
                          selectedFollowup: newDate
                        })
                      }}
                      label='Follow Up Date'
                      renderInput={(params) => <TextField {...params} labe='Next Follow-up'
                        sx={{ width: '13vw' }} size='small'
                        onClick={() => { this.setState({ open: true }) }}
                      />}
                    />
                  </LocalizationProvider>
                  <FormControl size='small' sx={{ width: '15vw' }}>
                    <InputLabel>Select Pharmacy</InputLabel>
                    <Select
                      label='select Pharmacy'
                      value={this.state.selectedPharmacy}
                      onChange={(e) => this.setState({ selectedPharmacy: e.target.value })}
                    >
                      {this.state.pharmacyList?.length > 0 && this.state.pharmacyList?.map((item, index) => (
                        <MenuItem value={item?.pharmacy_id} key={index}>{item?.pharmacy_name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                </Box>
                <Box component={'div'}>
                  <Typography fontSize={'0.9vw'} fontWeight={600}>Brand Name</Typography>
                  {this.state.drugList?.length > 0 && this.state.drugList?.map((item, index) => (
                    <Chip
                      className={`eMed_chip ${(item.name ? (item?.id === this.state.selectedDrugId && (item.name) === this.state.selectedDrug) : (item === this.state.selectedDrug)) ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                      label={item.name ? item?.name : item}
                      variant={(item.name ? (item?.id === this.state.selectedDrugId && (item.name) === this.state.selectedDrug) : (item === this.state.selectedDrug)) ? 'contained' : 'outlined'}
                      key={index}
                      onClick={() => {
                        this.setState({
                          selectedDrug: item?.name ? item?.name : item,
                          favourite: JSON.parse(JSON.stringify(item?.is_favorite)),
                          selectedDrugId: item?.id,
                          searchedGenericName: null,
                          dosageStrength: '', dosageType: ''
                        }, () => {
                          this.getGenericNameList(this.state.selectedDrug)
                        })
                      }}
                      // color={'primary'}
                      clickable
                    />
                  ))}
                  <Box component={'div'} mt={'0.5vw'}>
                    <Autocomplete
                      size='small'
                      sx={{ width: '20vw' }}
                      clearIcon
                      freeSolo
                      options={this.state.searchedDrugList?.map((item) => item)}
                      value={this.state.searchedBrandName ? this.state.searchedBrandName : null}
                      onChange={(e, value) => {
                        this.setState({
                          searchedBrandName: value
                        }, () => {
                          if (this.state.searchedBrandName?.length > 0) {
                            let check = this.checkDuplicate()
                            if (check === null) {
                              this.state.drugList?.push(this.state.searchedBrandName)
                              this.setState({
                                drugList: this.state.drugList,
                                selectedDrug: this.state.searchedBrandName
                              }, () => {
                                this.setState({ searchedBrandName: null, searchedDrugList: [] })
                                this.getGenericNameList(this.state.selectedDrug)
                              })
                            } else {
                              this.errorMessage(check)
                              this.setState({ searchedBrandName: null, searchedDrugList: [] })
                            }
                          }
                        })
                      }}
                      renderInput={(params) => <TextField {...params}
                        onChange={(e) => {
                          if (e.target.value?.length > 0) {
                            this.setState({
                              searchedBrandName: e.target.value
                            }, () => this.getPharmacyDrugSearchList(this.state.searchedBrandName))
                          }
                        }}
                        label='Search Drug' />}
                    />
                  </Box>
                </Box>
                <Box component={'div'} mt={'0.5vw'}>
                  <Typography fontSize={'0.9vw'} fontWeight={600}>Generic Name,Dosage and Dosage Type</Typography>
                  <Box component={'div'} mt={'0.5vw'} display={'flex'}>
                    <Autocomplete
                      size='small'
                      sx={{ width: '15vw' }}
                      clearIcon
                      freeSolo
                      options={this.state.genericList}
                      getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.generic_name}
                      value={this.state.searchedGenericName}
                      onChange={(e, value) => {
                        this.setState({
                          searchedGenericName: value
                        }, () => {
                          this.setState({
                            dosageStrength: this.state.searchedGenericName?.dosage_strength,
                            dosageType: this.state.searchedGenericName?.dosage_type
                          })
                        })
                      }}
                      renderInput={(params) => <TextField {...params}
                        label='Select Generic Name' />}
                    />
                    <TextField
                      size='small'
                      sx={{ width: '10vw', ml: '0.5vw' }}
                      value={this.state.dosageStrength}
                      InputProps={{ readOnly: true }}
                      label='Dosage Strength'
                    />
                    <TextField
                      size='small'
                      sx={{ width: '10vw', ml: '0.5vw' }}
                      value={this.state.dosageType}
                      InputProps={{ readOnly: true }}
                      label='Dosage Type'
                    />
                  </Box>
                </Box>
                <Box component={'div'} mt={'0.5vw'} display={'flex'} alignItems={'center'}>
                  <Box component={'div'} flex={0.5} borderRight={'1px solid lightgray'}>
                    <Box component={'div'}>
                      <Typography fontSize={'0.9vw'} fontWeight={600}>MAEN Dosage Quantity</Typography>
                    </Box>
                    <Box component={'div'} display={'flex'} alignItems={'center'} mt={'1.5vw'}>
                      <Typography mr={'1.8vw'} fontSize={'0.9vw'} fontWeight={600}>Morning</Typography>
                      {this.state.tabletQty?.length > 0 && this.state.tabletQty?.map((item, index) => (
                        <Chip
                          className={`eMed_chip ${(item.label) === this.state.morningQty ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                          label={item.label}
                          variant={(item.label) === this.state.morningQty ? 'contained' : 'outlined'}
                          key={index}
                          onClick={() => {
                            this.setState({
                              morningQty: item?.label,
                            })
                          }}
                          // color={'primary'}
                          clickable
                        />
                      ))}
                    </Box>
                    <Box component={'div'} display={'flex'} alignItems={'center'}>
                      <Typography sx={{ mr: '1vw' }} fontSize={'0.9vw'} fontWeight={600}>Afternoon</Typography>
                      {this.state.tabletQty?.length > 0 && this.state.tabletQty?.map((item, index) => (
                        <Chip
                          className={`eMed_chip ${(item.label) === this.state.afternoonQty ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                          label={item.label}
                          variant={(item.label) === this.state.afternoonQty ? 'contained' : 'outlined'}
                          key={index}
                          onClick={() => {
                            this.setState({
                              afternoonQty: item?.label,
                            })
                          }}
                          // color={'primary'}
                          clickable
                        />
                      ))}
                    </Box>
                    <Box component={'div'} display={'flex'} alignItems={'center'}>
                      <Typography mr={'2vw'} fontSize={'0.9vw'} fontWeight={600}>Evening</Typography>
                      {this.state.tabletQty?.length > 0 && this.state.tabletQty?.map((item, index) => (
                        <Chip
                          className={`eMed_chip ${(item.label) === this.state.eveningQty ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                          label={item.label}
                          variant={(item.label) === this.state.eveningQty ? 'contained' : 'outlined'}
                          key={index}
                          onClick={() => {
                            this.setState({
                              eveningQty: item?.label,
                            })
                          }}
                          // color={'primary'}
                          clickable
                        />
                      ))}
                    </Box>
                    <Box component={'div'} display={'flex'} alignItems={'center'}>
                      <Typography mr={'3vw'} fontSize={'0.9vw'} fontWeight={600}>Night</Typography>
                      {this.state.tabletQty?.length > 0 && this.state.tabletQty?.map((item, index) => (
                        <Chip
                          className={`eMed_chip ${(item.label) === this.state.nightQty ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                          label={item.label}
                          variant={(item.label) === this.state.nightQty ? 'contained' : 'outlined'}
                          key={index}
                          onClick={() => {
                            this.setState({
                              nightQty: item?.label,
                            })
                          }}
                          // color={'primary'}
                          clickable
                        />
                      ))}
                    </Box>
                  </Box>
                  <Box component={'div'} flex={0.5} pl={'0.5vw'}>
                    <Box component={'div'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                      <Typography fontSize={'0.9vw'} fontWeight={600}>Duration</Typography>
                      <FormControl size='small'>
                        <InputLabel>Select Duration</InputLabel>
                        <Select
                          sx={{ width: '10vw' }}
                          label='Select Duration'
                          value={this.state.selectedDays}
                          onChange={(e) => this.setState({ selectedDays: e.target.value, selectedDuration: '' })}
                        >
                          <MenuItem value={'1'}>Days</MenuItem>
                          <MenuItem value={'2'}>Weeks</MenuItem>
                          <MenuItem value={'3'}>Months</MenuItem>
                          <MenuItem value={'4'}>Years</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <Box component={'div'} mt={'0.5vw'}>
                      {this.state.selectedDays === '1' ?
                        this.state.durationDays?.map((item, index) => (
                          <Chip
                            className={`eMed_chip ${item.value === this.state.selectedDuration ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                            label={item.label}
                            variant={item.value === this.state.selectedDuration ? 'contained' : 'outlined'}
                            key={index}
                            onClick={() => { this.setState({ selectedDuration: item?.value }) }}
                            // color={'primary'}
                            clickable
                          />
                        )) : this.state.selectedDays === '2' ?
                          this.state.durationWeeks?.map((item, index) => (
                            <Chip
                              className={`eMed_chip ${item.value === this.state.selectedDuration ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                              label={item.label}
                              variant={item.value === this.state.selectedDuration ? 'contained' : 'outlined'}
                              key={index}
                              onClick={() => { this.setState({ selectedDuration: item?.value }) }}
                              // color={'primary'}
                              clickable
                            />
                          )) : this.state.selectedDays === '3' ?
                            this.state.durationMonts?.map((item, index) => (
                              <Chip
                                className={`eMed_chip ${item.value === this.state.selectedDuration ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                label={item.label}
                                variant={item.value === this.state.selectedDuration ? 'contained' : 'outlined'}
                                key={index}
                                onClick={() => { this.setState({ selectedDuration: item?.value }) }}
                                // color={'primary'}
                                clickable
                              />
                            )) : this.state.durationYears?.map((item, index) => (
                              <Chip
                                className={`eMed_chip ${item.value === this.state.selectedDuration ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                label={item.label}
                                variant={item.value === this.state.selectedDuration ? 'contained' : 'outlined'}
                                key={index}
                                onClick={() => { this.setState({ selectedDuration: item?.value }) }}
                                // color={'primary'}
                                clickable
                              />
                            ))
                      }
                    </Box>
                  </Box>
                </Box>
                <Box component={'div'} mt={'0.5vw'} display={'flex'}>
                  <FormControl size='small' sx={{ width: '15vw' }}>
                    <InputLabel>Select Usage</InputLabel>
                    <Select
                      label='Select Usage'
                      value={this.state.selectedUsage}
                      onChange={(e) => this.setState({ selectedUsage: e.target.value })}
                    >
                      {this.state.foodUsage?.length > 0 && this.state.foodUsage?.map((item, index) => (
                        <MenuItem value={item?.value} key={index}>{item?.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Box component={'div'}>
                    <TextField
                      label='Comments'
                      size='small'
                      sx={{ width: '40vw', ml: '0.5vw' }}
                      value={this.state.comments}
                      onChange={(e) => {
                        let value = e.target.value
                        if (value?.length < 250) {
                          this.setState({ comments: e.target.value })
                        } else {
                          this.errorMessage('Allowed only 250 characters')
                        }
                      }}
                    />
                  </Box>
                </Box>

                <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
                  <Button
                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                    variant='outlined'
                    onClick={() => this.onClearHandler()}
                  >Clear</Button>
                  <Button
                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                    variant='contained'
                    onClick={() => this.addPrescriptionData()}
                  >Add</Button>
                  <Button
                    disabled={((!this.state.isEdit && this.state.prescriptionList?.length > 0)) ? false : true}
                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                    variant='contained'
                    onClick={() => this.postPrescriptionData()}
                  >Save</Button>
                </Box>
                <Box component={'div'} mt={'1vw'} border={'1px solid lightgray'} maxHeight={'15vw'} overflow={'auto'}>
                  <Table stickyHeader size='small'>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Favourite</TableCell>
                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Prescription</TableCell>
                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, color: Colors.SecondaryText, backgroundColor: Colors.Background }}>M-A-E-N</TableCell>
                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Description</TableCell>
                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.prescriptionList?.length > 0 ? this.state.prescriptionList?.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Button className='Common_Btn_Min_Width'
                              onClick={() => {
                                this.state.prescriptionList[index]['is_favorite'] = !JSON.parse(JSON.stringify(item?.is_favorite))
                                this.setState({
                                  prescriptionList: this.state.prescriptionList
                                })
                              }}
                            >
                              <Box component={'img'} src={item?.is_favorite ? ImagePaths.StarFill.default : ImagePaths.StarUnfill.default} height={'1.5vw'} width={'1.5vw'} />
                            </Button>
                          </TableCell>
                          <TableCell>{item?.brand_name ?
                            <Box component={'div'}>
                              <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.brand_name?.length > 20 ?
                                <Tooltip placement='top' title={item?.brand_name}><div>{item?.brand_name?.slice(0, 20) + '... '}</div></Tooltip> :
                                item?.brand_name
                              }</Typography>
                              <Typography fontSize={'0.8vw'} color={Colors.SecondaryText}>{`${item?.generic_name ?
                                item?.generic_name?.length > 15 ? <Tooltip placement='top' title={item?.generic_name}><div>{item?.generic_name?.slice(0, 15) + '...'}</div></Tooltip> :
                                  item?.generic_name : '-'
                                } | ${item?.dosage_strength ? item?.dosage_strength : '-'} ${item?.dosage_type ? item?.dosage_type : ''}`}</Typography>
                            </Box> : '-'
                          }</TableCell>
                          <TableCell>{`${item?.morning ? item?.morning : '0'} - ${item?.afternoon ? item?.afternoon : '0'} - ${item?.evening ? item?.evening : '0'} - ${item?.night ? item?.night : '0'}`}</TableCell>
                          <TableCell>{item?.comments ?
                            item?.comments?.length > 15 ?
                              <Tooltip placement='top' title={item?.comments}><div>{item?.comments?.slice(0, 15) + '...'}</div></Tooltip> :
                              item?.comments : '-'
                          }</TableCell>
                          <TableCell>
                            <Button className='Common_Btn_Min_Width'
                              onClick={() => {
                                this.setState({
                                  selectedIndex: index
                                }, () => this.onEditHandler(item))
                              }}
                            >
                              <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.5vw'} width={'1.5vw'} />
                            </Button>
                            <Button className='Common_Btn_Min_Width'
                              onClick={() => {
                                if (this.state.isEdit) {
                                  this.errorMessage('You clicked edit button.So you cannot able to delete')
                                } else {
                                  this.state.prescriptionList?.splice(index, 1)
                                  this.setState({
                                    prescriptionList: this.state.prescriptionList
                                  })
                                }
                              }}
                            >
                              <Box component={'img'} src={ImagePaths.Delete.default} height={'1.5vw'} width={'1.5vw'} />
                            </Button>
                          </TableCell>
                        </TableRow>
                      )) :
                        <TableRow>
                          <TableCell />
                          <TableCell />
                          <TableCell>No Records To Be Shown</TableCell>
                          <TableCell />
                          <TableCell />
                        </TableRow>
                      }
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            </Box>
          </Modal>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  instructions = (item) => {
    let value = '';
    switch (item) {
      case 'BF':
        value = 'Before Food'
        break;
      case 'AF':
        value = 'After Food'
        break;
      case 'EA':
        value = 'External Application'
        break;
      case 'ES':
        value = 'Empty Stomach'
        break;
      case 'WF':
        value = 'With Food'
        break;
      case 'IA':
        value = 'Internal Application'
        break;
      default: break;
    }
    return value;
  }

  onEditMedic = (item) => {
    if (!item?.tapper_meds.length > 0) {
      this.setState({
        disableTapper: true,
        value: "One",
        selectedDrug: item?.brand_name,
        selectedDrugId: item?.drug_id,
        medicId: item?.id,
        eyeType: item?.eye_type,
        selectedDuration: item?.duration.split(" ")[1] === "Until" ? "Until_the_Next_Visit" : item?.duration.split(" ")[1] === "Long" ? "Long_Term" : item?.duration.split(" ")[1],
        MedUsage: item?.instructions,
        MedDuration: item?.duration.split(" ")[1] === "Until" ? "" : item?.duration.split(" ")[1] === "Long" ? "" : +item?.duration.split(" ")[0],
        MedQty: item?.quantity,
        medCommends: item?.comments,
        isEdit: true,
        Morning: +item?.morning,
        Afternoon: +item?.afternoon,
        Evening: +item?.evening,
        Night: +item?.night,
        no_of_Dosage: item?.no_of_dosage,
        Medfrequency: item?.frequency,
        Medic_Gen_Details: item?.drug_id ? item : this.state.Medic_Gen_Details,
        searchedBrandName: { drug_name: item?.brand_name, generic_name: item?.generic_name },
        GenericNameSearch: { drug_name: item?.brand_name, generic_name: item?.generic_name },
        optTemplateId: item?.template_line_id ? item?.template_line_id : null
      }, () => {
        if (item?.drug_id) {
          this.setState({
            ExternalDrugAdd: false,
            External_Drug_Details: {
              DrugName: "",
              GenericName: "",
              DosageType: "",
              DosageStrength: "",
            }
          }, () => {
            this.getTapperDrugDetails(true)
          })
        } else {
          this.setState({
            ExternalDrugAdd: true,
            External_Drug_Details: {
              DrugName: item?.doctor_drug_name ? item?.doctor_drug_name : item?.brand_name,
              GenericName: item?.doctor_generic_name ? item?.doctor_generic_name : item?.generic_name,
              dosage_type: item?.doctor_dosagetype ? item?.doctor_dosagetype : item?.dosage_type,
              DosageStrength: item?.doctor_drug_strength ? item?.doctor_drug_strength : item?.dosage_strength,
              no_of_dosage: item?.no_of_dosage,
            },
            ExternalBrandName: item?.doctor_drug_name ? item?.doctor_drug_name : item?.brand_name
          })
        }
      })
    } else {
      console.log(item)

      this.setState({
        disableMedic: true,
        value: "Two",
        // selectedDrug: item?.brand_name,
        // TapperId: item?.id,
        // taperAddedDetails: item?.tapper_meds,
        // tapperQty: item?.quantity,
        // tapperComm: item?.comments,
        // tapperID: item?.id,
        isEdit: true,
        // eyeType: item?.eye_type,
        newTapMedListData: JSON.parse(JSON.stringify([item]))
      })
      // }, () => { this.getTapperDrugDetails() })
    }
  }

  onDeleteHandler = () => {
    try {
      RestAPIService.deleteAll(Serviceurls.DOC_OPT_TAPPER_DETAILS_GET_POST + `?prescription_id=${this.state.selectedPrescripID}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response?.data?.message)
            this.setState({
              deletePopup: false, selectedPrescripID: null
            }, () => { this.getTapperMed() })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderMedicationTable = () => {
    try {
      return (
        <div>
          {this.state.TapperMedList.length > 0 ? this.state.TapperMedList?.map((data, index) => (
            <div>
              {index == 0 ? null :
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Box component={'div'} display={'flex'} alignItems={'center'}>
                      <Button className='Common_Btn_Min_Width' disabled={this.state.isLocked || this.props.isLocked}
                        onClick={() => {
                          if (data?.is_billed_in_pharmacy == false && !data?.is_transmitted) {
                            this.setState({ selectedPrescripID: data?.prescription_id, deletePopup: true })
                          }
                        }}
                      >
                        {data?.is_billed_in_pharmacy == true || data?.is_transmitted || this.state.isLocked || this.props.isLocked ?
                          <Box height={'1.2vw'} width={'1.2vw'} component={'img'} src={ImagePaths.DisabledDelete.default} /> :
                          <Box height={'1vw'} width={'1vw'} component={'img'} src={ImagePaths.DeleteOutline.default} />}
                      </Button>
                      <Typography fontSize={'0.8vw'} fontWeight={600}>New Medication</Typography>
                    </Box>
                    {data?.is_billed_in_pharmacy == true ? null :
                      data?.is_billed_in_pharmacy == false && data?.is_transmitted && !this.props.isLocked ? <Button
                        className='Common_Btn_Min_Width'
                        onClick={() => {
                          this.setState({ recallPopup: true, RecallPresId: data?.prescription_id })
                        }}
                      >
                        <Tooltip placement='top' title='Recall'>
                          <div style={{ backgroundColor: "#b8170d", borderRadius: "0.2vw", width: "1.4vw", height: "1.5vw" }}>
                            <Box component={'img'} src={ImagePaths.Recall.default} height={'1.1vw'} width={'1.1vw'} />
                          </div>
                        </Tooltip>
                      </Button> :
                        <Button
                          className='Common_Btn_Min_Width'
                          disabled={this.state.isLocked || this.props.isLocked || data[index]?.is_transmitted || this.state.DisableBtn}
                          onClick={() => this.getPharmacyList(false, true)}
                        >
                          <Tooltip placement='top' title='Transmit'>
                            <Box component={'img'} src={(this.state.isLocked || this.props.isLocked || data[index]?.is_transmitted) ? ImagePaths.medicationTransmitOff.default : ImagePaths.medicationTransmitOn.default} height={'1.1vw'} width={'1.1vw'} />
                          </Tooltip>
                        </Button>}
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Button
                      className='Common_Btn_Min_Width'
                      onClick={() => {
                        if (this.state?.isOPT && !this.state.DisableBtn) {
                          this.getMedicationPrint(data?.prescription_id)
                        }
                      }} disabled={data?.length > 0 || data?.prescription_lines?.length > 0 ? false : true}
                    >
                      <Tooltip placement='top' title='Print'>
                        <Box component={'img'} src={ImagePaths.PrintIcons.default} height={'2vw'} width={'2vw'} />
                      </Tooltip>
                    </Button>
                    <Button
                      variant='contained'
                      sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: "1vw" }}
                      onClick={() => { this.setState({ AddClicked: true }, () => this.getPharmacyList(false, false, data)) }}
                      disabled={this.state.isLocked || this.props.isLocked || data?.is_transmitted || this.state.DisableBtn}
                    >Add</Button>
                  </div>
                </div>}
              <Box component={'div'} border={'1px solid lightgray'} maxHeight={'15vw'} overflow={'auto'}>
                <Table stickyHeader size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell width={'5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background, paddingLeft: '3vw' }}>Eye</TableCell>
                      <TableCell width={'25%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Drug Name</TableCell>
                      <TableCell width={'24%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Frequency</TableCell>
                      <TableCell width={'20%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Duration/Unit</TableCell>
                      <TableCell width={'13%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Quantity</TableCell>
                      <TableCell width={'13%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.prescription_lines?.length > 0 ? data?.prescription_lines?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Box component={'div'} style={{ display: "flex", flexDirection: "row" }}>
                            <Box component={'img'} src={item?.is_favorite ? ImagePaths.StarFill.default : ImagePaths.StarUnfill.default} height={'1.5vw'} width={'1.5vw'} />
                            <div style={{ paddingLeft: "0.5vw" }}>{item?.eye_type ? item?.eye_type : ""}</div>
                          </Box>
                        </TableCell>
                        <TableCell>
                          {item?.brand_name ? (
                            <Box component={'div'}>
                              <Typography fontSize={'0.9vw'} fontWeight={600}>
                                <Tooltip placement='top' title={item?.brand_name}>
                                  <div>
                                    {item?.brand_name.length > 15
                                      ? `${item?.brand_name.slice(0, 15)}... `
                                      : item?.brand_name}
                                  </div>
                                </Tooltip>
                              </Typography>
                              {item?.generic_name && (
                                <Typography fontSize={'0.8vw'} color={Colors.SecondaryText}>
                                  {`${typeof item.generic_name === 'object'
                                    ? JSON.stringify(item.generic_name)
                                    : item?.generic_name?.length > 15
                                      ? `${item?.generic_name.slice(0, 15)}...`
                                      : item?.generic_name} | ${item?.dosage_type ? item?.dosage_type : ''}`}
                                </Typography>
                              )}
                            </Box>
                          ) : '-'}
                        </TableCell>
                        <TableCell>{
                          item?.tapper_meds.length > 0 ? (
                            item?.tapper_meds?.map((list, listIndex) => (
                              <Box key={listIndex} component={'div'}>
                                <Typography fontSize={'0.9vw'} fontWeight={600}>{list?.no_of_dosage ? list?.no_of_dosage : "-"}</Typography>
                                <Typography fontSize={'0.8vw'} color={Colors.SecondaryText}>{`${list?.frequency ? list?.frequency : "-"}`}</Typography>
                              </Box>
                            ))) : <div style={{ fontWeight: "600" }}>{item?.frequency ? item?.frequency : "-"}</div>
                        }</TableCell>
                        <TableCell>
                          {
                            item?.tapper_meds.length > 0 ? (
                              item?.tapper_meds?.map((list, listIndex) => (
                                <Box key={listIndex} component={'div'}>
                                  <Typography fontSize={'0.9vw'} fontWeight={600}>{list?.duration}</Typography>
                                  <Typography fontSize={'0.8vw'} color={Colors.SecondaryText}>{`${list?.start_date ? (list?.start_date).split("-").reverse().join("-") : "-"} | ${list?.end_date ? (list?.end_date).split("-").reverse().join("-") : '-'}`}</Typography>
                                </Box>
                              ))) : <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.duration ? (item?.duration.split(" ")[1] === "Until_the_Next_Visit") ? "Until the Next Visit" : item?.duration : "-"}</Typography>
                          }
                        </TableCell>
                        <TableCell>
                          <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.quantity ? item?.quantity : "-"}</Typography>
                        </TableCell>
                        <TableCell>
                          <Box component={'div'} style={{ marginLeft: "-0.65vw" }} display={'flex'} justifyContent={'flex-start'} flexDirection={'row'} alignItems={'center'}>
                            <Button
                              className='Common_Btn_Min_Width'
                              onClick={() => {
                                this.setState({ openMedicPopUp: true, editItem: item, AllowEdit: true }, () => {
                                  this.getPharmacyList()
                                })
                              }}
                              disabled={this.state.isLocked || this.props.isLocked || data?.is_transmitted}
                            >
                              {this.state.isLocked || this.props.isLocked || data?.is_transmitted ?
                                <Box component={'img'} src={ImagePaths.DisabledEdit.default} height={'1.2vw'} width={'1.2vw'} />
                                :
                                <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                              }
                            </Button>
                            <Button
                              className='Common_Btn_Min_Width'
                              onClick={() => this.onDeleteMedic(item)}
                              disabled={this.state.isLocked || this.props.isLocked || data?.is_transmitted}
                            >
                              {this.state.isLocked || this.props.isLocked || data?.is_transmitted ?
                                <Box component={'img'} src={ImagePaths.DisabledDelete.default} height={'1.2vw'} width={'1.2vw'} />
                                :
                                <Box component={'img'} src={ImagePaths.Delete.default} height={'1.2vw'} width={'1.2vw'} />
                              }
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )) :
                      <TableRow>
                        <TableCell />
                        <TableCell align='right' sx={{ position: 'relative', left: '6.5vw' }}>No Records To Be Shown</TableCell>
                        <TableCell />
                        <TableCell />
                        <TableCell />
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              </Box>
            </div>)) :
            <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>{"No Records To Be Shown"}</div>}
        </div>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderPrescriptionTable = () => {
    try {
      let prescriptionData = this.state.prescriptionData?.prescription_lines ? this.state.prescriptionData?.prescription_lines : this.state.prescriptionData
      return (
        <Box component={'div'} border={'1px solid lightgray'} maxHeight={'15vw'} overflow={'auto'}>
          <Table stickyHeader size='small'>
            <TableHead>
              <TableRow>
                <TableCell width={'10%'} sx={{ fontWeight: 600, fontSize: '0.8vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Favourite</TableCell>
                <TableCell width={'30%'} sx={{ fontWeight: 600, fontSize: '0.8vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Prescription</TableCell>
                <TableCell width={'20%'} sx={{ fontWeight: 600, fontSize: '0.8vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>M-A-E-N</TableCell>
                <TableCell width={'20%'} sx={{ fontWeight: 600, fontSize: '0.8vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Duration</TableCell>
                <TableCell width={'20%'} sx={{ fontWeight: 600, fontSize: '0.8vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Comments</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {prescriptionData?.length > 0 ? prescriptionData?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Box component={'div'}>
                      <Box component={'img'} src={item?.is_favorite ? ImagePaths.StarFill.default : ImagePaths.StarUnfill.default} height={'1.5vw'} width={'1.5vw'} />
                    </Box>
                  </TableCell>
                  <TableCell>{item?.brand_name ?
                    <Box component={'div'}>
                      <Typography fontSize={'0.8vw'} fontWeight={600}>{item?.brand_name?.length > 15 ?
                        <Tooltip placement='top' title={item?.brand_name}><div>{item?.brand_name?.slice(0, 15) + '... '}</div></Tooltip> :
                        item?.brand_name
                      }</Typography>
                      <Typography fontSize={'0.8vw'} color={Colors.SecondaryText}>{`${item?.generic_name ?
                        item?.generic_name?.length > 15 ? <Tooltip placement='top' title={item?.generic_name}><div>{item?.generic_name?.slice(0, 15) + '...'}</div></Tooltip> :
                          item?.generic_name : '-'
                        } | ${item?.dosage_strength ? item?.dosage_strength : '-'} ${item?.dosage_type ? item?.dosage_type : ''}`}</Typography>
                    </Box> : '-'
                  }</TableCell>
                  <TableCell>
                    <Box component={'div'}>
                      <Typography fontSize={'0.8vw'} fontWeight={600}>{`${item?.morning ? item?.morning : '0'} - ${item?.afternoon ? item?.afternoon : '0'} - ${item?.evening ? item?.evening : '0'} - ${item?.night ? item?.night : '0'}`}</Typography>
                      <Typography fontSize={'0.8vw'} color={Colors.SecondaryText}>{this.instructions(item?.instructions)}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ fontSize: '0.8vw', fontWeight: 600 }}>{`${item?.duration} ${item?.duration_mode === '1' ? 'Days' : item?.duration_mode === '2' ? 'Weeks' : item?.duration_mode === '3' ? 'Months' : 'Years'}`}</TableCell>
                  <TableCell sx={{ fontSize: '0.8vw', fontWeight: 600 }}>{item?.comments ? item?.comments?.length > 10 ?
                    <Tooltip placement='top' title={item?.comments}><div>{item?.comments?.slice(0, 10) + '...'}</div></Tooltip> :
                    item?.comments : '-'}</TableCell>
                </TableRow>
              )) :
                <TableRow>
                  <TableCell />
                  <TableCell align='right' sx={{ position: 'relative', left: '6.5vw', fontSize: '0.7vw' }}>No Records To Be Shown</TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              }
            </TableBody>
          </Table>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderFavPopup = () => {
    try {
      return (
        <Box component={'div'}>
          <Modal open={this.state.openFav}>
            <Box component={'div'} className='eMed_Doc_Notes_Prescrip_Fav_Pop_Up'>
              <Box component={'div'} display={'flex'} justifyContent={'space-between'} mt={'0.5vw'}>
                <Typography pl={'0.5vw'} fontWeight={600} color={Colors.SecondaryText}>Select From Favourite</Typography>
                <Button
                  onClick={() => this.setState({ openFav: false, selectedPrescripFav: [] })}
                >
                  <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1.1vw'} width={'1.1vw'} />
                </Button>
              </Box>
              <Box component={'div'} display={'flex'} justifyContent={'center'} mt={'1vw'}>
                <Autocomplete
                  clearIcon
                  size='small'
                  multiple
                  sx={{ width: '20vw' }}
                  options={this.state.prescriptionFav}
                  getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.brand_name}
                  value={this.state.selectedPrescripFav}
                  limitTags={3}
                  autoComplete='off'
                  onChange={(event, newValue) => {
                    this.setState({ selectedPrescripFav: newValue })
                  }}
                  renderInput={(params) => <TextField {...params} label='Select Prescription' />}
                />
              </Box>
              <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
                <Button
                  sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                  variant='outlined'
                  onClick={() => this.setState({ selectedPrescripFav: [] })}
                >Clear</Button>
                <Button
                  disabled={this.state.DisableBtn || this.state.selectedPrescripFav?.length < 0 || this.state.transmitted ? true : false}
                  sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                  variant='contained'
                  onClick={() => {
                    if (this.state?.isOPT) {
                      this.TapperTableDetails(this.state.selectedPrescripFav)
                    } else {
                      this.postPrescriptionData(true)
                    }
                  }}
                >Save</Button>
              </Box>
            </Box>
          </Modal>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderPrescripTransmitPopup = () => {
    try {
      return (
        <Box component={'div'}>
          <Modal open={this.state.openTransmit}>
            <Box component={'div'} className='eMed_Doc_Notes_Prescrip_Fav_Pop_Up'>
              <Box component={'div'} display={'flex'} justifyContent={'space-between'} mt={'0.5vw'}>
                <Typography pl={'0.5vw'} fontWeight={600} color={Colors.SecondaryText}>Transmit Prescription</Typography>
                <Button
                  onClick={() => this.setState({ openTransmit: false })}
                >
                  <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1.1vw'} width={'1.1vw'} />
                </Button>
              </Box>
              <Box component={'div'} mt={'1vw'} display={'flex'}>
                <FormControl size='small' sx={{ width: '20vw', ml: '4vw' }}>
                  <InputLabel>Select Pharmacy</InputLabel>
                  <Select
                    label={'Select Pharmacy'}
                    value={this.state.selectedTransmitPharmacy}
                    disabled={this.state.TapperMedList?.length > 0 &&
                      (this.state.TapperMedList[0]?.prescription_lines[0]?.pharmacy_id === this.state.selectedTransmitPharmacy)}
                    onChange={(e) => this.setState({ selectedTransmitPharmacy: e.target.value })}
                  >
                    {this.state.pharmacyList?.length > 0 && this.state.pharmacyList?.map((item, index) => (
                      <MenuItem value={item?.pharmacy_id} key={index}>{item?.pharmacy_name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
                {/* <Button
                  sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                  variant='outlined'
                  onClick={() => this.setState({ selectedTransmitPharmacy: '' })}
                >Clear</Button> */}
                <Button
                  disabled={this.state.selectedTransmitPharmacy ? false : true}
                  sx={{ height: '2vw', textTransform: 'capitalize' }}
                  variant='contained'
                  onClick={() => this.postPrescriptionTransmit()}
                >Transmit</Button>
              </Box>
            </Box>
          </Modal>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  copyPreviousPrescription = () => {
    try {
      let prevPrescriptionData = []
      this.state.prevPrescripData?.forEach(element => {
        prevPrescriptionData?.push({
          afternoon: element?.afternoon ? element?.afternoon : '0',
          brand_name: element?.drug_name ? element?.drug_name : '',
          comments: element?.comments ? element?.comments : '',
          dosage_strength: element.drug_strength ? element.drug_strength : '',
          dosage_type: element.drug_type ? element.drug_type : '',
          drug_id: element.drug_id ? element.drug_id : '',
          duration: element.duration ? element.duration : '',
          duration_mode: element.duration_mode ? element.duration_mode : '1',
          evening: element.evening ? element.evening : '0',
          generic_name: element.generic_name ? element.generic_name : '',
          instructions: element.instructions ? element.instructions : '',
          is_favorite: element.is_favorite,
          morning: element.morning ? element.morning : '0',
          night: element.night ? element.night : '0',
          pharmacy_id: element.pharmacy_id,
        })
      })
      this.setState({
        prescriptionList: prevPrescriptionData
      }, () => this.getPharmacyList(true))
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderPreviousPrescriptionPopup = () => {
    try {
      this.state.prevPrescripData?.forEach((element, index) => element.sno = index + 1)
      const columns = [
        {
          field: 'drug_name', headerName: 'Brand Name', flex: 0.35,
          renderCell: (params) => (
            <Box component={'div'}>
              <Typography fontSize={'0.9vw'} fontWeight={600}>{params?.row?.drug_name}</Typography>
              <Typography fontSize={'0.8vw'}>{`${params?.row?.generic_name} | ${params?.row?.drug_strength} ${params?.row?.drug_type}`}</Typography>
            </Box>
          )
        },
        {
          field: 'morning', headerName: 'M-A-E-N', flex: 0.2,
          renderCell: (params) => (
            <Box component={'div'}>
              <Typography fontSize={'0.9vw'} fontWeight={600}>{`${params?.row?.morning} - ${params?.row?.afternoon} - ${params?.row?.evening} - ${params?.row?.night}`}</Typography>
              <Typography fontSize={'0.8vw'}>{`${params?.row?.duration} ${params?.row?.duration_mode === '1' ? 'Days' : params?.row?.duration_mode === '1' ? 'Weeks' : params?.row?.duration_mode === '1' ? 'Months' : 'Years'}`}</Typography>
            </Box>
          )
        },
        {
          field: 'instructions', headerName: 'Usage', flex: 0.2,
          renderCell: (params) => (
            <Box component={'div'}>
              <Typography fontSize={'0.9vw'}>{`${this.instructions(params?.row?.instructions)}`}</Typography>
            </Box>
          )
        },
        {
          field: 'comments', headerName: 'Description', flex: 0.25,
          renderCell: (params) => (
            <Box component={'div'}>
              {params?.row?.comments ? params?.row?.comments?.length > 10 ?
                <Tooltip placement='top' title={params?.row?.comments}>
                  <Typography fontSize={'0.9vw'}>{params?.row?.comments?.slice(0, 10) + '...'}</Typography>
                </Tooltip>
                :
                <Typography fontSize={'0.9vw'}>{params?.row?.comments}</Typography> : '-'
              }
            </Box>
          )
        },
      ]
      return (
        <Box component={'div'}>
          <Modal open={this.state.openPrevious}>
            <Box component={'div'} className='eMed_Doc_Notes_Counsellor_Pop_Up'>
              <Box component={'div'} display={'flex'} justifyContent={'space-between'} mt={'0.5vw'}>
                <Typography pl={'0.5vw'} fontWeight={600} color={Colors.SecondaryText}>Previous Prescription</Typography>
                <Button
                  onClick={() => {
                    this.setState({ openPrevious: false })
                  }}
                >
                  <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1.1vw'} width={'1.1vw'} />
                </Button>
              </Box>
              <Box component={'div'} display={'flex'} m={'0.5vw'} alignItems={'center'}>
                <Autocomplete
                  size='small'
                  clearIcon
                  sx={{ width: '15vw', mr: '1vw' }}
                  options={this.state.previousPrescripDate}
                  getOptionLabel={(option) => typeof (option) === 'string' ? formatDate(option) : formatDate(option?.prescription_date)}
                  value={this.state.selectedPrevDate}
                  onChange={(e, value) => {
                    this.setState({
                      selectedPrevDate: value
                    }, () => this.getPreviousPrescriptionDatas())
                  }}
                  renderInput={(params) => <TextField {...params} label={'Select Date'} />}
                />
                <Button variant='contained'
                  sx={{ textTransform: 'capitalize', mr: '1vw' }}
                  onClick={() => this.getPreviousPrescriptionPrint()}
                  disabled={this.state.previousPrescripDate?.length > 0 ? false : true}
                >Print</Button>
                <Button variant='contained'
                  sx={{ textTransform: 'capitalize' }}
                  onClick={() => this.copyPreviousPrescription()}
                  disabled={this.state.isLocked || this.props.isLocked || (this.state.previousPrescripDate?.length > 0 ? false : true)}
                >Copy</Button>
              </Box>
              <Box component={'div'} sx={{ height: '21vw', m: "0.5vw" }}>
                <DataGrid
                  rows={this.state.prevPrescripData}
                  columns={columns}
                  getRowId={(row) => row['sno']}
                  page={this.state.page}
                  pageSize={this.state.pageSize}
                  localeText={{
                    toolbarColumns: "",
                    toolbarDensity: "",
                    toolbarExport: "",
                    toolbarFilters: "",
                    toolbarExportPrint: ""
                  }}
                  components={{
                    // Toolbar: CommonGridHeader.CommonToolBar,
                    NoRowsOverlay: () => (
                      <Box className='' sx={{ position: 'relative', top: '5vw', left: '20vw' }}>
                        {'No Records To Be Shown'}
                      </Box>
                    )
                  }}
                  onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                  onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                  rowsPerPageOptions={[10, 20, 30]}
                  pagination
                  headerHeight={40}
                  disableSelectionOnClick
                  loading={this.state.loading}
                />
              </Box>
            </Box>
          </Modal>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  modifyHistory = (Array) => {
    const keysToRemove = ["prescription_id", "appointment_id", "id", "pharmacy_id", "prescription_date"];
    var newArrayWithoutKey = Array.map(obj => {
      const newObj = Object.keys(obj).reduce((accumulator, key) => {
        if (!keysToRemove.includes(key)) {
          accumulator[key] = obj[key];
        }
        return accumulator;
      }, {});

      newObj["frequency"] = obj.frequency_desc;

      return newObj;
    })
    return newArrayWithoutKey
  }

  renderPreviousMedicationPopup = () => {
    try {
      return (
        <Box component={'div'}>
          <Modal open={this.state.openPrevsMedic}>
            <Box component={'div'} className='eMed_Doc_Notes_Counsellor_Pop_Up'>
              <Box component={'div'} display={'flex'} alignItems={'center'} justifyContent={'space-between'} mt={'0.5vw'}>
                <Typography pl={'0.5vw'} fontWeight={600} color={Colors.SecondaryText}>Previous Medication</Typography>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Button variant='contained'
                    sx={{ textTransform: 'capitalize' }}
                    onClick={() => this.TapperTableDetails(this.state.previousMedicData, true)}
                    disabled={this.state.DisableBtn || this.state.isLocked || this.props.isLocked || (this.state.previousMedicData?.length > 0 ? false : true) || (!this.state.Recalled && this.state.transmitted)}
                  >Add</Button>
                  <Button
                    onClick={() => {
                      this.setState({ openPrevsMedic: false })
                    }}
                  >
                    <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1.1vw'} width={'1.1vw'} />
                  </Button>
                </div>
              </Box>
              <Box component={'div'} sx={{ height: '21vw', m: "0.5vw" }}>
                <Box component={'div'} border={'1px solid lightgray'} maxHeight={'15vw'} overflow={'auto'}>
                  <Table stickyHeader size='small'>
                    <TableHead>
                      <TableRow>
                        <TableCell width={'30%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Drug Name</TableCell>
                        <TableCell width={'25%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Frequency</TableCell>
                        <TableCell width={'25%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Duration/Unit</TableCell>
                        <TableCell width={'20%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Quantity</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.previousMedicData?.length > 0 ? this.state.previousMedicData?.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {item?.brand_name ? (
                              <Box component={'div'}>
                                <Typography fontSize={'0.9vw'} fontWeight={600}>
                                  <Tooltip placement='top' title={item?.brand_name}>
                                    <div>
                                      {item?.brand_name.length > 15
                                        ? `${item?.brand_name.slice(0, 15)}... `
                                        : item?.brand_name}
                                    </div>
                                  </Tooltip>
                                </Typography>
                                {item?.generic_name && (
                                  <Typography fontSize={'0.8vw'} color={Colors.SecondaryText}>
                                    {`${typeof item.generic_name === 'object'
                                      ? JSON.stringify(item.generic_name)
                                      : item?.generic_name?.length > 15
                                        ? `${item?.generic_name.slice(0, 15)}...`
                                        : item?.generic_name} | ${item?.dosage_type ? item?.dosage_type : ''}`}
                                  </Typography>
                                )}
                              </Box>
                            ) : '-'}
                          </TableCell>
                          <TableCell>{
                            item?.tapper_meds.length > 0 ? (
                              item?.tapper_meds?.map((list, listIndex) => (
                                <Box key={listIndex} component={'div'}>
                                  <Typography fontSize={'0.9vw'} fontWeight={600}>{list?.no_of_dosage ? list?.no_of_dosage : "-"}</Typography>
                                  <Typography fontSize={'0.8vw'} color={Colors.SecondaryText}>{`${list?.frequency ? list?.frequency : "-"}`}</Typography>
                                </Box>
                              ))) : <div style={{ fontWeight: "600" }}>{item?.frequency_desc ? item?.frequency_desc : "-"}</div>
                          }</TableCell>
                          <TableCell>
                            {
                              item?.tapper_meds.length > 0 ? (
                                item?.tapper_meds?.map((list, listIndex) => (
                                  <Box key={listIndex} component={'div'}>
                                    <Typography fontSize={'0.9vw'} fontWeight={600}>{list?.duration}</Typography>
                                    <Typography fontSize={'0.8vw'} color={Colors.SecondaryText}>{`${list?.start_date ? formatDate(list?.start_date) : "-"} | ${list?.end_date ? formatDate(list?.end_date) : '-'}`}</Typography>
                                  </Box>
                                ))) : <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.duration ? item?.duration : "-"}</Typography>
                            }
                          </TableCell>
                          <TableCell>
                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.quantity ? item?.quantity : "-"}</Typography>
                          </TableCell>
                        </TableRow>
                      )) :
                        <TableRow>
                          <TableCell />
                          <TableCell align='right' sx={{ position: 'relative' }}>No Records To Be Shown</TableCell>
                          <TableCell />
                          <TableCell />
                        </TableRow>
                      }
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            </Box>
          </Modal>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  render() {
    let prescriptionData = this.state.isOPT ? (this.state.TapperMedList?.prescription_id ? this.state.TapperMedList?.prescription_id : this.state.selectedPharmacy) : (this.state.prescriptionData?.prescription_id ? this.state.prescriptionData?.prescription_id : this.state.selectedPharmacy)
    let prescription = this.state.prescriptionData?.prescription_lines ? this.state.prescriptionData?.prescription_lines : this.state.prescriptionData
    return (
      <ShowComponents onIntersection={this.apiCalls.bind(this)}>
        <Box component={'div'} sx={{ backgroundColor: Colors.white }}>
          <Box component={'div'} height={'3.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} sx={{ backgroundColor: Colors.Background }}>
            <Box component={'div'} display={'flex'} alignItems={'center'}>
              {this.state.isOPT ?
                <Typography pl={'0.5vw'} fontWeight={600}>Medication</Typography> :
                <Typography pl={'0.5vw'} fontWeight={600} fontSize={'0.8vw'}>Prescription</Typography>
              }
              <Button
                className='Common_Btn_Min_Width'
                disabled={this.state.isLocked || this.props.isLocked || this.state.TapperMedList[0]?.is_transmitted || this.state.DisableBtn}
                onClick={() => this.getPharmacyList(false, true)}
              >
                <Tooltip placement='top' title='Transmit'>
                  <Box component={'img'} src={(this.state.isLocked || this.props.isLocked || this.state.TapperMedList[0]?.is_transmitted) ? ImagePaths.medicationTransmitOff.default : ImagePaths.medicationTransmitOn.default} height={'1.1vw'} width={'1.1vw'} />
                </Tooltip>
              </Button>
              <Button
                className='Common_Btn_Min_Width'
                onClick={() => {
                  this.setState({ openPrevious: this.state.isOPT ? false : true, openPrevsMedic: this.state.isOPT ? true : false }, () => {
                    this.getPreviousMedicDatas()
                  })
                }}
              >
                <Tooltip placement='top' title={'Previous Medication'}>
                  <Box component={'img'} src={ImagePaths.PreviousPrescription.default} height={'1.1vw'} width={'1.1vw'} />
                </Tooltip>
              </Button>
              {this.state.TapperMedList[0]?.is_billed_in_pharmacy == false && this.state.TapperMedList[0]?.is_transmitted && !this.props.isLocked ? <Button
                className='Common_Btn_Min_Width'
                onClick={() => { this.setState({ recallPopup: true, RecallPresId: this.state.TapperMedList[0]?.prescription_id }) }}
              >
                <Tooltip placement='top' title='Recall'>
                  <div style={{ backgroundColor: "#b8170d", borderRadius: "0.2vw", width: "1.4vw", height: "1.5vw" }}>
                    <Box component={'img'} src={ImagePaths.Recall.default} height={'1.1vw'} width={'1.1vw'} />
                  </div>
                </Tooltip>
              </Button> : null}
            </Box>
            <Box component={'div'} display={'flex'} mr={'0.5vw'}>
              <Button
                className='Common_Btn_Min_Width'
                disabled={this.state.isLocked || this.props.isLocked || this.state.transmitted}
                onClick={() => this.setState({ openFav: true })}
              >
                <Tooltip placement='top' title='favorite'>
                  <Box component={'img'} src={ImagePaths.StarUnfill.default} height={'1.3vw'} width={'1.3vw'} />
                </Tooltip>
              </Button>
              <Box component={'div'}>
                {!this.state.isOPT ?
                  <Autocomplete
                    size='small'
                    disabled={this.state.isLocked || this.props.isLocked}
                    sx={{ width: '13vw', mr: '0.5vw' }}
                    options={this.state.prescriptionTemplateList}
                    getOptionLabel={(option) => typeof (option) === 'string' ? option : option.template_name}
                    value={this.state.selectedTemplate}
                    limitTags={3}
                    autoComplete='off'
                    onChange={(event, newValue) => {
                      this.setState({
                        selectedTemplate: newValue
                      }, () => {
                        this.getPrescriptionData()
                      })
                    }}
                    renderInput={(params) => <TextField {...params} me label='Select Template' />}
                    PaperComponent={({ children }) => {
                      return (
                        <Paper component={'div'}>
                          {children}
                          <Button
                            color="primary"
                            fullWidth
                            sx={{ justifyContent: "flex-start", pl: 2, textTransform: 'capitalize' }}
                            onMouseDown={(e) => {
                              this.setState({ openPopupAddNew: true, tempType: 'Add' })
                            }}
                          >
                            Add Template
                          </Button>
                          {this.state.selectedTemplate ?
                            <Box component={'div'}>
                              <Button
                                color="primary"
                                fullWidth
                                sx={{ justifyContent: "flex-start", pl: 2, textTransform: 'capitalize' }}
                                onMouseDown={(e) => {
                                  this.setState({ openPopupAddNew: true, tempType: 'rename', templateName: this.state.selectedTemplate?.template_name })
                                }}
                              >
                                Rename
                              </Button>
                              <Button
                                color="primary"
                                fullWidth
                                sx={{ justifyContent: "flex-start", pl: 2, textTransform: 'capitalize' }}
                                onMouseDown={(e) => {
                                  this.setState({ deletePopup: true })
                                }}
                              >
                                Delete
                              </Button>
                            </Box> : null
                          }

                        </Paper>
                      )
                    }}
                  /> :
                  <Autocomplete
                    size='small'
                    disabled={this.state.isLocked || this.props.isLocked || (this.state.transmitted && !this.state.Recalled)}
                    sx={{ width: '13vw', mr: '0.5vw' }}
                    options={this.state.MedicationTemplateList?.filter(item => {
                      const pharmacyId = this.state.TapperMedList[0]?.prescription_lines[0]?.pharmacy_id || this.state.selectedPharmacy;
                      return pharmacyId ? item?.pharmacy_id === pharmacyId : true
                    })}
                    getOptionLabel={(option) => typeof (option) === 'string' ? option : option.template_name}
                    value={this.state.selectedTemplate}
                    autoComplete='off'
                    onChange={(event, newValue) => {
                      this.setState({
                        selectedTemplate: newValue
                      }, () => {
                        if (this.state.selectedTemplate) {
                          this.getMedicationTemplateData()
                        }
                      })
                    }}
                    renderInput={(params) => <TextField {...params} me label='Select Template' />}
                    PaperComponent={({ children }) => {
                      return (
                        <Paper component={'div'}>
                          {children}
                          <Button
                            color="primary"
                            fullWidth
                            sx={{ justifyContent: "flex-start", pl: 2, textTransform: 'capitalize' }}
                            onMouseDown={(e) => {
                              this.setState({ openPopupAddNew: true, tempType: 'Add' }, () => { this.getPharmacyList() })
                            }}
                          >
                            Add Template
                          </Button>
                          {this.state.selectedTemplate ?
                            <Box component={'div'}>
                              <Button
                                color="primary"
                                fullWidth
                                sx={{ justifyContent: "flex-start", pl: 2, textTransform: 'capitalize' }}
                                onMouseDown={(e) => {
                                  this.setState({ openPopupAddNew: true, tempType: 'rename', templateName: this.state.selectedTemplate?.template_name, templateId: this.state.selectedTemplate?.id })
                                }}
                              >
                                Update
                              </Button>
                              <Button
                                color="primary"
                                fullWidth
                                sx={{ justifyContent: "flex-start", pl: 2, textTransform: 'capitalize' }}
                                onMouseDown={(e) => {
                                  this.setState({ deletePopup: true, templateName: this.state.selectedTemplate?.template_name })
                                }}
                              >
                                Delete
                              </Button>
                            </Box> : null
                          }
                        </Paper>
                      )
                    }}
                  />}
              </Box>
              <Box component={'div'}>
                <Select
                  size='small'
                  sx={{ width: '10vw' }}
                  value={this.state.selectedLanguage}
                  onChange={(e) => this.setState({ selectedLanguage: e.target.value })}
                >
                  {this.state.languageList?.map((item, index) => (
                    <MenuItem value={item?.value} key={index}>{item?.label}</MenuItem>
                  ))}
                </Select>
              </Box>
              <Box component={'div'}>
                <Button
                  className='Common_Btn_Min_Width'
                  onClick={() => {
                    if (this.state?.isOPT && !this.state.DisableBtn) {
                      this.getMedicationPrint(this.state.TapperMedList[0]?.prescription_id)
                    }
                  }}
                  disabled={prescription?.length > 0 || this.state.TapperMedList?.prescription_lines?.length > 0 ? false : true}
                >
                  <Tooltip placement='top' title='Print'>
                    <Box component={'img'} src={ImagePaths.PrintIcons.default} height={'2vw'} width={'2vw'} />
                  </Tooltip>
                </Button>
              </Box>
              <Box component={'div'}>
                {
                  this.state.selectedTemplate && !this.state?.isOPT ?
                    <Button
                      variant='contained'
                      sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw', position: 'relative', top: '0.5vw' }}
                      onClick={() => { this.postPrescriptionData(false, true) }}
                      disabled={this.state.isLocked || this.props.isLocked || this.state.transmitted}
                    >Save</Button> :
                    prescriptionData && !this.state?.isOPT ?
                      <Button className='Common_Btn_Min_Width'
                        onClick={() => this.getPharmacyList()}
                        disabled={this.state.isLocked || this.props.isLocked || this.state.transmitted}
                      >
                        <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.5vw'} width={'1.5vw'} />
                      </Button> :
                      <Button
                        variant='contained'
                        sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw', position: 'relative', top: '0.5vw' }}
                        onClick={() => { this.getPharmacyList(false, false, this.state.prescriptionData[0]) }}
                        disabled={this.state.isLocked || this.props.isLocked || this.state.TapperMedList[0]?.is_transmitted || this.state.DisableBtn}
                      >Add</Button>
                }
              </Box>
            </Box>
          </Box>
          <Box component={'div'} p={'0.5vw'}>
            {this.renderMedicationTable()}
          </Box>
          {this.state.Recalled ? <Button className='Common_Btn_Min_Width'
            sx={{ height: '2vw', marginLeft: '0.5vw', textTransform: 'capitalize', marginBottom: "0.5vw" }}
            variant='outlined'
            onClick={() => { this.setState({ newMedication: true }, () => { this.getPharmacyList() }) }}
          >
            <Box component={'img'} style={{ marginRight: "0.5vw", borderRadius: "1vw" }} src={ImagePaths.AddIcon.default} height={'1.5vw'} width={'1.5vw'} />
            Add New Medication Table
          </Button> : null}
          {this.renderNewTemplatePopup()}
          {this.renderPrescriptionPopup()}
          {this.renderMedicationPopup()}
          {this.renderFavPopup()}
          {this.renderPrescripTransmitPopup()}
          {this.renderPreviousPrescriptionPopup()}
          {this.renderPreviousMedicationPopup()}
          {this.renderRecallPopup()}
          {this.state.isErrorMsg ?
            <ToastMsg
              severity={'error'}
              msg={this.state.isErrorMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.successMsg ?
            <ToastMsg
              severity={'success'}
              msg={this.state.successMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.deletePopup ? <DeletePopup
            DeletTitle={this.state.SelectedDeleteChip ? `Are you sure ? Do you want to remove ${this.state.SelectedDeleteChip} from suggestion ?` : this.state.templateName ? `Are you sure you want to delete ${this.state.templateName}?` : `Are you sure you want to delete`}
            deleteAlertPopupClose={this.popupClose.bind(this)}
            removeData={this.state.SelectedDeleteChip ? this.deleteFrequencysugg.bind(this) : this.state.templateName ? this.deleteTemplate.bind(this) : this.onDeleteHandler.bind(this)}
            disable={this.state.removeData}
          /> : null}
          <Loader loaderOpen={this.state.DisableBtn} />
        </Box>
      </ShowComponents>
    )
  }
}
