import React, { Component } from 'react'
import './OutpatientHome.css'
import { TextField, IconButton, Tooltip, Box, Typography, Button, ButtonGroup, Menu, MenuItem, Stack, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText, FormControlLabel, Checkbox, Drawer, FormControl, InputLabel, Select } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { AppointmentCard, BillSuccessPoPUp, CommonPatientDetails, NewCommonPatientDetails, ReasonPopup } from '../../../Components/Common Components/CommonComponents';
import { DataGrid, gridNumberComparator } from '@mui/x-data-grid';
import CommonGridHeader, {PovEditPopup} from '../../../Components/Common Components/CommonComponents';
import { Colors } from '../../../Styles/Colors';
import { withTranslation } from 'react-i18next';
import NextAppointments from './NextAppointments';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { DateTime } from 'luxon';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { getCachevalue, localGetItem, setCachevalue } from '../../../Utility/Services/CacheProviderService';
import moment from 'moment/moment';
import { CurrencySymbol, appointment_Status_opt, cmt_Char_limit } from '../../../Utility/Constants';
import { AmountFormat, CheckAccessFunc, FindCreditType, formatToCustomDateTime } from '../../../Components/CommonFunctions/CommonFunctions';
import PaymentsIcon from '@mui/icons-material/Payments';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReportIcon from '@mui/icons-material/Report';
import CreditBillCompletePoP from '../BillingTransaction/CreditBillCompletePoP';

const DilationList = ["Tropicamide Plus", "Homide", "Cylopentolate"]
class OutpatientHome extends Component {
  constructor(props) {
    super(props)
    this.state = {
      datePicker: new Date(),
      AptTblData: [],
      patientCount: {},
      totalAppointment: {},
      anchorEl: null,
      selectedRow: null,
      selectedOption: "",
      isSelected: false,
      selectedID: "",
      selectedCard: '',
      SelectedPatientDetails: {},
      waitingCmts: false,
      deletedPopup: false,
      cancelAppointment: false,
      page: 0,
      pageSize: 30,
      waitCmtText: '',
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: '',
      timeDiff: null,
      SelectedDoctorID: "",
      DoctorsList: [],
      DatePickerOpen: false,
      tokenNum: '',
      barcodeHeader: false,
      creditBillData: [],
      opCreditBill: {},
      receiptID: null,
      pharmacReceipt: null,
      onAddComment: false,
      povText: {},
      ispovPopOpen: false,
      onChangePov: false,
      FilterOpen: false,
      Rescheduled: false,
      Cancelled: false,
      Transferred: false,
      isOPT: false,
    }
  }

  componentDidMount() {
    let DoctorID = JSON.parse(getCachevalue("SelectedDoctorID"))
    let UserData = JSON.parse(localGetItem("loggedInUserInfo"))
    let isOpt = UserData?.is_opt ? UserData?.is_opt : false
    this.setState({ isOPT: isOpt })

    // this.getDoctorsList()
    if (DoctorID !== this.state.SelectedDoctorID && ((DoctorID != null && DoctorID != undefined))) {
      this.setState({ SelectedDoctorID: DoctorID }, () => {
        this.getPatientDetails()
      })
    } else {
      this.getPatientDetails()
    }
    if (isOpt) {
      this.intervalId = setInterval(() => {
        this.setState({ currentTime: formatToCustomDateTime(new Date()) });
      }, 1000);
    }
    
  }

  componentDidUpdate(prevProps) {
    let DoctorID = JSON.parse(getCachevalue("SelectedDoctorID"))
    if (prevProps.history?.location?.state?.NewAppointmentCreated) {
      this.successMsgFunc(prevProps.history?.location?.state?.NewMessage)
      this.props.history.push({ state: { NewAppointmentCreated: false } })
    }
    if (DoctorID !== this.state.SelectedDoctorID && ((DoctorID !== null && DoctorID !== undefined))) {
      this.setState({ SelectedDoctorID: DoctorID }, () => {
        this.getPatientDetails()
      })
    }
    if (prevProps.history?.location?.state?.RequestBool) {
      this.successMsgFunc(this.props.history?.location?.state?.RequestMessage)
      this.getPatientDetails()
      this.props.history.push({ state: { RequestBool: false } })
    }
  }

  errorMsgFunc = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message,
      DisableBtn: false
    })
  }

  successMsgFunc = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message,
      DisableBtn: false
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  ClosePopUp() {
    this.setState({
      creditBillPopUp: false,
      CreditTypeChangePopup: false,
      dataForTypeChage: {},
      receiptID: null,
      pharmacReceipt: null
    })
  }

  ClosesuccessPopUp = () => {
    this.setState({
      receiptID: null,
      pharmacReceipt: null
    })
  }

  getPatientDetails = () => {
    try {
      this.LoaderFunction(true)
      var states = this.state
      var StateDate = new Date(states.datePicker)
      var date = states.datePicker ? DateTime.fromJSDate(StateDate).toFormat('yyyy-MM-dd') : null
      let appointmentFilter = []
      if(states.Rescheduled){
        appointmentFilter.push("Reschedule")
      }
      if(states.Cancelled){
        appointmentFilter.push("Cancelled")
      }
      if(states.Transferred){
        appointmentFilter.push("Transfer")
      }
      appointmentFilter = appointmentFilter.join(",")
      setCachevalue(date, 'OpAppDate')
      setCachevalue(states.selectedCard, 'OPStatus')
      var DoctorId = (JSON.parse(getCachevalue("SelectedDoctorID")) === 0 || JSON.parse(getCachevalue("SelectedDoctorID")) === null || JSON.parse(getCachevalue("SelectedDoctorID")) === undefined) ? "" : JSON.parse(getCachevalue("SelectedDoctorID"));
      if (date === 'Invalid DateTime') {
      } else {
        RestAPIService.getAllTwo(Serviceurls.FO_HOME_OP + `?appointment_date=${date}&appointment_status=${states.selectedCard}&doctor_id=${DoctorId}&appointment_filter=${appointmentFilter}`)
          .then(response => {
            if (response.data.status === "success") {
              this.setState({
                AptTblData: response.data.data ? response.data.data : [],
                patientCount: response.data.patient_count ? response.data.patient_count : {},
                // totalAppointment: response.data.total_appointment ? response.data.total_appointment : {}
              }, () => {
                this.state.AptTblData?.forEach(element => {
                  element.patientDetails = `${element.patient_name} ${element.patient_account_numbers} ${element?.patient_mobile_number} ${element?.doctor_name} ${element?.patient_address_1} ${element?.patient_address_2} ${element?.patient_city} ${element?.father_name} ${element?.mother_name}`
                })
                this.LoaderFunction(false)
                this.GetStatusCount(date, DoctorId )
              }
                //Commented for auto calling
                // , () => {
                //   // setTimeout(() => {
                //     //this.getPatientDetails()
                //   }, 40000
              );
              // })
            }
          }).catch(e => {
            this.LoaderFunction(false)
            if (e?.response?.data?.status === "fail") {
              this.errorMsgFunc(e.response.data.message)
            } else {
              this.errorMsgFunc(e.message)
            }
          })
      }
    } catch (e) {
      this.LoaderFunction(false)
      this.errorMsgFunc(e.message)
    }
  }

  GetStatusCount = (date, DoctorId) => {
    try {
      RestAPIService.getAll(Serviceurls.FO_PATIENT_COUNT_DETAILS_GET + `?appointment_date=${date}&doctor_id=${DoctorId}`).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              totalAppointment: response.data.data ? response.data.data : {}
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.LoaderFunction(false)
            this.errorMsgFunc(error.response.data.message)
          } else {
            this.LoaderFunction(false)
            this.errorMsgFunc(error.message)
          }
        })
    } catch (error) {
      this.LoaderFunction(false)
      this.errorMsgFunc(error.message)
    }
  }

  getPatientCompletedBillData = (data) => {
    try {
      this.LoaderFunction(true)
      RestAPIService.getAll(Serviceurls.FO_PATIENT_BILL_DETAILS_GET + "?patient_id=" + data?.patient_id).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              creditBillData: response.data.data
            }, () => {
              let selectedAppointment = response.data.data.filter((item) => item?.appointment_date === data?.appointment_date)
              this.setState({ creditBillPopUp: true, opCreditBill: selectedAppointment[0], PatientDetails: data })
              this.LoaderFunction(false)
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.LoaderFunction(false)
            this.errorMsgFunc(error.response.data.message)
          } else {
            this.LoaderFunction(false)
            this.errorMsgFunc(error.message)
          }
        })
    } catch (error) {
      this.LoaderFunction(false)
      this.errorMsgFunc(error.message)
    }
  }

  creditBillPost = (message = "", receiptNo = null, phrReceipt = null, error = false, OpId = []) => {
    this.setState({
      creditBillPopUp: false,
      receiptID: receiptNo,
      pharmacReceipt: phrReceipt,
      OpPopupId: OpId
    }
      , () => {
        if (error) {
          this.errorMsgFunc(message)
        } else {
          this.successMsgFunc(message)
          this.getPatientDetails()
        }
      })
  }

  postPatientStatus = (status, id, comments, time, waitTime) => {
    try {
      var states = this.state
      var data = {
        "appointment_id": id,
        "appointment_status": status,
        "waiting_timer": waitTime ? waitTime : time ? time : null
      }
      if (states.waitingCmts) {
        data['waiting_comment'] = comments
      }
      if (states.cancelAppointment) {
        data['cancel_reason'] = comments.replace(/\s+/g, ' ').trim()
      }
      RestAPIService.create(data, Serviceurls.FO_HOME_OP_STATUS)
        .then(response => {
          if (response.data.status == "success") {
            this.getPatientDetails()
            this.setState({
              waitCmtText: '',
              DisableBtn: false
            })
          }
        }).catch(e => {
          this.setState({ DisableBtn: false })
          if (e?.response?.data?.status === "fail") {
            this.errorMsgFunc(e.response.data.message)
          } else {
            this.errorMsgFunc(e.message)
          }
        })
    } catch (e) {
      this.setState({ DisableBtn: false })
      this.errorMsgFunc(e.message)
    }
  }

  getDoctorsList() {
    try {
      RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              DoctorsList: response.data.data.filter((item) => (item.specialization_active === true))
            })
          }
          else {
            this.errorMsgFunc(response.data.message)
          }
        }).catch((e) => {
          this.errorMsgFunc(e.message)
        })
    }
    catch (e) {
      this.errorMsgFunc(e.message)
    }
  }

  getPatientPrescriptionPrint = () => {
    try {
      let APIUrl = ""
      if (this.state.SelectedPatientDetails?.speciality === Serviceurls.ROLE_OPT) {
        // Serviceurls.OP_DOCTOR_PRECSCRIPTION_PRINT + "?appointment_id=" + this.state.SelectedPatientDetails?.appointment_id
        APIUrl = Serviceurls.OPT_PRESCRIPTION_PRINT + "?appointment_id=" + this.state.SelectedPatientDetails?.appointment_id + "&patient_id=" + this.state.SelectedPatientDetails?.patient_id + "&doctor_id=" + this.state.SelectedPatientDetails?.doctor_id + "&language=english"
      }
      else {
        APIUrl = Serviceurls.PRINT_PRESCRIPTION + "?appointment_id=" + this.state.SelectedPatientDetails?.appointment_id + "&patient_id=" + this.state.SelectedPatientDetails?.patient_id + "&doctor_id=" + this.state.SelectedPatientDetails?.doctor_id + "&export_type=pdf"
      }

      RestAPIService.getAllTwo(APIUrl).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({ selectedOption: "" })
        }).catch((error) => {
          this.errorMsgFunc(error.response?.data?.message);
          this.setState({ selectedOption: "" })
        })
    } catch (error) {
      this.errorMsgFunc(error.message)
      this.setState({ selectedOption: "" })
    }
  }

  getPatientDoctorNotesPrint = () => {
    try {
      let APIUrl = ""
      if (this.state.SelectedPatientDetails?.speciality === Serviceurls.ROLE_OPT) {
        APIUrl = Serviceurls.OPT_PATIENT_DOCTOR_NOTES_PRINT + "&appointment_id=" + this.state.SelectedPatientDetails?.appointment_id + "&patient_id=" + this.state.SelectedPatientDetails?.patient_id + "&doctor_id=" + this.state.SelectedPatientDetails?.doctor_id
      } else {
        APIUrl = Serviceurls.DOCTOR_NOTES_PRINT + "?appointment_id=" + this.state.SelectedPatientDetails?.appointment_id + "&patient_id=" + this.state.SelectedPatientDetails?.patient_id + "&doctor_notes=all" + "&doctor_id=" + this.state.SelectedPatientDetails?.doctor_id
      }
      RestAPIService.getAll(APIUrl).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({ selectedOption: "" })
        }).catch((error) => {
          this.errorMsgFunc(error.response?.data?.message);
          this.setState({ selectedOption: "" })
        })
    } catch (error) {
      this.errorMsgFunc(error.message)
      this.setState({ selectedOption: "" })
    }
  }

  getCustomizedCardPrint = () => {
    try {
      RestAPIService.getAll(Serviceurls.OP_CUSTOMIZED_CARD_PRINT + "?patient_id=" + this.state.SelectedPatientDetails?.patient_id + "&appointment_id=" + this.state.SelectedPatientDetails?.appointment_id).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({ selectedOption: "" })
        }).catch((error) => {
          this.errorMsgFunc(error.response?.data?.message);
          this.setState({ selectedOption: "" })
        })
    } catch (error) {
      this.setState({ selectedOption: "" })
      this.errorMsgFunc(error.message)
    }
  }

  getPatientOpCardPrint = (barcodeHeader) => {
    try {
      let Url = ""
      if (barcodeHeader) {
        Url = Serviceurls.OP_CARD_PRINT + "?patient_id=" + this.state.SelectedPatientDetails?.patient_id + "&appointment_id=" + this.state.SelectedPatientDetails?.appointment_id + "&header=false"
      } else {
        Url = Serviceurls.OP_CARD_PRINT + "?patient_id=" + this.state.SelectedPatientDetails?.patient_id + "&appointment_id=" + this.state.SelectedPatientDetails?.appointment_id
      }
      RestAPIService.getAll(Url).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({ selectedOption: "" })
        }).catch((error) => {
          this.errorMsgFunc(error.response?.data?.message);
          this.setState({ selectedOption: "" })
        })
    } catch (error) {
      this.setState({ selectedOption: "" })
      this.errorMsgFunc(error.message)
    }
  }

  getPatientOpBillDetailPrint = () => {
    try {
      RestAPIService.getAll(Serviceurls.OP_DETAILED_BILL_PRINT + "?appointment_id=" + this.state.SelectedPatientDetails?.appointment_id).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({ selectedOption: "" })
        }).catch((error) => {
          if (error.response?.data?.message) {
            this.errorMsgFunc(error.response?.data?.message);
          } else {
            this.errorMsgFunc(error.message);
          }
          this.setState({ selectedOption: "" })
        })
    } catch (error) {
      this.setState({ selectedOption: "" })
      this.errorMsgFunc(error.message)
    }
  }

  getPatientOpSummaryBillPrint = () => {
    try {
      RestAPIService.getAll(Serviceurls.OP_SUMMARY_BILL_PRINT + "?appointment_id=" + this.state.SelectedPatientDetails?.appointment_id).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({
            selectedOption: ""
          })
        }).catch((error) => {
          this.setState({ selectedOption: "" })
          this.errorMsgFunc(error.response?.data?.message);
        })
    } catch (error) {
      this.setState({ selectedOption: "" })
      this.errorMsgFunc(error.message)
    }
  }

  getPatientPresTemplate = () => {
    try {
      let token = this.state.tokenNum ? this.state.tokenNum : null
      RestAPIService.getAll(Serviceurls.OP_PRES_TEMPLATE + "?patient_id=" + this.state.SelectedPatientDetails?.patient_id +
        "&doctor_id=" + this.state.SelectedPatientDetails?.doctor_id +
        "&token_number=" + token + "&appointment_id=" + this.state.SelectedPatientDetails?.appointment_id)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({ selectedOption: "" })
        }).catch((error) => {
          this.errorMsgFunc(error.response?.data?.message);
          this.setState({ selectedOption: "" })
        })
    } catch (error) {
      this.errorMsgFunc(error.message)
      this.setState({ selectedOption: "" })
    }
  }
  getCommonBarCodePrint = () => {
    try {
      RestAPIService.getAll(Serviceurls.COMMON_BARCODE_PRINT + "?patient_id=" + this.state.SelectedPatientDetails?.patient_id + "&appointment_id=" + this.state.SelectedPatientDetails?.appointment_id).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({ selectedOption: "" })
        }).catch((error) => {
          if (error.response?.data?.message) {
            this.errorMsgFunc(error.response?.data?.message);
          } else {
            this.errorMsgFunc(error.message);
          }
          this.setState({ selectedOption: "" })
        })
    } catch (error) {
      this.setState({ selectedOption: "" })
      this.errorMsgFunc(error.message)
    }
  }

  getMedicalRecordPrint = () => {
    try {
      RestAPIService.getAll(Serviceurls.MEDICAL_RECORD_PRINT + "?appointment_id=" + this.state.SelectedPatientDetails?.appointment_id).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({ selectedOption: "" })
        }).catch((error) => {
          if (error.response?.data?.message) {
            this.errorMsgFunc(error.response?.data?.message);
          } else {
            this.errorMsgFunc(error.message);
          }
          this.setState({ selectedOption: "" })
        })
    } catch (error) {
      this.setState({ selectedOption: "" })
      this.errorMsgFunc(error.message)
    }
  }

  renderOPHeader = () => {
    const { t } = this.props
    let userData = JSON.parse(localGetItem("loggedInUserInfo"))
    return (
      <div className='eMed_OP_AptHead'>
        <p>{t('OPAppointments')}</p>
        <div className='eMed_Header_OP_home_div'>
          {
            this.state.datePicker < new Date() && (DateTime.fromJSDate(new Date(this.state.datePicker)).toFormat('yyyy-MM-dd') !== DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd')) && localGetItem("noShowStatus") === "true" ?
              <Button
                emed_tid = 'op_header_noShow'
                className={this.state.selectedCard === 'No Show' ? "eMed_Header_OP_Noshow_btn_on" : 'eMed_Header_OP_Noshow_btn'} size='small'
                onClick={() => { this.setState({ selectedCard: 'No Show' }, () => { this.getPatientDetails() }) }}>
                {`No Show ( ${this.state.totalAppointment?.no_show ? this.state.totalAppointment?.no_show : 0} )`}
              </Button> : null
          }

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              open={this.state.DatePickerOpen}
              onOpen={() => { this.setState({ DatePickerOpen: true }) }}
              onClose={() => { this.setState({ DatePickerOpen: false }) }}
              value={this.state.datePicker}
              name={"datePicker"}
              inputFormat='DD-MM-YYYY'
              views={["year", "month", "day"]}
              onChange={
                (newDate) => {
                  this.setState({
                    datePicker: newDate,
                    selectedCard: ''
                  }, () => {
                    this.getPatientDetails()
                  });
                }}
              renderInput={(params) => <TextField onKeyDown={(e) => e.preventDefault()} style={{ width: '10vw' }} size='small' {...params}  inputProps={{ ...params.inputProps,'emed_tid': 'datePicker_TestID_OP'}}  onClick={() => { this.setState({ DatePickerOpen: true }) }} />}
            />
          </LocalizationProvider>
          {userData?.is_qms_enabled ?
            <Tooltip title="Filter" placement='top' arrow>
              <Button className='eMed_usrconf_btn' emed_tid='opHome_filter_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
              </Button>
            </Tooltip>
            : null}
        </div>
      </div>
    )
  }

  renderPatientType = () => {
    const { t } = this.props
    let { history } = this.props;
    var states = this.state
    // Opt Doc filter
    let is_opt = false
    let is_selectopt = false
    var is_optdoc = states.DoctorsList.find((item) => {
      if (item.specialization_name === Serviceurls.ROLE_OPT || item.specialization_name === Serviceurls.ROLE_OPTOMETRY) {
        is_opt = true
      } else {
        is_opt = false
      }
      return is_opt
    }
    )
    // select Opt Doc filter
    var is_optselect = getCachevalue('SelectedDoctorID')
    var is_optDocselect = states.DoctorsList.filter((item) => (item.doctor_id === +is_optselect))
    var is_selectoptdoc = is_optDocselect.find((item) => {
      if (item.specialization_name === Serviceurls.ROLE_OPT || item.specialization_name === Serviceurls.ROLE_OPTOMETRY) {
        is_selectopt = true
      } else {
        is_selectopt = false
        is_optdoc = false
      }
      return is_selectopt, is_optdoc
    }
    )
    var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
    var UserData = myUser
    let appointmentStatus = JSON.parse(localGetItem('appointment_status')) ? JSON.parse(localGetItem('appointment_status')) :
    JSON.parse(localGetItem('loggedInUserInfo'))?.appointment_status ? JSON.parse(localGetItem('loggedInUserInfo'))?.appointment_status !== null ? JSON.parse(localGetItem('loggedInUserInfo'))?.appointment_status :
        appointment_Status_opt : appointment_Status_opt
    return (
      <div className='eMed_OP_AddPatient'>
        <div className='eMed_OP_PatientType_CreateBtn' >
          {UserData?.module_name !== 'nurse' ?
            <div>
              <Tooltip title={t('CreatePatient')} placement='top' arrow>
                <IconButton emed_tid='op_CreatePatient_btn' onClick={() => { history.push({ pathname: "/OPHome/Billing/QuickBilling" }) }}>
                  <img className='eMed_OP_AddPatient_Icon' src={ImagePaths.AddNewPatient.default} alt={"Add Patient"} />
                </IconButton>
              </Tooltip>
            </div> : null}
          <div className='eMed_OP_PatientTypeCont'>
            <p className='eMed_OP_TypeNew'><img className='eMed_OP_Patient_Iconcircle' src={ImagePaths.NewPatient.default} alt={'New Patient'} /> {t('NewPatients') + ' (' + (states.patientCount.new_patient ? states.patientCount.new_patient : '0') + ')'}</p>
            <p className='eMed_OP_TypeEstablished'><img className='eMed_OP_Patient_Iconcircle' src={ImagePaths.EstablishedPatient.default} alt={'Review Patient'} /> {t('ReviewPatients') + ' (' + (states.patientCount.review_patient ? states.patientCount.review_patient : '0') + ')'}</p>
            <p className='eMed_OP_TypeEstablished'><img className='eMed_OP_Patient_Iconcircle' src={ImagePaths.ReviewPatient.default} alt={'Old Patient'} /> {t('Old Patients') + ' (' + (states.patientCount.old_patient ? states.patientCount.old_patient : '0') + ')'}</p>
          </div>
        </div>
        <div>
          <div className='eMed_OP_Apt_Cards'>
            <AppointmentCard onClick={() => { this.setState({ selectedCard: '' }, () => { this.getPatientDetails() }) }} icon={ImagePaths.BookedPatientFill.default} count={states.totalAppointment.total} label={t("All")} color={"#616161"} isSelectedCard={states.selectedCard === "" ? true : false} />
            <AppointmentCard onClick={() => { this.setState({ selectedCard: 'Booked' }, () => { this.getPatientDetails() }) }} icon={ImagePaths.BookedPatientFill.default} count={states.totalAppointment.booked} label={t("Booked")} color={"#616161"} isSelectedCard={states.selectedCard === "Booked" ? true : false} />
            <AppointmentCard onClick={() => { this.setState({ selectedCard: 'Arrived' }, () => { this.getPatientDetails() }) }} icon={ImagePaths.StatusArrivedFill.default} count={states.totalAppointment.arrived} label={t("Arrived")} color={"#0461B7"} isSelectedCard={states.selectedCard === "Arrived" ? true : false} />
            {this.state.totalAppointment?.optometry > 0 && !appointmentStatus[2]?.is_checked ?
            (is_optdoc || is_selectoptdoc ? <AppointmentCard onClick={() => { this.setState({ selectedCard: 'Optometry' }, () => { this.getPatientDetails() }) }} icon={ImagePaths.OptometryFill.default} count={states.totalAppointment.optometry} label={t("With Optometry")} color={"#ffff33"} isSelectedCard={states.selectedCard === "Optometry" ? true : false} /> : null) : 
            appointmentStatus[2]?.is_checked ?
            (is_optdoc || is_selectoptdoc ? <AppointmentCard onClick={() => { this.setState({ selectedCard: 'Optometry' }, () => { this.getPatientDetails() }) }} icon={ImagePaths.OptometryFill.default} count={states.totalAppointment.optometry} label={t("With Optometry")} color={"#ffff33"} isSelectedCard={states.selectedCard === "Optometry" ? true : false} /> : null) : null}
            {((this.state.totalAppointment?.optometry_completed > 0 && !appointmentStatus[3]?.is_checked) || appointmentStatus[3]?.is_checked) && (is_optdoc || is_selectoptdoc) ?
              <AppointmentCard onClick={() => { this.setState({ selectedCard: 'Optometry Completed' }, () => { this.getPatientDetails() }) }} icon={ImagePaths.withOptCompletedOn.default} count={states.totalAppointment?.optometry_completed || 0} label={t("Opt Completed")} color={"orange"} isSelectedCard={states.selectedCard === "Optometry Completed"} />
              : null}
            {this.state.totalAppointment?.with_nurse > 0 && !appointmentStatus[1]?.is_checked ?
              <AppointmentCard onClick={() => { this.setState({ selectedCard: 'With Nurse' }, () => { this.getPatientDetails() }) }} icon={ImagePaths.StatusNurseFill.default} count={states.totalAppointment.with_nurse} label={t("WithNurse")} color={"#AF2591"} isSelectedCard={states.selectedCard === "With Nurse" ? true : false} /> :
              appointmentStatus[1]?.is_checked ? <AppointmentCard onClick={() => { this.setState({ selectedCard: 'With Nurse' }, () => { this.getPatientDetails() }) }} icon={ImagePaths.StatusNurseFill.default} count={states.totalAppointment.with_nurse} label={t("WithNurse")} color={"#AF2591"} isSelectedCard={states.selectedCard === "With Nurse" ? true : false} /> : null}
            <AppointmentCard onClick={() => { this.setState({ selectedCard: 'With Doctor' }, () => { this.getPatientDetails() }) }} icon={ImagePaths.StatusDoctorFill.default} count={states.totalAppointment.with_doctor} label={t("WithDoctor")} color={"#29B6F6  "} isSelectedCard={states.selectedCard === "With Doctor" ? true : false} />
            {this.state.totalAppointment?.with_counsellor > 0 && !appointmentStatus[5]?.is_checked ?
              <AppointmentCard onClick={() => { this.setState({ selectedCard: 'With Counsellor' }, () => { this.getPatientDetails() }) }} icon={ImagePaths.withCounsellorOn.default} count={states.totalAppointment.with_counsellor ? states.totalAppointment.with_counsellor : 0} label={t("With Counsellor")} color={"#29B6F6  "} isSelectedCard={states.selectedCard === "With Counsellor" ? true : false} /> :
              appointmentStatus[5]?.is_checked ? <AppointmentCard onClick={() => { this.setState({ selectedCard: 'With Counsellor' }, () => { this.getPatientDetails() }) }} icon={ImagePaths.withCounsellorOn.default} count={states.totalAppointment.with_counsellor ? states.totalAppointment.with_counsellor : 0} label={t("With Counsellor")} color={"#29B6F6  "} isSelectedCard={states.selectedCard === "With Counsellor" ? true : false} /> : null}
            {this.state.totalAppointment?.scan_surgery > 0 && !appointmentStatus[6]?.is_checked ?
            (is_optdoc || is_selectoptdoc ? <AppointmentCard onClick={() => { this.setState({ selectedCard: 'Scan/Surgery' }, () => { this.getPatientDetails() }) }} icon={ImagePaths.ScanSurgeryFill.default} count={states.totalAppointment.scan_surgery} label={t("Scan/Surgery")} color={"#ff9900"} isSelectedCard={states.selectedCard === "Scan/Surgery" ? true : false} /> : null) : 
            appointmentStatus[6]?.is_checked ? 
            (is_optdoc || is_selectoptdoc ? <AppointmentCard onClick={() => { this.setState({ selectedCard: 'Scan/Surgery' }, () => { this.getPatientDetails() }) }} icon={ImagePaths.ScanSurgeryFill.default} count={states.totalAppointment.scan_surgery} label={t("Scan/Surgery")} color={"#ff9900"} isSelectedCard={states.selectedCard === "Scan/Surgery" ? true : false} /> : null) : null
            }
            <AppointmentCard onClick={() => { this.setState({ selectedCard: 'Completed' }, () => { this.getPatientDetails() }) }} icon={ImagePaths.CompletedPatientFill.default} count={states.totalAppointment.completed} label={t("Completed")} color={"#1E972A"} isSelectedCard={states.selectedCard === "Completed" ? true : false} />
            <AppointmentCard onClick={() => { this.setState({ selectedCard: 'Cancelled' }, () => { this.getPatientDetails() }) }} icon={ImagePaths.CancelPatients.default} count={states.totalAppointment.cancelled} label={t("Cancelled")} color={"#B8170D"} isSelectedCard={states.selectedCard === "Cancelled" ? true : false} />

          </div>
        </div>
      </div>
    )
  }

  AppointmentTypeIcon(type) {
    const { t } = this.props
    switch (type) {
      case "Telephonic Appointment": return <div className='eMed_OP_AptType_Icon'><Tooltip placement='top' title={t("TelephonicAppointment")} arrow><img className='eMed_OP_Patient_Iconcircle_Appointment'  src={ImagePaths.Telephonic.default}></img></Tooltip></div>
      case "Mobile Appointment": return <div className='eMed_OP_AptType_Icon'><Tooltip placement='top' title={t("MobileAppointment")} arrow><img className='eMed_OP_Patient_Iconcircle_Appointment' src={ImagePaths.Phone.default}></img></Tooltip></div>
      case "Online Appointment": return <div className='eMed_OP_AptType_Icon'><Tooltip placement='top' title={t("Online Appointment")} arrow><img className='eMed_OP_Patient_Iconcircle_Appointment'  src={ImagePaths.Web.default}></img></Tooltip></div>
      case "Web Appointment": return <div className='eMed_OP_AptType_Icon'><Tooltip placement='top' title={t("WebAppointment")} arrow><img className='eMed_OP_Patient_Iconcircle_Appointment'  src={ImagePaths.Web.default}></img></Tooltip></div>
      case "Walk in Appointment": return <div className='eMed_OP_AptType_Icon'><Tooltip placement='top' title={t("WalkAppointment")} arrow><img className='eMed_OP_Patient_Iconcircle_Appointment' src={ImagePaths.Walkin.default}></img></Tooltip></div>
      case "transfer": return <div className='eMed_OP_AptType_Icon'><Tooltip placement='top' title={t("Transfered")} arrow><img className='eMed_OP_Patient_Iconcircle_Appointment' src={ImagePaths.Transfer.default}></img></Tooltip><span id='eMed_OP_AptTbl_AptText' style={{ fontWeight: '600' }}>{" (Transfered)"}</span></div>
      default: return <div className='eMed_OP_AptType_Icon'><Tooltip placement='top' title={type === null ? t("Others") : type} arrow><img className='eMed_OP_Patient_Iconcircle_Appointment' src={ImagePaths.DefaultAppointType.default}></img></Tooltip></div>
    }
  }

  PaymentStatus(BillAmount, ReceivedAmount) {
    const { t } = this.props
    return (
      <Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <PaymentsIcon sx={{ fontSize: "0.9vw", marginRight: '0.4vw', color: '#616161' }} />
          <Typography fontSize={"0.8vw"}>{`Bill : ${AmountFormat(BillAmount)} `}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <CheckCircleIcon sx={{ fontSize: "0.9vw", marginRight: '0.4vw', color: 'green' }} />
          <Typography color={"green"} fontSize={"0.8vw"}>{`Paid : ${AmountFormat(ReceivedAmount)} `}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <ReportIcon sx={{ fontSize: "0.9vw", marginRight: '0.4vw', color: "#B8170D" }} />
          <Typography color={"#B8170D"} fontSize={"0.8vw"}>{`Balance : ${AmountFormat(BillAmount - ReceivedAmount)} `}</Typography>
        </Box>
      </Box>
    )
  }

  calculateTimeDifference = (StartTime, StopTime) => {
    const start = new Date(StartTime);
    const end = new Date(StopTime);
    const difference = end.getTime() - start.getTime();
    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);
    return <Typography fontSize={'0.8vw'} fontWeight={600} color={difference > 1200000 ? "red" : "black"}>{difference > 0 ? `${hours} Hr : ${minutes} Min : ${seconds} Sec` : "0 Hr : 0 Min: 0 Sec"}</Typography>;
  };

  UpdateDilationTime = (id, StartTime = false, EndTime = false, index) => {
    try {
      let DilationObj = this.state.AptTblData.find((list) => (list?.appointment_id === id)) ? this.state.AptTblData.find((list) => (list?.appointment_id === id))?.dilation_details : null
      let DilationValue = DilationObj ? DilationObj[index] ? DilationObj[index]?.dilation : null : null
      if (DilationValue) {
        var data = {
          "appointment_id": id,
          "dilation": DilationValue,
          "set_start_time": StartTime,
          "set_end_time": EndTime
        }
        RestAPIService.create(data, Serviceurls.DOC_DILATION_TIMER_UPDATE)
          .then(response => {
            if (response.data.status == "success") {
              this.getPatientDetails()
              this.getPatientAppointmentStatusCount()
            }
          }).catch(e => {
            if (e?.response?.data?.status === "fail") {
              this.errorMsgFunc(e.response.data.message)
            } else {
              this.errorMsgFunc(e.message)
            }
          })
      } else {
        this.errorMsgFunc("Select the Dilation Type")
      }
    } catch (e) {
      this.errorMsgFunc(e.message)
    }
  }
  renderDilationSelect = (id, index) => {
    let DilationObj = this.state.AptTblData.find((list) => (list?.appointment_id === id)) ? this.state.AptTblData.find((list) => (list?.appointment_id === id))?.dilation_details : null
    let DilationValue = DilationObj ? DilationObj[index] ? DilationObj[index]?.dilation : null : null
    let DilationValueDisable = DilationObj ? DilationObj[index] ? DilationObj[index]?.end_time : false : false
    return (
      <FormControl variant="standard" size='small' sx={{ width: '15vw', padding: 0 }}>
        <InputLabel>{'Dilation'}</InputLabel>
        <Select
          label={'Dilation'}
          value={DilationValue}
          disabled={(DilationValueDisable) || (this.state.isOPT) }
          onChange={(e) => {
            let data = this.state.AptTblData
            if (data[this.state.AptTblData.findIndex((list) => (list?.appointment_id === id))].dilation_details) {
              data[this.state.AptTblData.findIndex((list) => (list?.appointment_id === id))].dilation_details[index].dilation = e.target.value
            } else {
              data[this.state.AptTblData.findIndex((list) => (list?.appointment_id === id))].dilation_details = [
                {
                  "dilation": e.target.value,
                  "end_time": "",
                  "start_time": ""
                }
              ]
            }
            this.setState({ AptTblData: data })
          }}
          inputProps={{ style: { padding: 0, fontSize: '0.9vw' } }}
        >
          {DilationList?.map((item, index) => (
            <MenuItem key={index} value={item}>{item}</MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

  StatusBtns(data, id) {
    var { AptTblData } = this.state
    const { t } = this.props
    let FirstDilationDetails = data?.dilation_details ? data?.dilation_details[0] : {}
    let SecondDilationDetails = data?.dilation_details ? data?.dilation_details[1] : null
    let appointmentStatus = JSON.parse(localGetItem('appointment_status')) ? JSON.parse(localGetItem('appointment_status')) :
      JSON.parse(localGetItem('loggedInUserInfo'))?.appointment_status !== null ? JSON.parse(localGetItem('loggedInUserInfo'))?.appointment_status :
        appointment_Status_opt

    return (
      data.appointment_status === "Completed" ?
        <div><Button emed_tid = {"Completed_01_TestID"} sx={{ textTransform: 'capitalize', width: "10vw", backgroundColor: Colors.themeDark }} size='small' disableElevation variant='contained'>{t("Completed")}</Button></div> :
        data.appointment_status === "Cancelled" ?
          <Tooltip title={`Cancelled Due to ${data?.cancel_reason}`} arrow placement='top'>
            <div><Button emed_tid = {"Cancelled_01_TestID"}  size='small' color='error' sx={{ textTransform: 'capitalize', width: '10vw' }} variant='contained'>{t("Cancelled")}</Button></div>
          </Tooltip> :
          <div>
            <ButtonGroup size="small" sx={{ backgroundColor: '#E4F2F0' }}>
              <Tooltip title={t('Arrived')} placement='top' arrow>
                <Button  emed_tid = {"Arrived_01_TestID"} id='eMed_OP_Btn' key="Arrived" onClick={() => {
                  this.setState({
                    DisableBtn: true
                  }, () => {
                    var today = new Date()
                    var date = DateTime.fromJSDate(today).toFormat('yyyy-MM-dd')
                    var AptDate = new Date(data.appointment_date)
                    var AppointmentDate = DateTime.fromJSDate(AptDate).toFormat('yyyy-MM-dd')

                    if (date === AppointmentDate) {
                      this.postPatientStatus('Arrived', id, data.waiting_comment)
                    } else {
                      this.errorMsgFunc("Arrived status can't be changed for future or past dates.")
                    }
                  })
                

                }
                } disabled={this.state.DisableBtn || ((data.appointment_status === "Booked" || data.appointment_status === "On Queue") ? false : true)}>
                  <img className='eMed_OP_AptTbl_Status_Icon' src={(data.appointment_status === 'Arrived' ||
                    data.appointment_status === "With Nurse" || data.appointment_status === 'With Counsellor' ||
                    data.appointment_status === "With Doctor" ||
                    data.appointment_status === "Optometry" ||
                    data.appointment_status === "Optometry Completed" ||
                    data.appointment_status === "Scan/Surgery") ? ImagePaths.StatusArrivedFill.default : ImagePaths.StatusArrived.default} alt='Arrived' />
                </Button>
              </Tooltip>
              {this.state.totalAppointment?.with_nurse > 0 && !appointmentStatus[1]?.is_checked ?
                <Tooltip title={t('WithNurse')} placement='top' arrow>
                  <Button id='eMed_OP_Btn' key="WithNurse" emed_tid='op_WithNurse_btn' onClick={() => {
                    this.setState({
                      DisableBtn: true
                    }, () => { this.postPatientStatus('With Nurse', id, data.waiting_comment, (data.waiting_timer ? null : data.timer), data.waiting_timer) })
                  }
                  } disabled={((data.appointment_status === "Arrived" || data.appointment_status === "Optometry" || data.appointment_status === "With Doctor") ? false : true) || this.state.DisableBtn}>
                    <img className='eMed_OP_AptTbl_Status_Icon' src={(data.appointment_status === "With Nurse" ||
                      data.appointment_status === "With Doctor" || data.appointment_status === 'With Counsellor' ||
                      data.appointment_status === "Optometry" || data.appointment_status === "Optometry Completed" ||
                      data.appointment_status === 'Scan/Surgery') ? ImagePaths.StatusNurseFill.default : ImagePaths.StatusNurse.default} alt='With Nurse' />
                  </Button>
                </Tooltip> :
                appointmentStatus[1]?.is_checked ?
                  <Tooltip title={t('WithNurse')} placement='top' arrow>
                    <Button id='eMed_OP_Btn' key="WithNurse" emed_tid='op_WithNurse_btn' onClick={() => {
                      this.setState({
                        DisableBtn: true
                      }, () => { this.postPatientStatus('With Nurse', id, data.waiting_comment,(data.waiting_timer ? null : data.timer), data.waiting_timer) })
                    }
                    } disabled={((data.appointment_status === "Arrived" || data.appointment_status === "Optometry" || data.appointment_status === "With Doctor") ? false : true) || this.state.DisableBtn}>
                      <img className='eMed_OP_AptTbl_Status_Icon' src={(data.appointment_status === "With Nurse" ||
                        data.appointment_status === "With Doctor" || data.appointment_status === 'With Counsellor' ||
                        data.appointment_status === "Optometry" || data.appointment_status === "Optometry Completed" ||
                        data.appointment_status === 'Scan/Surgery') ? ImagePaths.StatusNurseFill.default : ImagePaths.StatusNurse.default} alt='With Nurse' />
                    </Button>
                  </Tooltip> : null}

              {/* For Optometry Icon start */}
              {(this.state.totalAppointment?.optometry > 0 && !appointmentStatus[2]?.is_checked && (data.speciality === Serviceurls.ROLE_OPTOMETRY || data.speciality === Serviceurls.ROLE_OPT)) ? 
              <Tooltip title={t('With Optometry')} placement='top' arrow>
              <Button id='eMed_OP_Btn' key="Optometry" onClick={() => {
                this.setState({
                  DisableBtn: true
                }, () => { this.postPatientStatus('Optometry', id, data.waiting_comment, (data.waiting_timer ? null : data.timer), data.waiting_timer) })
              }
              }
                disabled={appointmentStatus[1]?.is_checked ?
                  (((data.appointment_status === "With Nurse" || data.appointment_status === "With Doctor") ? false : true) || this.state.DisableBtn) :
                  ((data.appointment_status === "Arrived" || data.appointment_status === "With Nurse" || data.appointment_status === "With Doctor") ? false : true) || this.state.DisableBtn
                }
              // disabled={((data.appointment_status === "With Nurse" || data.appointment_status === "With Doctor") ? false : true) || this.state.DisableBtn}
              >
                <img className='eMed_OP_AptTbl_Status_Icon' src={(data.appointment_status === "Optometry" || data.appointment_status === "With Doctor" || data.appointment_status === "Scan/Surgery" || data.appointment_status === 'With Counsellor' || data.appointment_status === "Optometry Completed") ? ImagePaths.OptometryFill.default : ImagePaths.Optometry.default} alt='With Nurse' />
              </Button>
            </Tooltip> :
              appointmentStatus[2]?.is_checked && (data.speciality === Serviceurls.ROLE_OPTOMETRY || data.speciality === Serviceurls.ROLE_OPT) ?
                <Tooltip title={t('With Optometry')} placement='top' arrow>
                  <Button id='eMed_OP_Btn' key="Optometry" emed_tid='op_Optometry_btn' onClick={() => {
                    this.setState({
                      DisableBtn: true
                    }, () => { this.postPatientStatus('Optometry', id, data.waiting_comment, (data.waiting_timer ? null : data.timer), data.waiting_timer) })
                  }
                  }
                    disabled={appointmentStatus[1]?.is_checked ?
                      (((data.appointment_status === "With Nurse" || data.appointment_status === "With Doctor") ? false : true) || this.state.DisableBtn) :
                      ((data.appointment_status === "Arrived" || data.appointment_status === "With Doctor" || data.appointment_status === "With Nurse") ? false : true) || this.state.DisableBtn
                    }
                  >
                    <img className='eMed_OP_AptTbl_Status_Icon' src={(data.appointment_status === "Optometry" || data.appointment_status === "With Doctor" || data.appointment_status === "Scan/Surgery" || data.appointment_status === 'With Counsellor'|| data.appointment_status === "Optometry Completed") ? ImagePaths.OptometryFill.default : ImagePaths.Optometry.default} alt='With Nurse' />
                  </Button>
                </Tooltip> : null}
              {/* For Optometry Icon end */}
              {/* For Optometry completed start */}
              {((this.state.totalAppointment?.optometry_completed > 0 && !appointmentStatus[3]?.is_checked) || (appointmentStatus[3]?.is_checked)) && (data.speciality === Serviceurls.ROLE_OPTOMETRY || data.speciality === Serviceurls.ROLE_OPT) ?
                <Tooltip title={t('Optometry Completed')} placement='top' arrow>
                  <Button id='eMed_OP_Btn' key="optometry_completed" onClick={() => {
                    this.setState({
                      DisableBtn: true
                    }, () => { this.postPatientStatus('Optometry Completed', id, data.waiting_comment, (data.waiting_timer ? null : data.timer), data.waiting_timer) })
                  }
                  }
                    disabled={(((data.appointment_status === "Optometry") ? false : true) || this.state.DisableBtn)}
                  >
                    <img className='eMed_OP_AptTbl_StatusCom_Icon' src={(data.appointment_status === "Optometry Completed" || data.appointment_status === "With Doctor" || data.appointment_status === "Scan/Surgery" || data.appointment_status === 'With Counsellor') ? ImagePaths.withOptCompletedOn.default : ImagePaths.withOptCompletedOff.default} alt='Opt Complete' />
                  </Button>
                </Tooltip> : null}
              {/* For Optometry completed end */}
              <Tooltip title={t('WithDoctor')} placement='top' arrow>
                <Button id='eMed_OP_Btn' emed_tid='op_withDoctor_btn' key="WithDoctor" onClick={() => {
                  this.setState({
                    DisableBtn: true
                  }, () => { this.postPatientStatus('With Doctor', id, data.waiting_comment, (data.waiting_timer ? null : data.timer), data.waiting_timer) })
                }
                }
                  disabled={this.state.totalAppointment?.with_nurse > 0 && !appointmentStatus[1]?.is_checked ?
                    ((data.appointment_status === "With Nurse" || data.appointment_status === 'Optometry' || data.appointment_status === 'Scan/Surgery' || data.appointment_status === 'With Counsellor'|| data.appointment_status === "Optometry Completed" ? false : true) || this.state.DisableBtn) :
                    appointmentStatus[1]?.is_checked ?
                      ((data.appointment_status === "With Nurse" || data.appointment_status === 'Optometry' || data.appointment_status === 'Scan/Surgery' || data.appointment_status === 'With Counsellor' || data.appointment_status === "Optometry Completed" ? false : true) || this.state.DisableBtn) :
                      ((data.appointment_status === "Arrived" || data.appointment_status === "With Nurse" || data.appointment_status === 'Optometry' || data.appointment_status === 'Scan/Surgery' || data.appointment_status === 'With Counsellor'|| data.appointment_status === "Optometry Completed" ? false : true) || this.state.DisableBtn)
                  }
                // disabled={(data.appointment_status === "With Nurse" || data.appointment_status === 'Optometry' || data.appointment_status === 'Scan/Surgery' || data.appointment_status === 'With Counsellor' ? false : true) || this.state.DisableBtn}
                >
                  <img className='eMed_OP_AptTbl_Status_Icon' src={(data.appointment_status === "With Doctor" || data.appointment_status === 'With Counsellor' || data.appointment_status === 'Scan/Surgery') ? ImagePaths.StatusDoctorFill.default : ImagePaths.StatusDoctor.default} alt='With Doctor' />
                </Button>
              </Tooltip>

              {this.state.totalAppointment?.with_counsellor > 0 && !appointmentStatus[5]?.is_checked ?
                <Tooltip title={t('With Counsellor')} placement='top' arrow>
                  <Button id='eMed_OP_Btn'  emed_tid='op_Scan/Surgery_btn' key="WithCounsellor" onClick={() => {
                    this.setState({
                      DisableBtn: true
                    }, () => { this.postPatientStatus('With Counsellor', id, data.waiting_comment) })
                  }
                  }
                    disabled={((data.appointment_status === "With Doctor" || data.appointment_status === 'Scan/Surgery' ? false : true) || this.state.DisableBtn)}
                  // disabled={(data.appointment_status === "With Doctor" ? false : true) || this.state.DisableBtn}
                  >
                    <img className='eMed_OP_AptTbl_Status_Icon' src={(data.appointment_status === "With Counsellor" || data.appointment_status === "Scan/Surgery") ? ImagePaths.withCounsellorOn.default : ImagePaths.withCounsellorOff.default} alt='With Counsellor' />
                  </Button>
                </Tooltip> :
                appointmentStatus[5]?.is_checked ?
                  <Tooltip title={t('With Counsellor')} placement='top' arrow>
                    <Button id='eMed_OP_Btn' key="WithCounsellor" onClick={() => {
                      this.setState({
                        DisableBtn: true
                      }, () => { this.postPatientStatus('With Counsellor', id, data.waiting_comment) })
                    }
                    }
                      disabled={((data.appointment_status === "With Doctor" || data.appointment_status === 'Scan/Surgery' ? false : true) || this.state.DisableBtn)}
                    // disabled={(data.appointment_status === "With Doctor" ? false : true) || this.state.DisableBtn}
                    >
                      <img className='eMed_OP_AptTbl_Status_Icon' src={(data.appointment_status === "With Counsellor" || data.appointment_status === "Scan/Surgery") ? ImagePaths.withCounsellorOn.default : ImagePaths.withCounsellorOff.default} alt='With Counsellor' />
                    </Button>
                  </Tooltip> : null}

              {/* For Optometry Icon start */}
              {this.state.totalAppointment?.scan_surgery > 0 && !appointmentStatus[6]?.is_checked && (data.speciality === Serviceurls.ROLE_OPTOMETRY || data.speciality === Serviceurls.ROLE_OPT) ? 
              <Tooltip title={t('Scan/Surgery')} placement='top' arrow>
              <Button id='eMed_OP_Btn' key="Scan/Surgery" onClick={() => {
                this.setState({
                  DisableBtn: true
                }, () => { this.postPatientStatus('Scan/Surgery', id, data.waiting_comment) })
              }
              } disabled={((data.appointment_status === "With Doctor" || data.appointment_status === 'With Counsellor') ? false : true) || this.state.DisableBtn}>
                <img className='eMed_OP_AptTbl_Status_Icon' src={(data.appointment_status === "Scan/Surgery") ? ImagePaths.ScanSurgeryFill.default : ImagePaths.ScanSurgery.default} alt='With Nurse' />
              </Button>
            </Tooltip> :
              appointmentStatus[6]?.is_checked && (data.speciality === Serviceurls.ROLE_OPTOMETRY || data.speciality === Serviceurls.ROLE_OPT) ?
                <Tooltip title={t('Scan/Surgery')} placement='top' arrow>
                  <Button id='eMed_OP_Btn' emed_tid='op_Scan/Surgery_btn' key="Scan/Surgery" onClick={() => {
                    this.setState({
                      DisableBtn: true
                    }, () => { this.postPatientStatus('Scan/Surgery', id, data.waiting_comment) })
                  }
                  } disabled={((data.appointment_status === "With Doctor" || data.appointment_status === 'With Counsellor') ? false : true) || this.state.DisableBtn}>
                    <img className='eMed_OP_AptTbl_Status_Icon' src={(data.appointment_status === "Scan/Surgery") ? ImagePaths.ScanSurgeryFill.default : ImagePaths.ScanSurgery.default} alt='With Nurse' />
                  </Button>
                </Tooltip> : null}
              {/* For Optometry Icon end */}
              <Tooltip title={t('Completed')} placement='top' arrow>
                <Button id='eMed_OP_Btn' emed_tid='op_Completed_btn' key="Completed" onClick={() => {
                  this.setState({
                    DisableBtn: true
                  }, () => { this.postPatientStatus('Completed', id, data.waiting_comment) })
                }
                } disabled={(data.appointment_status === 'With Doctor' || data.appointment_status === 'Scan/Surgery' || data.appointment_status === 'With Counsellor' ? false : true) || this.state.DisableBtn} >
                  <img className='eMed_OP_AptTbl_StatusCom_Icon' src={ImagePaths.StatusCompleted.default} alt='Completed' />
                </Button>
              </Tooltip>
            </ButtonGroup>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box display={(this.state.isOPT && data.appointment_status === 'Optometry' && FirstDilationDetails) ? "flex" : "none"} sx={{ flexDirection: 'column', alignItems: "center", justifyContent: "space-between", height: "7vh", marginTop: '0.5vw' }}>
                  {this.renderDilationSelect(id, 0)}
                  <Box sx={{ display: 'flex', justifyContent: "space-around", alignItems: 'center' }}>
                    {FirstDilationDetails && FirstDilationDetails?.start_time && !FirstDilationDetails?.end_time ? this.calculateTimeDifference(FirstDilationDetails?.start_time, this.state.currentTime) : (FirstDilationDetails?.start_time && FirstDilationDetails?.end_time ? this.calculateTimeDifference(FirstDilationDetails?.start_time, FirstDilationDetails?.end_time) : <Typography fontSize={'0.8vw'} fontWeight={600}>{"0 Hr : 0 Min: 0 Sec"}</Typography>)}
                    {!FirstDilationDetails?.start_time ? <Button disabled={this.state.isOPT} size='small' sx={{ textTransform: "capitalize", fontWeight: 600 }} onClick={() => { this.UpdateDilationTime(id, true, false, 0) }}>Start</Button> : null}
                    {FirstDilationDetails?.start_time && !FirstDilationDetails?.end_time ? <Button disabled={this.state.isOPT} sx={{ textTransform: "capitalize", fontWeight: 600 }} size='small' color='error' onClick={() => { this.UpdateDilationTime(id, false, true, 0) }}>Stop</Button> : null}
                    {FirstDilationDetails?.end_time && !SecondDilationDetails ? <Button disabled={this.state.isOPT} sx={{ textTransform: "capitalize", fontWeight: 600 }} size='small' color='info' onClick={() => {
                      let data = this.state.AptTblData
                      data[this.state.AptTblData.findIndex((list) => (list?.appointment_id === id))].dilation_details[1] = {
                        "dilation": "",
                        "end_time": "",
                        "start_time": ""
                      }
                      this.setState({ AptTblData: data })
                    }}>Add New</Button> : null}
                  </Box>
                </Box>
                <Box display={(this.state.isOPT && data.appointment_status === 'Optometry' && SecondDilationDetails) ? "flex" : "none"} sx={{ flexDirection: 'column', alignItems: "center", justifyContent: "space-between", height: "7vh", marginTop: "0.5vw" }}>
                  {this.renderDilationSelect(id, 1)}
                  <Box sx={{ display: 'flex', justifyContent: "space-around", alignItems: 'center' }}>
                    {SecondDilationDetails && SecondDilationDetails?.start_time && !SecondDilationDetails.end_time ? this.calculateTimeDifference(SecondDilationDetails?.start_time, this.state.currentTime) : (SecondDilationDetails?.start_time && SecondDilationDetails.end_time ? this.calculateTimeDifference(SecondDilationDetails?.start_time, SecondDilationDetails.end_time) : <Typography fontSize={'0.8vw'} fontWeight={600}>{"0 Hr : 0 Min: 0 Sec"}</Typography>)}
                    {!SecondDilationDetails?.start_time ? <Button disabled = {this.state.isOPT} sx={{ textTransform: "capitalize", fontWeight: 600 }} size='small' onClick={() => { this.UpdateDilationTime(id, true, false, 1) }}>Start</Button> : null}
                    {SecondDilationDetails?.start_time && !SecondDilationDetails.end_time ? <Button disabled = {this.state.isOPT} sx={{ textTransform: "capitalize", fontWeight: 600 }} size='small' color='error' onClick={() => { this.UpdateDilationTime(id, false, true, 1) }}>Stop</Button> : null}
                  </Box>
                </Box>
              </Box>
          </div>

    )
  }
  ActionBtn = (data) => {
    const { t } = this.props
    var states = this.state
    let multi_option = []
    if (data.row.appointment_status === "Booked" || data.row.appointment_status === "Arrived") {
      multi_option.push({ label: "Reschedule", value: "Reschedule" })
      multi_option.push({ label: "PrintDoctorNotes", value: "PrintDoctorNotes" })
      multi_option.push({ label: "PrintPrescription", value: "PrintPrescription" })
      multi_option.push({ label: "Customized Print", value: "CustomizedPrint" })
      multi_option.push({ label: "OpBarcode", value: "OpBarcode" })
      multi_option.push({ label: "OpCard", value: "OpCard" })
      multi_option.push({ label: "BillDetails", value: "BillDetails" })
      multi_option.push({ label: "SummaryBill", value: "SummaryBill" })
      multi_option.push({ label: "PresTem", value: "PrescriptionTemplate" })
      multi_option.push({ label: "BarCode", value: "CommonBarcode" })
      multi_option.push({ label: "MedicalRecord", value: "MedicalRecord" })
      multi_option.push({ label: "TransAppoint", value: "TransAppoint" })
      multi_option.push({ label: "CancelAppontment", value: "CancelAppontment" })
    }
    else {
      multi_option.push({ label: "NextAppointment", value: "NextAppointment" })
      multi_option.push({ label: "PrintDoctorNotes", value: "PrintDoctorNotes" })
      multi_option.push({ label: "PrintPrescription", value: "PrintPrescription" })
      multi_option.push({ label: "Customized Print", value: "CustomizedPrint" })
      multi_option.push({ label: "OpBarcode", value: "OpBarcode" })
      multi_option.push({ label: "OpCard", value: "OpCard" })
      multi_option.push({ label: "BillDetails", value: "BillDetails" })
      multi_option.push({ label: "SummaryBill", value: "SummaryBill" })
      multi_option.push({ label: "PresTem", value: "PrescriptionTemplate" })
      multi_option.push({ label: "BarCode", value: "CommonBarcode" })
      multi_option.push({ label: "MedicalRecord", value: "MedicalRecord" })
      multi_option.push({ label: "TransAppoint", value: "TransAppoint" })
    }

    if (data?.row?.is_emergency) {
      multi_option?.push({ label: "Disable Highlight", value: "ChangeTag" })
    }
    return (
      <div className='eMed_OP_Apt_ActionBtn'>
        <Tooltip title={t('CreateBill')} placement='top' arrow>
          <div>
            <IconButton disabled={(data.row.appointment_status === "Cancelled" || data.row.appointment_status === "No Show") ? true : false} emed_tid='op_home_CreateBill_btn' onClick={() => {
              let { history } = this.props;
              setCachevalue(JSON.stringify(data?.row), "patientData")
              history.push({ pathname: '/OPHome/PatientDetails/Billing' })

            }}
            ><img className='eMed_OP_AptTbl_Status_Icon' src={(data.row.appointment_status === "Cancelled" || data.row.appointment_status === "No Show") ? ImagePaths.DisabledCreateBill.default : ImagePaths.BillCreated.default} alt='Create Bill' /></IconButton>
          </div>
        </Tooltip>
        {/* <Tooltip title={t('CancelAppontment')} placement='top' arrow>
          <div>
            <IconButton disabled={data.row.appointment_status === "Booked" || data.row.appointment_status === "Arrived" ? false : true} onClick={() => {
              this.setState({ cancelAppointment: true, SelectedPatientDetails: data.row })
            }}>
              <img className='eMed_OP_AptTbl_Status_Icon' src={data.row.appointment_status === "Booked" || data.row.appointment_status === "Arrived" ? ImagePaths.CancelAppoinment.default : ImagePaths.CancelAppDisabled.default} alt='Cancel Appointments' />
            </IconButton>
          </div>
        </Tooltip> */}
        <Tooltip title={t('Receive Payment')} placement='top' arrow>
          <div>
            <IconButton emed_tid='op_home_receivePayment_btn' disabled={CheckAccessFunc("front_office", "Home", "Billing", "Bills", "SubTab")?.editAccess && data.row?.appointment_id && data.row.appointment_status !== "Cancelled" && (((data.row?.bill_amount ? data.row?.bill_amount : 0) - (data.row?.received_amount ? data.row?.received_amount : 0)) > 0) ? false : true}
              onClick={() => {
                this.getPatientCompletedBillData(data.row)
              }}>
              <img className='eMed_OP_AptTbl_Status_Icon' src={CheckAccessFunc("front_office", "Home", "Billing", "Bills", "SubTab")?.editAccess && data.row?.appointment_id && data.row.appointment_status !== "Cancelled" && (((data.row?.bill_amount ? data.row?.bill_amount : 0) - (data.row?.received_amount ? data.row?.received_amount : 0)) > 0) ? ImagePaths.Payment.default : ImagePaths.DisPayment.default} alt='Cancel Appointments' />
            </IconButton>
          </div>
        </Tooltip>
        <div>
          <Tooltip title={t("More")} placement='top' arrow>
            <IconButton emed_tid='op_home_more_btn'
              onClick={(e) => {
                this.setState({
                  anchorEl: e.currentTarget,
                  selectedRow: data.id,
                  isSelected: true
                })
              }}
              disabled={(data.row.appointment_status === "Cancelled" || data.row.appointment_status === "No Show") ? true : false}
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
          {(this.state.isSelected && this.state.selectedRow == data.id) ?
            <Menu
              anchorEl={this.state.anchorEl}
              open={Boolean(this.state.anchorEl)}
              onClose={() => {
                this.setState({ anchorEl: null, })
              }}
            >
              {multi_option.map((option) => (
                <MenuItem key={option} emed_tid={`op_home_${option.label}_btn`}
                  disabled={(((data.row.appointment_status !== "Completed" && data.row.appointment_status !== "With Doctor") || data.row.is_nurse)
                    && (option.label === "PrintDoctorNotes" || option.label === "PrintPrescription"))
                    || (data.row.is_nurse && option.label === "PresTem")
                    || (option.label === "TransAppoint" && data.row.appointment_status === "Completed")
                    || (this.state.selectedOption === option.value)
                    ? true : false}
                  onClick={() => { this.setState({ selectedOption: option.value, anchorEl: null, SelectedPatientDetails: data.row, tokenNum: data.row.token_number }) }}>
                  {t(option.label)}
                </MenuItem>
              ))}
            </Menu> : null}
        </div>
      </div>
    )
  }

  routeToDemograhics = (params) => {
    let { history } = this.props;
    setCachevalue(JSON.stringify(params?.row), "patientData")
    if (params.field === "patientDetails" || (params.field === "purpose_of_visit" && !this.state.onChangePov) || params.field === "appointment_start_time" || params.field === "bill_amount" || (params.field === "token_number" && !this.state.onAddComment)) {
      history.push({ pathname: "/OPHome/PatientDetails/Demographics" })
    }
  }
  handleClose = () => {
    this.setState({
      waitingCmts: false
    })
  }

  addComments = (value) => {
    var states = this.state
    if (this.state.waitCmtText) {
      var data1 = value.waiting_comment
      var data2 = []
      var date = new Date()
      var options = { timeZone: 'Asia/Kolkata', hour12: true, hour: 'numeric', minute: 'numeric' }
      var timeString = date.toLocaleTimeString('en-IN', options)
      data2.push({
        "date": DateTime.fromJSDate(date).toFormat('dd-MM-yyyy'),
        "time": timeString,
        "comments": states.waitCmtText.replace(/\s+/g, ' ').trim(),
      })
      var cmtData = [...data1, ...data2]
      this.setState({
        waitingCmts: false,
      })
      this.postPatientStatus('', states.SelectedPatientDetails.appointment_id, cmtData)
    } else {
      this.errorMsgFunc('Please Enter The Comment')
    }
  }

  povPopClose = () => {
    this.setState({
      povText: {},
      ispovPopOpen: false,
      onChangePov: false,
    })
  }

  renderWaitingCmt = (data) => {
    const { t } = this.props
    var states = this.state
    return (
      states.SelectedPatientDetails.patient_id == data.patient_id ?
        <Dialog
          open={this.state.waitingCmts}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
            <div>
              {t("WaitingComments")}
            </div>
            <div className="eMed_DialogBox_Close">
              <img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt='Close' emed_tid='op_home_WaitingComments_btn' onClick={() => { this.setState({ waitingCmts: false, waitCmtText: '' }) }} />
            </div>
          </div>
          {
            data.appointment_status !== "Completed" ?
              <div>
                <DialogContent>
                  <TextField
                    sx={{ width: '25vw' }}
                    multiline
                    inputProps={{'emed_tid' : 'AddComments_testID', maxLength: cmt_Char_limit }}
                    rows={3}
                    placeholder={t("AddComments")}
                    variant="outlined"
                    value={this.state.waitCmtText}
                    onChange={(e) => {
                      this.setState({ waitCmtText: e.target.value })
                    }} />
                </DialogContent>
                <DialogActions id="eMed_DialogBox_Btn">
                  <Button size="small" variant='outlined' id="eMed_Dia_Btn" emed_tid='op_home_comments_clear_btn' onClick={() => { this.setState({ waitCmtText: '' }) }}>
                    {t('Clear')}
                  </Button>
                  <Button size="small" variant='contained' id="eMed_Dia_Btn" emed_tid='op_home_comments_add_btn' onClick={() => { this.addComments(data) }}>
                    {t('Add')}
                  </Button>

                </DialogActions>
              </div> : null
          }
          {data.waiting_comment.length > 0 ?
            <DialogContent id='eMed_OP_oldComments_Con'>
              <div className='eMed_OP_oldComments_Con1'>
                {data?.waiting_comment.map((item) => (
                  <div style={{ height: 'auto' }} >
                    <p className='eMed_OP_Cmts_Text'>{item.comments}</p>
                    <p className='eMed_OP_Cmts_Text1'>{item.time + ' | ' + item.date}</p>
                    <hr />
                  </div>
                ))}
              </div>
            </DialogContent> :
            <DialogContent id='eMed_OP_no_comments_div'>
              <Typography className='eMed_OP_no_comments_txt'>No Comments</Typography>
            </DialogContent>}
        </Dialog> : null
    )
  }
  waitTime = (data, id) => {
    var { AptTblData } = this.state;

    const convertTime12to24 = moment(data.patient_arrived_time, ["h:mm A"]).format("HH:mm");
    var year = moment(new Date(data.appointment_date)).format("LL") + " " + convertTime12to24 + ":00"
    var currnet_date = new Date();
    var arrived_date = new Date(year);
    var res = Math.abs(currnet_date - arrived_date) / 1000;
    var days = Math.floor(res / 86400);
    var hours = Math.floor(res / 3600) % 24;
    var minutes = Math.floor(res / 60) % 60;
    var seconds = res % 60;

    // const newDate = new Date().toLocaleTimeString('en-US', { hour12: false, })
    // var startTime = moment(data.patient_arrived_time != null ? ":00 PM" : '', "HH:mm:ss");
    // var endTime = moment(newDate, "HH:mm:ss");
    // var duration = moment.duration(endTime.diff(startTime));
    // var hours = parseInt(duration.asHours());
    // var minutes = parseInt(duration.asMinutes()) - hours * 60;
    var result = hours + ' Hr ' + minutes + ' Mins.'
    var time = data.patient_arrived_time ? "Arrived: " + data.patient_arrived_time + " | " + (data.waiting_timer ? data.waiting_timer : result) : "-"

    AptTblData && AptTblData.length > 0 && (AptTblData).map((element, index) => {
      if (id === element.patient_id && element.timer != result && element.timer === undefined) {
        AptTblData[index]["timer"] = result === "NaN Hr NaN Mins." ? "0 Hr 0 Mins." : result
        this.setState({
          AptTblData
        })
      }
    })
    return (
      <Typography id='eMed_OP_AptTbl_AptText1'  fontWeight={'bold'}>{time}</Typography>
    )
  }

  numberSort = (v1, v2) => {
    let sorted = gridNumberComparator(v1, v2);
    return sorted;
  }
  renderAptTable = () => {
    const { t } = this.props
    var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
    const appointmentStatusFromStorage = JSON.parse(localGetItem('appointment_status'));
    const loggedInUserInfo = myUser?.appointment_status;
    const appointmentStatus = appointmentStatusFromStorage || (loggedInUserInfo !== null ? loggedInUserInfo : appointment_Status_opt);
    const checkedCount = appointmentStatus?.filter(item => item.is_checked).length;

    var UserData = myUser
    let isPovEdit = myUser?.is_user ? (myUser?.permission_access?.op?.is_pov_editable || false) : true;

    const columns = UserData?.module_name !== 'nurse' ? [
      {
        field: 'patientDetails', headerName: t('Patient Details'), headerClassName: 'eMed_Apt_TblHead', flex: 0.170,
        renderCell: (params) => (
          <NewCommonPatientDetails data={params.row} showTag={true} fromOp={true} />
        ), density: 'compact'
      },
      {
        field: 'token_number', headerName: t('AppointmentDetails'), headerClassName: 'eMed_Apt_TblHead', flex: 0.140, density: 'compact',
        renderCell: (params) => {
          let priorityLvl = params?.row?.appointment_type_priority ? params?.row?.appointment_type_priority : ''
          let bgColor = priorityLvl === "Priority_Level_1" ? "#1E972A" : priorityLvl === "Priority_Level_2" ? "#0461B7" : priorityLvl === "Priority_Level_3" ? "#DE7D24" : ''
          return (
          <div>
            <div className='eMed_OP_PatientType'> <Typography id='eMed_OP_AptTbl_AptText' sx={{ fontWeight: '600', color : bgColor ? 'white' : 'black', backgroundColor: bgColor, padding: bgColor ? '0.2vw' : '0', borderRadius: bgColor ? '0.4vw' : '0'}}>{'Token :' + (params.row.token_number ? params.row.token_number : '-')}</Typography> <div>{this.AppointmentTypeIcon(params.row.is_transfered ? "transfer" : params.row.patient_appointment_types)}</div></div>
            <div className='eMed_OP_AptTbl_Comment'>
              {(params.row.waiting_comment.length > 0 && params.row.waiting_comment[params.row.waiting_comment.length - 1].comments) ? (params.row.waiting_comment[params.row.waiting_comment.length - 1].comments).length > 14 ?
                <Tooltip title={params.row.waiting_comment[params.row.waiting_comment.length - 1].comments} placement='top' arrow>
                  <Typography id='eMed_OP_AptTbl_AptText1'>{'WC : ' + params.row.waiting_comment[params.row.waiting_comment.length - 1].comments.slice(0, 12) + '...'}</Typography>
                </Tooltip> : <Typography id='eMed_OP_AptTbl_AptText1'>{'WC : ' + params.row.waiting_comment[params.row.waiting_comment.length - 1].comments}</Typography>
                : null
              }
              <Tooltip title={'Add Comments'} placement='top' arrow>
                <div emed_tid='op_home_addComments_btn' onClick={() => { this.setState({ waitingCmts: true, SelectedPatientDetails: params.row }) }} onMouseOver={() => { this.setState({ onAddComment: true }) }} onMouseLeave={() => { this.setState({ onAddComment: false }) }}>{(params.row.appointment_status === "Cancelled" || params.row.appointment_status === "No Show") ? null : <img className='eMed_OP_Patient_Iconcircle' src={ImagePaths.Comments.default} alt='Comments' />}</div>
              </Tooltip>
              {this.state.waitingCmts ? this.renderWaitingCmt(params.row) : null}
            </div>
            <div>{params.row.doctor_name?.length > 12 ?
              <Tooltip title={params.row.doctor_name} placement={'top'} arrow>
                <Typography id='eMed_OP_AptTbl_AptText'>{'Consultant :' + (params.row.doctor_name ? params.row.doctor_name.slice(0, 12) + '...' : '-')}</Typography>
              </Tooltip> : <Typography id='eMed_OP_AptTbl_AptText'>{(params.row?.is_nurse) ? 'Nurse :' + (params.row.nurse_name ? params.row.nurse_name : '-') : 'Consultant :' + (params.row.doctor_name ? params.row.doctor_name : '-')}</Typography>} </div>

          </div>)}, valueGetter: (params) => params.row.token_number, sortComparator: this.numberSort, sortingOrder: ['asc', 'desc']
      },
      {
        field: 'appointment_start_time', headerName: t('Arriving/WaitingTime'), headerClassName: 'eMed_Apt_TblHead', flex: 0.136, density: 'compact',
        renderCell: (params) => (
          <div>
            {this.waitTime(params.row, params.row.patient_id)}
            <Typography id='eMed_OP_AptTbl_AptText1'>{`Booked: ${params.row.appointment_start_time}`}</Typography>
          </div>
        )
      },
      {
        field: 'bill_amount', headerName: t('PaymentStatus'), headerClassName: 'eMed_Apt_TblHead', flex: 0.125, density: 'compact',
        renderCell: (params) => (
          <div>{this.PaymentStatus(params.row?.bill_amount ? params.row?.bill_amount : 0, params.row?.received_amount ? params.row?.received_amount : 0)}</div>)
      },
      {
        field: 'purpose_of_visit', headerName: t('PurposeOfVisit'), headerClassName: 'eMed_Apt_TblHead', flex: 0.124, density: 'compact',
        renderCell: (params) => (
          <Box width={"100%"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            {params.row.purpose_of_visit?.length > 16 ?
              <Tooltip title={params.row.purpose_of_visit} placement='top' arrow>
                <Typography>{params.row.purpose_of_visit.slice(0, 15) + '...'}</Typography>
              </Tooltip> : <Typography>{params.row.purpose_of_visit ? params.row.purpose_of_visit : "-"}</Typography>
            }
            {(isPovEdit && params.row.appointment_status !== "Cancelled" && params.row.appointment_status !== "Completed") ?
             <Tooltip title={'Edit'} placement='top' arrow>
              <div emed_tid={"fo_pov_edit"} size="small"
                onClick={() => this.setState({
                  povText: params.row,
                  ispovPopOpen: true,
                })}
                onMouseOver={() => { this.setState({ onChangePov: true }) }}
                onMouseLeave={() => { this.setState({ onChangePov: false }) }}>
                <img src={ImagePaths.UserEditOn.default} className='eMed_list_image' alt='edit' />
              </div>
              </Tooltip> : null}
          </Box>)
      },
      {
        field: 'appointment_status', headerName: t('status'), headerClassName: 'eMed_Apt_TblHead', flex: checkedCount > 5 && checkedCount <= 8 ? 0.24 : 0.15, density: 'compact',
        renderCell: ({row}) => (
          <Box display={'flex'} gap={'0.3vw'}>
            <div>{this.StatusBtns(row, row.appointment_id)}</div>
            {row?.appointment_change_status ? <Tooltip title={`Request for ${row?.appointment_change_status}` + (row?.appointment_change_status === "Reschedule" ? ` - Date: ${row?.appointment_changed_date ? row?.appointment_changed_date?.slice(0, 10)?.split('-')?.reverse()?.join('-') : '-'}`: row?.appointment_change_status === "Cancelled" ? ` - Reason: ${row?.reason_for_change}` : '')} placement='top' arrow><IconButton><img src={ImagePaths.Info.default} alt='Info' style={{ width: "1.2vw", height: "1.2vw" }} /></IconButton></Tooltip> : null}
          </Box>
        )
      },
      {
        field: 'action', headerName: t('Action'), headerClassName: 'eMed_Apt_TblHead', flex: 0.09, headerAlign: 'center', density: 'compact', sortable: false, hideable: false,
        renderCell: (params) => (
          <div>{this.ActionBtn(params)}</div>
        )
      }
    ] : [
      {
        field: 'patientDetails', headerName: t('Patient Details'), headerClassName: 'eMed_Apt_TblHead', flex: 0.170,
        renderCell: (params) => (
          <CommonPatientDetails data={params.row} showTag={true} fromOp={true} />
        ), density: 'compact'
      },
      {
        field: 'token_number', headerName: t('AppointmentDetails'), headerClassName: 'eMed_Apt_TblHead', flex: 0.140, density: 'compact',
        renderCell: (params) => {
          let priorityLvl = params?.row?.appointment_type_priority ? params?.row?.appointment_type_priority : ''
          let bgColor = priorityLvl === "Priority_Level_1" ? "#1E972A" : priorityLvl === "Priority_Level_2" ? "#0461B7" : priorityLvl === "Priority_Level_3" ? "#DE7D24" : ''
          return(
          <div>
            <div className='eMed_OP_PatientType' style={{  backgroundColor: bgColor, justifyContent: bgColor ? 'center' : ''}}> <Typography id='eMed_OP_AptTbl_AptText' sx={{ fontWeight: '600' }}>{'Token :' + (params.row.token_number ? params.row.token_number : '-')}</Typography> <div>{this.AppointmentTypeIcon(params.row.appointment_patient_type === "transfer" ? params.row.appointment_patient_type : params.row.patient_appointment_types)}</div></div>
            <div className='eMed_OP_AptTbl_Comment'>
              {(params.row.waiting_comment.length > 0 && params.row.waiting_comment[params.row.waiting_comment.length - 1].comments) ? (params.row.waiting_comment[params.row.waiting_comment.length - 1].comments).length > 14 ?
                <Tooltip title={params.row.waiting_comment[params.row.waiting_comment.length - 1].comments} placement='top' arrow>
                  <Typography id='eMed_OP_AptTbl_AptText1'>{'WC : ' + params.row.waiting_comment[params.row.waiting_comment.length - 1].comments.slice(0, 12) + '...'}</Typography>
                </Tooltip> : <Typography id='eMed_OP_AptTbl_AptText1'>{'WC : ' + params.row.waiting_comment[params.row.waiting_comment.length - 1].comments}</Typography>
                : <Typography id='eMed_OP_AptTbl_AptText1'>{"WC :  - "}</Typography>
              }
              <Tooltip title={'Add Comments'} placement='top' arrow>
                <div emed_tid='op_nurse_addComments_btn' onClick={() => { this.setState({ waitingCmts: true, SelectedPatientDetails: params.row }) }} onMouseOver={() => { this.setState({ onAddComment: true }) }} onMouseLeave={() => { this.setState({ onAddComment: false }) }}>{(params.row.appointment_status === "Cancelled" || params.row.appointment_status === "No Show") ? null : <img className='eMed_OP_Patient_Iconcircle' src={ImagePaths.Comments.default} alt='Comments' />}</div>
              </Tooltip>
              {this.state.waitingCmts ? this.renderWaitingCmt(params.row) : null}
            </div>
            <div>{params.row.doctor_name?.length > 12 ?
              <Tooltip title={params.row.doctor_name} placement={'top'} arrow>
                <Typography id='eMed_OP_AptTbl_AptText'>{'Consultant :' + (params.row.doctor_name ? params.row.doctor_name.slice(0, 12) + '...' : '-')}</Typography>
              </Tooltip> : <Typography id='eMed_OP_AptTbl_AptText'>{'Consultant :' + (params.row.doctor_name ? params.row.doctor_name : '-')}</Typography>} </div>

          </div>)}, valueGetter: (params) => params.row.token_number, sortComparator: this.numberSort, sortingOrder: ['asc', 'desc']
      },
      {
        field: 'appointment_start_time', headerName: t('Arriving/WaitingTime'), headerClassName: 'eMed_Apt_TblHead', flex: 0.136, density: 'compact',
        renderCell: (params) => (
          <div>
            {this.waitTime(params.row, params.row.patient_id)}
            <Typography id='eMed_OP_AptTbl_AptText1' fontWeight={'bold'}>{`Booked: ${params.row.appointment_start_time}`}</Typography>
          </div>
        )
      },
      {
        field: 'bill_amount', headerName: t('PaymentStatus'), headerClassName: 'eMed_Apt_TblHead', flex: 0.125, density: 'compact',
        renderCell: (params) => (
          <div>{this.PaymentStatus(params.row?.bill_amount ? params.row?.bill_amount : 0, params.row?.received_amount ? params.row?.received_amount : 0)}</div>)
      },
      {
        field: 'purpose_of_visit', headerName: t('PurposeOfVisit'), headerClassName: 'eMed_Apt_TblHead', flex: 0.124, density: 'compact',
        renderCell: (params) => (
          <div>
            {params.row.purpose_of_visit?.length > 18 ?
              <Tooltip title={params.row.purpose_of_visit} placement='top' arrow>
                <Typography>{params.row.purpose_of_visit.slice(0, 18) + '...'}</Typography>
              </Tooltip> : <Typography>{params.row.purpose_of_visit ? params.row.purpose_of_visit : "-"}</Typography>
            }
          </div>)
      },
      {
        field: 'appointment_status', headerName: t('status'), headerClassName: 'eMed_Apt_TblHead', flex: checkedCount > 5 && checkedCount <= 8 ? 0.24 : 0.15, density: 'compact',
        renderCell: (params) => (
          <div>{this.StatusBtns(params.row, params.row.appointment_id)}</div>
        )
      },
      // {
      //   field: 'action', headerName: t('Action'), headerClassName: 'eMed_Apt_TblHead', flex: 0.107, headerAlign: 'center', density: 'compact', sortable: false, hideable: false,
      //   renderCell: (params) => (
      //     <div>{this.ActionBtn(params)}</div>
      //   )
      // }
    ]
    this.state.AptTblData.forEach((element, index) => { element.sno = index + 1 })
    return (

      <div>
        <Box className='eMed_AptTbl_Con'>
          <DataGrid
            rows={this.state.AptTblData}
            columns={columns}
            getRowId={(row) => row['sno']}
            pageSize={this.state.pageSize}
            page={this.state.page}
            rowsPerPageOptions={[10, 20, 30, 50]}
            components={{
              Toolbar: CommonGridHeader.CommonToolBar,
              NoRowsOverlay: () => (
                <Stack className='eMed_conf_nodata'>
                  {t("NoRecordsFound")}
                </Stack>

              )
            }}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            density={'compact'}
            rowHeight={100}
            onCellClick={(params) => (params.row.appointment_status === "Cancelled" || params.row.appointment_status === "No Show") ? null : this.routeToDemograhics(params)}
            pagination
            getRowHeight={(params) => {
              if (this.state.isOPT && params?.model?.appointment_status === 'Optometry') {
                if (params?.model?.dilation_details && params?.model?.dilation_details?.length > 1) {
                  return 185
                } else {
                  return 140
                }
              } else {
                return 80
              }
            }}
            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
            onPageSizeChange={(newPageSize) => { this.setState({ pageSize: newPageSize }) }}
            disableSelectionOnClick
            loading={this.state.isLoader}
            getRowClassName={(params) => {
              if (params.row.appointment_status === "Cancelled" || params.row.appointment_status === "No Show") {
                return 'eMed_OP_CancelPatient'
              } else {
                return null
              }
            }}
          />
        </Box>
      </div>
    )
  }
  NxtAppiontmentClose() {
    setTimeout(() => { this.setState({ selectedOption: "" }) }, 1000)
  }
  NxtAppointmentSuccess(message) {
    this.setState({ selectedOption: "" }, () => {
      this.successMsgFunc(message)
      this.getPatientDetails()
    })
  }
  renderNxtApointmtPopup = () => {
    if (this.state.selectedOption === "NextAppointment") {
      return <NextAppointments
        PostSuccess={this.NxtAppointmentSuccess.bind(this)}
        NxtAppiontmentClose={this.NxtAppiontmentClose.bind(this)}
        ScreenName={"NextAppointment"}
        PatientDetails={this.state.SelectedPatientDetails} />
    }
    else if (this.state.selectedOption === "Reschedule") {
      return <NextAppointments
        PostSuccess={this.NxtAppointmentSuccess.bind(this)}
        NxtAppiontmentClose={this.NxtAppiontmentClose.bind(this)}
        ScreenName={"RescheduleAppiontment"}
        PatientDetails={this.state.SelectedPatientDetails} />
    }
    else if (this.state.selectedOption === "PrintDoctorNotes") {
      this.getPatientDoctorNotesPrint()
    }
    else if (this.state.selectedOption === "PrintPrescription") {
      this.getPatientPrescriptionPrint()
    }
    else if (this.state.selectedOption === "CustomizedPrint") {
      this.getCustomizedCardPrint()
    }
    else if (this.state.selectedOption === "OpBarcode") {
      this.getPatientOpCardPrint(false)
    }
    else if (this.state.selectedOption === "OpCard") {
      this.getPatientOpCardPrint(true)

    }
    else if (this.state.selectedOption === "BillDetails") {
      this.getPatientOpBillDetailPrint()
    }
    else if (this.state.selectedOption === "SummaryBill") {
      this.getPatientOpSummaryBillPrint()
    }
    else if (this.state.selectedOption === "PrescriptionTemplate") {
      this.getPatientPresTemplate()
    }
    else if (this.state.selectedOption === "CommonBarcode") {
      this.getCommonBarCodePrint()
    }
    else if (this.state.selectedOption === "MedicalRecord") {
      this.getMedicalRecordPrint()
    }
    else if (this.state.selectedOption === "CancelAppontment") {
      this.setState({ cancelAppointment: true, selectedOption: "" })
    }
    else if (this.state.selectedOption === 'ChangeTag') {
      this.postChangeTagNormal()
    }
  }

  postChangeTagNormal = () => {
    try {
      let data = {
        appointment_id: this.state.SelectedPatientDetails?.appointment_id,
        is_emergency: false
      }
      RestAPIService.create(data, Serviceurls.FO_IP_OP_PATIENT_TAG_CHANGE_NORMAL)
        .then((response) => {
          if (response?.data?.status === 'Success') {
            this.successMsgFunc(response.data.message)
            this.getPatientDetails()
            this.setState({ selectedOption: "" })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ selectedOption: "" })
            this.errorMsgFunc(error.response.data.message)
          } else {
            this.errorMsgFunc(error.message)
            this.setState({ selectedOption: "" })
          }
        })
    } catch (error) {
      this.setState({ selectedOption: "" })
      this.errorMsgFunc(error.message)
    }
  }

  popupClose = () => {
    this.setState({
      waitingCmts: false,
      cancelAppointment: false
    })
  }
  addCancelComments = (comments) => {
    var states = this.state
    this.postPatientStatus('Cancelled', states.SelectedPatientDetails.appointment_id, comments)
    this.setState({
      cancelAppointment: false
    })
  }
  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }
  errorMsg() {
    this.setState({
      errormsg: this.props.error
    })
  }
  
ReceiveClosePopUp() {
  this.setState({OpPopupId : []})
}

  closeFilter = () => {
    this.setState({ FilterOpen: false })
  }

  ClearFilterData = () => {
    this.setState({
      Rescheduled: false,
      Cancelled: false,
      Transferred: false,
    })
  }

  renderFilterScreen = () => {
    const { t } = this.props
    return (
      <Box component={"div"} className='eMed_rts_filter_container'>
        <Box component={"div"} className='eMed_filter_Top'>
          <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
          <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn' emed_tid='opHome_filter_close_btn'>
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </Box>
        <Box className="eMed_Filter_Header">
          <Typography marginLeft={"1vw"}>{t("Patient Request")}</Typography>
        </Box>
        <Box className='eMed_rts_middle' display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
          <Box>
            <Box display={'flex'}>
              <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox sx={{ fontSize: '0.4vw' }} checked={this.state.Rescheduled} onChange={(e) => { this.setState({ Rescheduled: e.target.checked }) }} size='small' emed_tid='opHome_filter_rescheduled_checkBox' />} label={t("Rescheduled")} />
              <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox sx={{ fontSize: '0.4vw' }} checked={this.state.Cancelled} onChange={(e) => { this.setState({ Cancelled: e.target.checked }) }} size='small' emed_tid='opHome_filter_cancelled_checkBox' />} label={t("Cancelled")} />
              <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox sx={{ fontSize: '0.4vw' }} checked={this.state.Transferred} onChange={(e) => { this.setState({ Transferred: e.target.checked }) }} size='small' emed_tid='opHome_filter_transferred_checkBox' />} label={t("Transferred")} />
            </Box>
          </Box>

          <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
            <Button variant='outlined' size="small" emed_tid='opHome_filter_clear_btn'
              onClick={() => { this.ClearFilterData() }}
              id="eMed_Config_btn">{t("Clear")}</Button>
            <Button variant='contained' size="small" emed_tid='opHome_filter_search_btn' disabled={this.state.disableBtn}
              onClick={() => {
                this.getPatientDetails()
                this.setState({
                  FilterOpen: false
                })
              }}
              id="eMed_Config_btn">{t("Search")}</Button>
          </Stack>
        </Box>
      </Box>
    )
  }

  render() {  
     return (
      <div className='eMed_OP_Appointment'>
        {this.renderOPHeader()}
        {this.renderPatientType()}
        {this.renderAptTable()}
        {this.state.selectedOption != "" ? this.renderNxtApointmtPopup() : null}

        {this.state.selectedOption === "TransAppoint" ?
          <NextAppointments
            PostSuccess={this.NxtAppointmentSuccess.bind(this)}
            NxtAppiontmentClose={this.NxtAppiontmentClose.bind(this)}
            getPatientDetails={this.getPatientDetails.bind(this)}
            ScreenName={"TransAppoint"}
            PatientDetails={this.state.SelectedPatientDetails}
            AppointmentDate={this.state.datePicker}
            disableDatePicker={true}
            AppointmentType={true} /> : null

        }

        {this.state.cancelAppointment ? <ReasonPopup
          title={"Are you sure? Do you want to cancel the appointment"}
          AlertPopupClose={this.popupClose.bind(this)}
          label={"Enter the Reason"}
          btntext={'Cancel'}
          btnvarient={'outlined'}
          btncolor={'error'}
          btntext1={'Confirm'}
          btnvarient1={'contained'}
          sendCmt={this.addCancelComments.bind(this)} /> : null}
        {this.state.creditBillPopUp ?
          <CreditBillCompletePoP
            Module_ID_Key_Name={"appointment_id"}
            Module_ID_Key_Value={this.state.opCreditBill?.appointment_id}
            modules={['op', 'lab', 'pharmacy', 'ot', 'radiology']}
            CrebillPosted={this.creditBillPost}
            BillData={this.state.PatientDetails}
            ClosePopUp={this.ClosePopUp.bind(this)}
            CollectedIn={"OP"}
            FromFrontOffice={true}
          /> : null}
           {this.state.OpPopupId?.length > 0 ?
            <BillSuccessPoPUp isFromOPHome={true}   BilllistArray= {this.state.OpPopupId} CloseBtnText={'close'} ClosePopUp={this.ReceiveClosePopUp.bind(this)}   error={this.errorMsgFunc.bind(this)}/> : null}
             
         <Drawer
           anchor={'right'}
           open={this.state.FilterOpen}
           ModalProps={{ onBackdropClick: this.closeFilter }}
         >
           {this.renderFilterScreen()}
         </Drawer>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.receiptID !== null ?
          <BillSuccessPoPUp creditreceipt={this.state.receiptID} phrma_creditreceipt={this.state.pharmacReceipt} ClosePopUp={this.ClosesuccessPopUp.bind(this)} />
          : null}

         {this.state.ispovPopOpen ?
           <PovEditPopup
             povEditText={this.state.povText.purpose_of_visit}
             povData={this.state.povText}
             getTableData={this.getPatientDetails.bind(this)}
             AlertPopupClose={this.povPopClose.bind(this)}
             successMsg={this.successMsgFunc.bind(this)}
             errorMsg={this.errorMsgFunc.bind(this)}
           /> : null}
      </div>

    )
  }
}

export default withTranslation()(OutpatientHome);