import React, { Component } from 'react'
import { ImagePaths } from '../../../Utility/ImagePaths'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, Divider, FormControlLabel, IconButton, InputAdornment, Modal, OutlinedInput, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import { withTranslation } from 'react-i18next'
import { Colors } from '../../../Styles/Colors';
import { CommonPatientCard, CommonPatientDetails } from '../../../Components/Common Components/CommonComponents';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import Loader from '../../../Components/Loader';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';
import { AmountFormat, convertDateTime } from '../../../Components/CommonFunctions/CommonFunctions';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';

class CreditBillCompletePoP extends Component {
    constructor(props) {
        super(props)
        var LoggedUserData = JSON.parse(localGetItem("loggedInUserInfo"))
        this.state = {
            ShowOpBills: this.props?.modules?.includes('op') ? true : false,
            ShowIpBills: this.props?.modules?.includes('ip') ? true : false,
            ShowPharmaBills: (this.props?.modules?.includes('pharmacy') && localGetItem("multiPharmacy") === "false" && (localGetItem("Allow_Pharma_CreditBill_In_FO") === "true" || LoggedUserData?.module_name === "Pharmacy")) ? true : false,
            ShowLabBills: (this.props?.modules?.includes('lab') && localGetItem("multiLaboratory") === "false") ? true : false,
            AllBillDataList: [],
            BillDataList: [],
            TotalPayableAmount: 0,
            PayableAmount: '',
            isCash: false,
            isCard: false,
            isUPI: false,
            isCheque: false,
            isBankTranfer: false,
            CashAmount: "",
            CardAmount: "",
            CardTransactionNumber: "",
            UPINumber: "",
            UPIAmount: "",
            BankTfrAmount: "",
            BankTfrNumber: "",
            ChequeNumber: "",
            ChequeAmount: "",
            TwoThounsandCounts: "",
            FiftyCounts: "",
            FiveHundredCounts: "",
            TwentyCounts: "",
            TwoHundredCounts: "",
            TenCounts: "",
            HundredCounts: "",
            CoinsCounts: "",
            FiveCount: "",
            PayFromAdvance: false,
            AdvanceAmount: 0,
            ShowRadiologyBills: (this.props?.modules?.includes('radiology') && (localGetItem("Show_Radiology_Bills_In_FO") === "true" || LoggedUserData?.module_name === "Radiology")) ? true : false,
            ShowOtBills: this.props?.modules?.includes('ot') ? true : false,
            isFromCheckOut: this.props?.isFromCheckOut ? true : false,
            disable: false,
            ShowCanteenBills: this.props?.modules?.includes('Canteen') ? true : false,
            PharmacyData: [],
            LabData: []
        }
    }
    componentDidMount() {
        var LoggedUserData = JSON.parse(localGetItem("loggedInUserInfo"))
        this.setState({
            PharmacyData: this.props?.FromFrontOffice ? JSON.parse(JSON.stringify((LoggedUserData?.pharmacy_data))) : [],
            LabData: this.props?.FromFrontOffice ? JSON.parse(JSON.stringify((LoggedUserData?.laboratory_data))) : []
        }, () => {
            this.GetBillDataList()
            this.CorporateDetailsGet()
        })
    }

    GetBillDataList = () => {
        try {
            this.LoaderFunction(true)
            var LoggedUserData = JSON.parse(localGetItem("loggedInUserInfo"))
            let params = ''
            let labId = []
            let pharmacyId = []
            if (this.props?.FromFrontOffice) {
                LoggedUserData?.laboratory_data?.map((item) => {
                    labId.push(item?.laboratory_id)
                })
                LoggedUserData?.pharmacy_data?.map((item) => {
                    pharmacyId.push(item?.pharmacy_id)
                })
            } else {
                labId.push(LoggedUserData?.laboratory_id)
                pharmacyId.push(LoggedUserData?.pharmacy_id)
            }

            if (this.props?.Module_ID_Key_Name_Two && this.props?.Module_ID_Key_Value_Two) {
                params = `?${this.props?.Module_ID_Key_Name}=${this.props?.Module_ID_Key_Value}` +
                    `&${this.props.Module_ID_Key_Name_Two}=${this.props?.Module_ID_Key_Value_Two}` +
                    '&module=' + this.props?.modules +
                    '&laboratory_id=' + labId +
                    '&pharmacy_id=' + pharmacyId +
                    // `${this.props?.Bill_Id ? `&bill_id=${this.props?.Bill_Id}` : ""}` +
                    '&clinic_id=' + (LoggedUserData?.clinic_id ? LoggedUserData?.clinic_id : null)
            } else {
                params = `?${this.props?.Module_ID_Key_Name}=${this.props?.Module_ID_Key_Value}` +
                    '&module=' + this.props?.modules +
                    '&laboratory_id=' + labId +
                    '&pharmacy_id=' + pharmacyId +
                    // `${this.props?.Bill_Id ? `&bill_id=${this.props?.Bill_Id}` : ""}` +
                    '&clinic_id=' + (LoggedUserData?.clinic_id ? LoggedUserData?.clinic_id : null)
            }

            RestAPIService.getAll(
                Serviceurls.FO_CREDIT_BILLS_GET + params).
                then((response) => {
                    if (response.data.status === "success") {
                        let Data = []
                        if (response?.data?.data?.length > 0) {
                            response?.data?.data?.forEach((item) => {
                                item.is_checked = this.props?.Bill_Id === item?.id ? true : false
                                item.deduct_amount = 0
                                Data.push(item)
                                if (this.props?.Bill_Id || (this.props.from === "admission_wise" && (this.props?.BillData?.bill_types === "Pharmacy" || this.props?.BillData?.bill_types  === "Laboratory"))) {
                                    if (this.props?.BillData?.patient_bill_type === "Laboratory" || this.props?.BillData?.bill_types  === "Laboratory") {
                                        let LabIndex = this.state.LabData.findIndex((lab) => lab?.laboratory_id === this.props?.BillData?.laboratory_id)
                                        this.state["LabData"][LabIndex]["isChecked"] = true
                                        this.state?.LabData?.forEach((list, i) => {
                                            if (i !== LabIndex) {
                                                list["disableCheck"] = true
                                            } else {
                                                list["disableCheck"] = false
                                            }
                                        })
                                    } else if (this.props?.BillData?.patient_bill_type === "Pharmacy" || this.props?.BillData?.bill_types  === "Pharmacy") {
                                        let PharmaIndex = this.state.PharmacyData.findIndex((ph) => ph?.pharmacy_id === this.props?.BillData?.pharmacy_id)
                                        this.state["PharmacyData"][PharmaIndex]["isChecked"] = true
                                        this.state?.PharmacyData?.forEach((list, i) => {
                                            if (i !== PharmaIndex) {
                                                list["disableCheck"] = true
                                            } else {
                                                list["disableCheck"] = false
                                            }
                                        })
                                    }
                                }
                            })
                        }
                        this.setState({
                            AllBillDataList: Data,
                            PharmacyData : this.state.PharmacyData,
                            LabData : this.state.LabData,
                            AdvanceAmount: (LoggedUserData?.module_name === "front_office" && response?.data?.advance_amount) ? response?.data?.advance_amount : 0
                        }, () => {
                            this.FilterBillList()
                            this.LoaderFunction(false)
                        })
                    }
                    else {
                        this.LoaderFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    CorporateDetailsGet = () => {
        let ApiUrl = null
        if (this.props?.Module_ID_Key_Name === "appointment_id") {
            ApiUrl = Serviceurls.GET_CORPORATE_CLAIM_DETAILS + '?appointment_id=' + this.props?.Module_ID_Key_Value
        } else if (this.props?.POST_Module_ID_Key_Name === "appointment_id") {
            ApiUrl = Serviceurls.GET_CORPORATE_CLAIM_DETAILS + '?appointment_id=' + this.props?.POST_Module_ID_Key_Value
        } else if (this.props?.Module_ID_Key_Name === "ip_admission_id") {
            ApiUrl = Serviceurls.GET_CORPORATE_CLAIM_DETAILS + '?ip_admission_id=' + this.props?.Module_ID_Key_Value
        } else if (this.props?.POST_Module_ID_Key_Name === "ip_admission_id") {
            ApiUrl = Serviceurls.GET_CORPORATE_CLAIM_DETAILS + '?ip_admission_id=' + this.props?.POST_Module_ID_Key_Value
        } else {
            ApiUrl = null
        }
        if (ApiUrl) {
            var LoggedUserData = JSON.parse(localGetItem("loggedInUserInfo"))
            RestAPIService.getAll(ApiUrl + '&clinic_id=' + (LoggedUserData?.clinic_id ? LoggedUserData?.clinic_id : null))
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            CreditDetails: response.data.data ? response.data.data : {}
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        }
    }

    printCreditBills = (billData) => {
        try {
            let url = ''
            let lab_id = billData?.laboratory_id ? billData?.laboratory_id : null
            if (billData?.module === "OT") {
                url = Serviceurls.GET_PRINT_PREVIEW + `?export=pdf&id=` + billData?.id
            }
            else if (billData?.module === "In Patient") {
                url = Serviceurls.IP_INVOICE_PRINT + `?export=pdf&ip_bill_summary_id=` + billData?.id
            }
            else if (billData?.module === "Out Patient") {
                url = Serviceurls.OP_INVOICE_PRINT + "?export=pdf&invoce_id=" + billData?.id
            }
            else if (billData?.module === "Pharmacy") {
                url = Serviceurls.PHARMACY_INVOICE_PRINT + "?id=" + billData?.id + "&export=pdf" + "&pharmacy_id=" + (billData?.pharmacy_id ? billData?.pharmacy_id : "")
            }
            else if (billData?.module === "Laboratory") {
                url = Serviceurls.LAB_CREATE_BILL_COMPLETED_PRINT + "?bill_summary_id=" + billData?.id + "&export_type=pdf" + "&laboratory_id=" + lab_id
            }
            else if (billData?.module === "Radiology") {
                url = Serviceurls.RAD_BILL_POST + '?bill_summary_id=' + billData?.id + "&export_type=pdf"
            }
            else if(billData?.billing_status){
                url = Serviceurls.PHARMA_BILLS_GET + "?id=" + billData?.id + "&export=pdf" + "&pharmacy_id=" + (billData?.pharmacy_id ? billData?.pharmacy_id : "")
            }
            RestAPIService.getAllPrint(url).
                then((response) => {
                    if (response) {
                        //Create a Blob from the PDF Stream
                        const file = new Blob(
                            [response.data],
                            { type: 'application/pdf' });
                        //Build a URL from the file
                        const fileURL = URL.createObjectURL(file);
                        //Open the URL on new Window
                        window.open(fileURL);
                    }
                }).catch((error) => {
                    console.log(error.message)
                })
        } catch (e) {
            console.log(e.message)
        }
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message,
            disable : false
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    FilterBillList = () => {
        let AllBillList = JSON.stringify(this.state.AllBillDataList)
        let BillList = []
        JSON.parse(AllBillList).forEach((item) => {
            if (item?.module === "Out Patient" && this.state.ShowOpBills) {
                BillList.push(item)
            }
            else if (item?.module === "In Patient" && this.state.ShowIpBills) {
                BillList.push(item)
            }
            else if (item?.module === "Pharmacy"){
                if (this.state.ShowPharmaBills) {
                    BillList.push(item)
                } else {
                    this.state.PharmacyData?.map((list) => {
                        if (list?.isChecked && list?.pharmacy_id === item?.pharmacy_id) {
                            BillList.push(item)
                        }
                    })
                } 
            }
            else if (item?.module === "Laboratory") {
                if (this.state.ShowLabBills) {
                    BillList.push(item)
                } else {
                    this.state.LabData?.map((list) => {
                        if (list?.isChecked && list?.laboratory_id === item?.laboratory_id) {
                            BillList.push(item)
                        }
                    })
                }
            }
            else if (item?.module === "Radiology" && this.state.ShowRadiologyBills) {
                BillList.push(item)
            }
            else if (item?.module === "OT" && this.state.ShowOtBills) {
                BillList.push(item)
            }
            else if (item?.module === "Canteen" && this.state.ShowCanteenBills) {
                BillList.push(item)
            }
        })
        if (BillList?.length === 1) {
            BillList[0].is_checked = true
        }
        if (this.state.isFromCheckOut) {
            BillList.forEach((list, index) => (
                BillList[index].is_checked = true
            ))
        }
        this.setState({ BillDataList: BillList, PayableAmount: '', PayFromAdvance: false }, () => {
            this.ClearPaymentDetails()
            this.CalculateTotalPayAmt(this.state.isFromCheckOut)
            this.CalculateDeductAmt()
        })
    }
    handleCheckBox = (e, key, index = null) => {
        var states = this.state
        if (key === "bill_Check") {
            if (index !== null) {
                states.PayableAmount = ""
                states.PayFromAdvance = false
                states.BillDataList[index].is_checked = e.target.checked
                states.BillDataList[index].deduct_amount = e.target.checked ? +states.BillDataList[index].deduct_amount : 0
            } else {
                states.PayableAmount = ""
                states.PayFromAdvance = false
                states.BillDataList.map((item) => (
                    item.is_checked = e.target.checked,
                    item.deduct_amount = e.target.checked ? +item.deduct_amount : 0
                ))
            }
        } else if (key === "PharmacyData") {
            states["PharmacyData"][index]["isChecked"] = e.target.checked
            states?.PharmacyData?.forEach((list, i) => {
                if (i !== index && e.target.checked) {
                    list["disableCheck"] = true
                } else {
                    list["disableCheck"] = false
                }
            })
        } else if (key === "LabData") {
            states["LabData"][index]["isChecked"] = e.target.checked
            states?.LabData?.forEach((list, i) => {
                if (i !== index && e.target.checked) {
                    list["disableCheck"] = true
                } else {
                    list["disableCheck"] = false
                }
            })
        } else {
            states[key] = e.target.checked
        }
        this.setState({ states }, () => {
            if (key === "bill_Check") {
                this.CalculateTotalPayAmt()
                this.CalculateDeductAmt()
            } else if (key === "ShowOpBills" || key === "ShowIpBills" || key === "ShowPharmaBills" || key === "ShowLabBills" || key === "ShowRadiologyBills" || key === "ShowOtBills" || key === "PharmacyData" || key === "LabData") {
                this.FilterBillList()
            } else {
                if (!states.isCash) { this.setState({ CashAmount: "" }) }
                if (!states.isCard) { this.setState({ CardAmount: "", CardNumber: "" }) }
                if (!states.isUPI) { this.setState({ UPIAmount: "", UPINumber: "" }) }
                if (!states.isCheque) { this.setState({ ChequeAmount: "", ChequeNumber: "" }) }
                if (!states.isBankTranfer) { this.setState({ BankTfrAmount: "", BankTfrNumber: "" }) }
                if (states.isCash && !states.isCard && !states.isUPI && !states.isBankTranfer) {
                    this.setState({ CashAmount: this.state.PayableAmount })  // Fetch Full amount in Cash Amount, when only the cash is selected
                }
            }
        })
    }

    CalculateTotalPayAmt = (isFromCheckOut = false) => {
        try {
            let Amount = 0
            this.state.BillDataList.forEach((item) => {
                if (item?.is_checked) {
                    Amount += item?.pending_amount
                }
            })
            this.setState({ TotalPayableAmount: Amount }, () => {
                if (isFromCheckOut && this.state.AdvanceAmount) {
                    if (localGetItem("default_PayFromAdvance_On_Checkout") === "true") {
                        if (this.state.AdvanceAmount < this.state.TotalPayableAmount) {
                            this.setState({ PayableAmount: this.state.AdvanceAmount, PayFromAdvance: true }, () => { this.CalculateDeductAmt() })
                        } else {
                            this.setState({ PayableAmount: this.state.TotalPayableAmount, PayFromAdvance: true }, () => { this.CalculateDeductAmt() })
                        }
                    } else {
                        this.setState({ PayableAmount: this.state.TotalPayableAmount }, () => { this.CalculateDeductAmt() })
                    }
                } else {
                    this.setState({ PayableAmount: this.state.TotalPayableAmount }, () => { this.CalculateDeductAmt() })
                }
            })
        }
        catch (e) {

        }
    }

    renderCountsText = (Amount, Key, Right = false) => {
        var states = this.state
        return (
            <Box component={"div"} xs={4} marginLeft={Right ? "1.2vw" : "1.2vw"} className="eMed_CrdPop_Denomi_invidual_div">
                <Typography className='eMed_countsAmount_Label'>{Amount}</Typography>
                <Typography>x</Typography>
                <TextField
                    placeholder="counts"
                    size='small'
                    emed_tid={Key}
                    inputProps={{ maxLength: 4 }}
                    className='eMed_crdPop_amountTextField'
                    variant='standard'
                    value={states[Key]}
                    onChange={(e) => {
                        let number = CommonValidation.numberOnly(e.target.value);
                        if (number || e.target.value === "") {
                            states[Key] = e.target.value
                            this.setState({
                                states
                            })
                        }
                    }}
                />
            </Box>
        )
    }

    renderAmountTextFields = (label, key, Icon) => {
        var states = this.state
        let { t } = this.props;
        return (
            <TextField
                sx={{ padding: 0, margin: 0, width: "10vw" }}
                className='eMed_CrdPop_Amount_TextFields'
                size='small'
                emed_tid={label}
                label={t(label) + "(₹)"}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <img src={Icon} alt={label} className='eMed_action_img_small' />
                        </InputAdornment>
                    ),
                }}
                inputProps={{ emed_tid: `${label}` }}
                value={states[key]}
                onChange={(e) => {
                    let number = CommonValidation.NumberWithDot(e.target.value);
                    if ((number && e.target.value > 0) || e.target.value === "") {
                        states[key] = e.target.value
                        this.setState({
                            states
                        })
                    }
                }}
            />
        )
    }

    renderNumberTextFields = (label, key, Right = false) => {
        var states = this.state
        let { t } = this.props;
        return (
            <TextField
                className='eMed_CrdPop_Amount_TextFields'
                sx={{ marginLeft: Right ? "1vw" : 0, width: '10vw' }}
                inputProps={{ maxLength: 20, emed_tid : `${label}` }}
                size='small'
                emed_tid={label}
                label={t(label)}
                value={states[key]}
                onChange={(e) => {
                    let number = CommonValidation.gstnumberValidation(e.target.value);
                    if (number || e.target.value === "") {
                        states[key] = e.target.value.toUpperCase()
                        this.setState({
                            states
                        })
                    }
                }}
            />
        )
    }

    returnTotal() {
        try {
            let Amount = 0
            let states = this.state
            if (states.TwoThounsandCounts != "") {
                Amount += +states.TwoThounsandCounts * 2000
            }
            if (states.FiveHundredCounts != "") {
                Amount += +states.FiveHundredCounts * 500
            }
            if (states.TwoHundredCounts != "") {
                Amount += +states.TwoHundredCounts * 200
            }
            if (states.HundredCounts != "") {
                Amount += +states.HundredCounts * 100
            }
            if (states.FiftyCounts != "") {
                Amount += +states.FiftyCounts * 50
            }
            if (states.TwentyCounts != "") {
                Amount += +states.TwentyCounts * 20
            }
            if (states.TenCounts != "") {
                Amount += +states.TenCounts * 10
            }
            if (states.FiveCount != "") {
                Amount += +states.FiveCount * 5
            }
            if (states.CoinsCounts != "") {
                Amount += +states.CoinsCounts * 1
            }
            return Amount
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    renderLeftTable = () => {
        let isAllBillChecked = this.state.BillDataList?.every((item) => (item.is_checked === true))
        return (
            <TableContainer sx={{ height: "65vh", width: '50vw', overflow: 'scroll' }}>
                <Table aria-label="simple table" stickyHeader>
                    <TableHead sx={{ backgroundColor: Colors.Background, height: '5vh' }}>
                        <TableRow sx={{ paddingY: "0.5vw" }}>
                            <TableCell sx={{ flex: 0.06 }} className='eMed_Credit_bill_pop_Tbl_Header' align="center"><Checkbox size='small' checked={isAllBillChecked} onChange={(e) => { this.handleCheckBox(e, "bill_Check") }} /></TableCell>
                            <TableCell sx={{ flex: 0.17 }} className='eMed_Credit_bill_pop_Tbl_Header' align="center">Bill Details</TableCell>
                            <TableCell sx={{ flex: 0.14 }} className='eMed_Credit_bill_pop_Tbl_Header' align="center">Module</TableCell>
                            <TableCell sx={{ flex: 0.10 }} className='eMed_Credit_bill_pop_Tbl_Header' align="right">{`Bill Amt (₹)`}</TableCell>
                            <TableCell sx={{ flex: 0.10 }} className='eMed_Credit_bill_pop_Tbl_Header' align="right">{`Pending Amt (₹)`}</TableCell>
                            <TableCell sx={{ flex: 0.10 }} className='eMed_Credit_bill_pop_Tbl_Header' align="right">{`Deduct Amt (₹)`}</TableCell>
                            {this.props.from === "admission_wise" ? <TableCell sx={{ flex: 0.10 }} className='eMed_Credit_bill_pop_Tbl_Header' align="right">{`Action`}</TableCell> : null}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.BillDataList?.length > 0 ? this.state.BillDataList?.map((row, index) => (
                            <TableRow
                                key={row?.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell sx={{ flex: 0.06 }} align="center" className='eMed_Credit_bill_pop_Tbl_Body'>
                                    <Checkbox emed_tid="bill_select_for_pay" size='small' checked={row?.is_checked} onChange={(e) => { this.handleCheckBox(e, "bill_Check", index) }} />
                                </TableCell>
                                <TableCell sx={{ flex: 0.17 }} className='eMed_Credit_bill_pop_Tbl_Body' align="center">
                                    <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography fontSize={"0.9vw"} fontWeight={600} >{row?.invoice_number ? row.invoice_number : "-"}</Typography>
                                        <Typography fontSize={"0.8vw"}>{row?.invoice_date ? convertDateTime(row?.invoice_date) : "-"}</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell emed_tid="bill_module" sx={{ flex: 0.14 }} className='eMed_Credit_bill_pop_Tbl_Body' align="center">{row?.module ? row.module : "-"}</TableCell>
                                <TableCell emed_tid="bill_total_amount" sx={{ flex: 0.10 }} className='eMed_Credit_bill_pop_Tbl_Body' align="right">{row?.net_amount ? row.net_amount : 0}</TableCell>
                                <TableCell emed_tid="bill_pending_amount" sx={{ flex: 0.10 }} className='eMed_Credit_bill_pop_Tbl_Body' align="right">{row?.pending_amount ? row.pending_amount : 0}</TableCell>
                                <TableCell emed_tid="bill_deduct_amount" sx={{ flex: 0.10, fontWeight: 600 }} className='eMed_Credit_bill_pop_Tbl_Body' align="right">{row?.deduct_amount ? row.deduct_amount : 0}</TableCell>
                                {this.props.from === "admission_wise" ? <TableCell sx={{ flex: 0.10, fontWeight: 600 }} className='eMed_Credit_bill_pop_Tbl_Body' align="right">{
                                    <Tooltip title="Print Bill" placement="top" arrow>
                                        <Button className='eMed_usrconf_btn' onClick={() => { this.printCreditBills(row) }}>
                                            <img src={ImagePaths.PrintIcons.default} alt="print" className='eMed_action_img' />
                                        </Button>
                                    </Tooltip>}</TableCell> : null}
                            </TableRow>
                        )) : <TableRow>
                            <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
                            <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
                            <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
                            <TableCell sx={{ borderBottom: "none" }} align="right">
                                <Typography paddingTop={"2vw"}>No Records Found</Typography>
                            </TableCell>
                            <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
                            <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    CalculateDeductAmt = () => {
        try {
            let Amount = this.state.PayableAmount ? this.state.PayableAmount : 0
            let List = []
            this.state.BillDataList.forEach((item, index) => {
                if (item?.is_checked) {
                    if (item?.pending_amount <= Amount) {
                        item.deduct_amount = +item?.pending_amount
                        Amount = Amount - item?.pending_amount
                    } else if (Amount > 0) {
                        item.deduct_amount = +Amount
                        Amount = 0
                    } else {
                        item.deduct_amount = 0
                    }
                }
                List.push(item)
            })
            this.setState({ BillDataList: List }, () => { this.ClearPaymentDetails() })
        } catch (e) {

        }
    }

    ClearDatas() {
        var LoggedUserData = JSON.parse(localGetItem("loggedInUserInfo"))
        this.setState({
            ShowOpBills: this.props?.modules?.includes('op') ? true : false,
            ShowIpBills: this.props?.modules?.includes('ip') ? true : false,
            ShowPharmaBills: (this.props?.modules?.includes('pharmacy') && (localGetItem("Allow_Pharma_CreditBill_In_FO") === "true" || LoggedUserData?.module_name === "Pharmacy")) ? true : false,
            ShowLabBills: this.props?.modules?.includes('lab') ? true : false,
            TotalPayableAmount: 0,
            PayableAmount: '',
            isCash: false,
            isCard: false,
            isUPI: false,
            isCheque: false,
            isBankTranfer: false,
            CashAmount: "",
            CardAmount: "",
            CardTransactionNumber: "",
            UPINumber: "",
            UPIAmount: "",
            BankTfrAmount: "",
            BankTfrNumber: "",
            ChequeNumber: "",
            ChequeAmount: "",
            TwoThounsandCounts: "",
            FiftyCounts: "",
            FiveHundredCounts: "",
            TwentyCounts: "",
            TwoHundredCounts: "",
            TenCounts: "",
            HundredCounts: "",
            CoinsCounts: "",
            FiveCount: "",
            PayFromAdvance: false,
            ShowRadiologyBills: (this.props?.modules?.includes('radiology') && (localGetItem("block_radiology_bills_in_fo") === "true" || LoggedUserData?.module_name === "Radiology")) ? true : false,
            ShowOtBills: this.props?.modules?.includes('ot') ? true : false
        }, () => {
            this.FilterBillList()
        })
    }

    ClearPaymentDetails() {
        this.setState({
            isCash: false,
            isCard: false,
            isUPI: false,
            isCheque: false,
            isBankTranfer: false,
            CashAmount: "",
            CardAmount: "",
            CardTransactionNumber: "",
            UPINumber: "",
            UPIAmount: "",
            BankTfrAmount: "",
            BankTfrNumber: "",
            ChequeNumber: "",
            ChequeAmount: "",
            TwoThounsandCounts: "",
            FiftyCounts: "",
            FiveHundredCounts: "",
            TwentyCounts: "",
            TwoHundredCounts: "",
            TenCounts: "",
            HundredCounts: "",
            CoinsCounts: "",
            FiveCount: "",
        })
    }

    CalculateAmount(RefNumCheck = false) {
        var states = this.state
        let CashAmt = states.CashAmount === "" ? 0 : +states.CashAmount;
        let CardAmt = states.CardAmount === "" ? 0 : +states.CardAmount;
        let UPIAmt = states.UPIAmount === "" ? 0 : +states.UPIAmount;
        let bankTransferAmt = states.BankTfrAmount === "" ? 0 : +states.BankTfrAmount;
        let ChequeAmt = states.ChequeAmount === "" ? 0 : +states.ChequeAmount;
        if (states.PayableAmount === "" || !states.PayableAmount) {
            //  this.setState({disable: false})
            return false
        }
        else if (+states.PayableAmount === (CashAmt + CardAmt + UPIAmt + bankTransferAmt + ChequeAmt)) {
            if (RefNumCheck) {
                if ((CardAmt === 0 || states.CardTransactionNumber !== "") &&
                    (UPIAmt === 0 || states.UPINumber !== "") &&
                    (bankTransferAmt === 0 || states.BankTfrNumber !== "") &&
                    (ChequeAmt === 0 || states.ChequeNumber !== "")) {
                    return true
                }
            } else {
                return true
            }
        }
    }

    ReturnCollectedIn(BillList = []) {
        let CollectedProp = this.props?.CollectedIn
        let OnlyPharmacyBills = BillList.every((list) => (list?.module === "Pharmacy"))
        let OnlyLabBills = BillList.every((list) => (list?.module === "Laboratory"))
        if (CollectedProp === "IP" || CollectedProp === "OP") {
            if (OnlyPharmacyBills) {
                return "FO PH"
            } else if (OnlyLabBills) {
                return "FO LAB"
            } else {
                return CollectedProp
            }
        } else {
            return CollectedProp
        }
    }

    PostBill(FromAdvance = false) {
        try {
            var states = this.state
            var LoggedUserData = JSON.parse(localGetItem("loggedInUserInfo"))
            let payment = []
            let BillList = this.state.BillDataList.filter((item) => (item?.is_checked && item?.deduct_amount > 0))
            if (this.state.isCash) {
                payment.push(1)
            }
            if (this.state.isCard) {
                payment.push(2)
            }
            if (this.state.isUPI) {
                payment.push(3)
            }
            if (this.state.isCheque) {
                payment.push(4)
            }
            if (this.state.isBankTranfer) {
                payment.push(5)
            }
            if (
                ((states.PayableAmount !== "" &&
                    (states.isCash || states.isCard || states.isBankTranfer || states.isUPI || states.isCheque) &&
                    this.CalculateAmount()) || FromAdvance)
            ) {
                if ((this.state.CardAmount !== "" && this.state.CardTransactionNumber === "" ||
                    this.state.UPIAmount !== "" && this.state.UPINumber === "" ||
                    this.state.ChequeAmount !== "" && this.state.ChequeNumber === "" ||
                    this.state.BankTfrAmount !== "" && this.state.BankTfrNumber === "") && !FromAdvance) {
                    this.errorMessage("Please,Enter Reference Number")
                    this.setState({disable : false})
                } else {
                    let ColledInValue = this.ReturnCollectedIn(BillList)
                    let data = {
                        "amount_cash": +states.CashAmount,
                        "amount_card": +states.CardAmount,
                        "upi_amount": +states.UPIAmount,
                        "paid_amount": +states.PayableAmount,
                        "bank_transfer_amount": +states.BankTfrAmount,
                        "cheque_amount": +states.ChequeAmount,
                        "cheque_ref_no": states.ChequeNumber,
                        "bank_ref_no": states.BankTfrNumber,
                        "upi_ref_no": states.UPINumber,
                        "card_ref_no": states.CardTransactionNumber,
                        "payment_mode": payment,
                        "patient_id": this.props?.BillData?.patient_id,
                        "bill_list": BillList,
                        "branch_id": localGetItem("BranchId") ? +localGetItem("BranchId") : null,
                        "clinic_id": LoggedUserData?.clinic_id ? LoggedUserData?.clinic_id : null,
                        "module": this.props?.modules ? this.props?.modules : [],
                        // "paid_for": LoggedUserData?.module_name ? (LoggedUserData?.module_name === "front_office" ? "Front office" : LoggedUserData?.module_name) : null,
                        "add_to_advance": states.PayFromAdvance ? true : false,
                        "collected_in": ColledInValue
                    }
                    if (ColledInValue === "FO PH") {
                        data.pharmacy_id = BillList[0]?.pharmacy_id
                    } else if (ColledInValue === "FO LAB") {
                        data.laboratory_id = BillList[0]?.laboratory_id
                    }
                    if (this.props?.POST_Module_ID_Key_Name) {
                        data[this.props?.POST_Module_ID_Key_Name] = this.props?.POST_Module_ID_Key_Value
                    } else {
                        data[this.props?.Module_ID_Key_Name] = this.props?.Module_ID_Key_Value
                    }
                    // this.setState({ disable: true })
                    this.LoaderFunction(true)
                    RestAPIService.create(data, Serviceurls.FO_CREDIT_BILLS_GET).
                        then((response) => {
                            this.LoaderFunction(false)
                            if (response.data.status === "success") {
                                this.setState({ disable: false })
                                this.LoaderFunction(false)
                                this.props.CrebillPosted(response.data.message, null, null, false, BillList)
                            }
                            else {
                                this.setState({ disable: false })
                                this.LoaderFunction(false)
                                this.props.CrebillPosted(response.data.message, null, null, true, [])
                                // this.errorMessage(response.data.message)
                            }
                        }).catch((error) => {
                            this.setState({ disable: false })
                            this.LoaderFunction(false)
                            this.props.CrebillPosted(error.message, null, null, true)
                            // this.errorMessage(error.message)
                        })
                }
            }
            else {
                if (states.PayableAmount === "" || states.PayableAmount === "0") {
                    this.errorMessage("Please Enter the Receivable Amount")
                    this.setState({disable:false})
                }
                else if (!(states.isCash || states.isCard || states.isBankTranfer || states.isUPI || states.isCheque) && !FromAdvance) {
                    this.errorMessage("Please Select any Payment Methods")
                    this.setState({disable:false})
                }
                else if (!this.CalculateAmount() && !FromAdvance) {
                    this.errorMessage("Amount Not Matched ")
                    this.setState({disable:false})
                }
                // else if (ZeroAmtBill) {
                //     this.errorMessage("Some Selected Bills have Zero Deducted Amount")
                // }
            }
        }
        catch (e) {
            this.setState({ disable: false })
            this.errorMessage(e.message)
        }
    }

    render() {
        console.log(this.state);
        console.log(this.props);
        
        let { t } = this.props;
        var LoggedUserData = JSON.parse(localGetItem("loggedInUserInfo"))
        return (
            <Box>
                <Modal open={true}>
                    <Paper elevation={3} id="emedhub_popup_mainBox" sx={{ width: "81vw", height: "87vh" }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', height: '6vh' }}>
                            <Typography fontWeight={600} marginLeft={'1vw'}>Credit Bill Receivables</Typography>
                            <Button emed_tid={"make_payment_close_btn"}  onClick={() => { this.props.ClosePopUp() }} >
                                <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                            </Button>
                        </Box>
                        {this.state.isLoader ? <Box sx={{ width: "80vw", height: '82vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><CircularProgress /></Box> :
                            <Box sx={{ width: "80vw", height: '82vh' }}>
                                <Box sx={{ height: "8vh", display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <CommonPatientDetails data={this.props?.BillData} />
                                    <Box>
                                        <Typography fontSize={"0.8vw"} >{(this.props?.BillData?.ip_number || this.props?.BillData?.ip_numberr) ? "IP No" : this.props?.BillData?.op_number ? "OP No" : ""}</Typography>
                                        <Typography fontSize={"0.8vw"} fontWeight={600}>{this.props?.BillData?.ip_numberr ? this.props?.BillData?.ip_numberr : this.props?.BillData?.ip_number ? this.props?.BillData?.ip_number : this.props?.BillData?.op_number ? this.props?.BillData?.op_number : ""}</Typography>
                                    </Box>
                                    {this.props.fromReceivePayment ?
                                        <Box component={'div'} display={'flex'} mb={'0.5vw'} ml={'1vw'}>
                                            <Box component={'div'} border={'1px solid lightgray'} mr={'0.5vw'} borderRadius={'5px'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} height={'4rem'} width={'10rem'}>
                                                <Typography fontWeight={600}>{AmountFormat(this.props.billAmount)}</Typography>
                                                <Typography fontWeight={600} fontSize={'0.8vw'}>Total Bill Amount</Typography>
                                            </Box>
                                            <Box component={'div'} border={'1px solid lightgray'} mr={'0.5vw'} borderRadius={'5px'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} height={'4rem'} width={'10rem'}>
                                                <Typography fontWeight={600}>{AmountFormat(this.props.paidAmount)}</Typography>
                                                <Typography fontWeight={600} fontSize={'0.8vw'}>Total Paid Amount</Typography>
                                            </Box>
                                            <Box component={'div'} border={'1px solid lightgray'} mr={'0.5vw'} borderRadius={'5px'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} height={'4rem'} width={'10rem'}>
                                                <Typography fontWeight={600}>{AmountFormat(this.props.balanceAmount)}</Typography>
                                                <Typography fontWeight={600} fontSize={'0.8vw'}>Total Balance</Typography>
                                            </Box>
                                            <Box component={'div'} border={'1px solid lightgray'} mr={'0.5vw'} borderRadius={'5px'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} height={'4rem'} width={'10rem'}>
                                                <Typography fontWeight={600}>{AmountFormat(this.props.dueAmount)}</Typography>
                                                <Typography fontWeight={600} fontSize={'0.8vw'}>Advance Amount</Typography>
                                            </Box>
                                        </Box> : null
                                    }
                                    {
                                        this.state.CreditDetails?.company_name ? <Box component={'div'} display={'flex'} mb={'0.5vw'} ml={'1vw'}>
                                            <Box component={'div'} border={'1px solid lightgray'} mr={'0.5vw'} borderRadius={'5px'} display={'flex'} flexDirection={'column'} justifyContent={'flex-start'} alignItems={'flex-start'} height={'4rem'} paddingX={"0.5vw"}>
                                                <Typography fontWeight={600} fontSize={"0.8vw"}>{`Corporate Name : ${this.state.CreditDetails?.company_name ? this.state.CreditDetails?.company_name : "-"} ( Emp Id : ${this.state.CreditDetails?.id_no ? this.state.CreditDetails?.id_no : "-"})`}</Typography>
                                                <Typography fontWeight={600} fontSize={"0.8vw"}>{`Corpoarte Allocated Amount ( ${this.state.CreditDetails?.discount_percent ? this.state.CreditDetails?.discount_percent : 0} % )  : ${AmountFormat(this.state.CreditDetails?.total_bill_amount ? CommonValidation.calculatePercentage(this.state.CreditDetails?.discount_percent ? this.state.CreditDetails?.discount_percent : 0, this.state.CreditDetails?.total_bill_amount ? +this.state.CreditDetails?.total_bill_amount : 0) : 0)}`}</Typography>
                                                <Box display={'flex'} flexDirection={'row'}>
                                                    <Typography fontWeight={600} fontSize={"0.8vw"}>{`Total Bill Amount : ${AmountFormat(this.state.CreditDetails?.total_bill_amount ? this.state.CreditDetails?.total_bill_amount : 0)}`}</Typography>
                                                    <Typography fontWeight={600} fontSize={'0.8vw'} marginLeft={"1.5vw"}>{`Patient Payable Amount : ${AmountFormat((this.state.CreditDetails?.pending_amount ? this.state.CreditDetails?.pending_amount : 0) - (this.state.CreditDetails?.total_bill_amount ? CommonValidation.calculatePercentage(this.state.CreditDetails?.discount_percent ? this.state.CreditDetails?.discount_percent : 0, this.state.CreditDetails?.total_bill_amount ? +this.state.CreditDetails?.total_bill_amount : 0) : 0) > 0 ? (this.state.CreditDetails?.pending_amount ? this.state.CreditDetails?.pending_amount : 0) - (this.state.CreditDetails?.total_bill_amount ? CommonValidation.calculatePercentage(this.state.CreditDetails?.discount_percent ? this.state.CreditDetails?.discount_percent : 0, this.state.CreditDetails?.total_bill_amount ? +this.state.CreditDetails?.total_bill_amount : 0) : 0) : 0)}`}</Typography>
                                                </Box>
                                            </Box>
                                        </Box> : null
                                    }
                                </Box>
                                <Divider />
                                <Box sx={{ height: "52vh" }}>
                                    <Box ml={"0.5vw"} visibility={(this.props?.modules?.length === 1 && localGetItem('multiPharmacy') === "false" && localGetItem('multiLaboratory') === "false" ) ? 'hidden' : 'visible'}>
                                        {(this.props?.modules?.includes('op') &&
                                            this.state.AllBillDataList.some((item) => (item?.module === "Out Patient"))) ?
                                            <FormControlLabel control={<Checkbox emed_tid={"show_op_bills"} checked={this.state.ShowOpBills} onChange={(e) => { this.handleCheckBox(e, "ShowOpBills") }} size='small' />} label={"OP"} /> : null}
                                        {(this.props?.modules?.includes('ip') &&
                                            this.state.AllBillDataList.some((item) => (item?.module === "In Patient"))) ?
                                            <FormControlLabel control={<Checkbox emed_tid={"show_ip_bills"} checked={this.state.ShowIpBills} onChange={(e) => { this.handleCheckBox(e, "ShowIpBills") }} size='small' />} label={"IP"} /> : null}
                                        {(this.props?.modules?.includes('pharmacy') &&
                                            (localGetItem("Allow_Pharma_CreditBill_In_FO") === "true" || LoggedUserData?.module_name === "Pharmacy") &&
                                            this.state.AllBillDataList.some((item) => (item?.module === "Pharmacy"))) ?
                                                (localGetItem('multiPharmacy') === "true") ?
                                                    this.state.PharmacyData?.map((item, i) => (
                                                        <FormControlLabel control={<Checkbox disabled={item?.disableCheck} checked={item?.isChecked} onChange={(e) => { this.handleCheckBox(e, "PharmacyData", i) }} size='small' />} label={item?.pharmacy_name} />
                                                    ))
                                                :
                                                <FormControlLabel control={<Checkbox emed_tid={"show_pharma_bills"} checked={this.state.ShowPharmaBills} onChange={(e) => { this.handleCheckBox(e, "ShowPharmaBills") }} size='small' />} label={"Pharmacy"} /> : null}
                                        {(this.props?.modules?.includes('lab') && this.state.AllBillDataList.some((item) => (item?.module === "Laboratory"))) ?
                                                (localGetItem('multiLaboratory') === "true") ?
                                                    this.state.LabData?.map((item, i) => (
                                                        // console.log(item)
                                                        
                                                        <FormControlLabel control={<Checkbox disabled={item?.disableCheck} checked={item?.isChecked} onChange={(e) => { this.handleCheckBox(e, "LabData", i) }} size='small' />} label={item?.laboratory_name} />
                                                    ))
                                                :
                                                <FormControlLabel control={<Checkbox emed_tid={"show_lab_bills"} checked={this.state.ShowLabBills} onChange={(e) => { this.handleCheckBox(e, "ShowLabBills") }} size='small' />} label={"Lab"} /> : null}
                                        {(this.props?.modules?.includes('radiology') && localGetItem("Show_Radiology_Bills_In_FO") === "true" &&
                                            this.state.AllBillDataList.some((item) => (item?.module === "Radiology"))) ?
                                            <FormControlLabel control={<Checkbox emed_tid={"show_radiology_bills"} checked={this.state.ShowRadiologyBills} onChange={(e) => { this.handleCheckBox(e, "ShowRadiologyBills") }} size='small' />} label={"Radiology"} /> : null}
                                        {(this.props?.modules?.includes('ot') &&
                                            this.state.AllBillDataList.some((item) => (item?.module === "OT"))) ?
                                            <FormControlLabel control={<Checkbox emed_tid={"show_ot_bills"} checked={this.state.ShowOtBills} onChange={(e) => { this.handleCheckBox(e, "ShowOtBills") }} size='small' />} label={"OT"} /> : null}
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <Box border={'1px solid lightgray'} sx={{ borderRadius: "0.3vw" }}>
                                            {this.renderLeftTable()}
                                        </Box>
                                        <Box border={'1px solid lightgray'} sx={{ height: "65vh", width: '30vw', borderRadius: "0.3vw", marginLeft: '0.5vw' }}>
                                            <Box height={"5vh"} sx={{ display: 'flex', alignItems: 'center', paddingLeft: "1vw", backgroundColor: Colors.Background }}>
                                                <Typography fontWeight={600} color={"#616161"}>Billing Information</Typography>
                                            </Box>
                                            <Divider />
                                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: "1vw", paddingBottom: '0.4vw' }}>
                                                <Typography emed_tid={"Total_Receivable_Amount_TestID"} fontSize={"0.9vw"} fontWeight={600}>Total Receivable Amount</Typography>
                                                <Typography emed_tid={"TotalPayableAmount01_TestID"} fontSize={"1vw"} fontWeight={600}>₹  {this.state.TotalPayableAmount ? this.state.TotalPayableAmount : 0}</Typography>
                                            </Box>
                                            <Box display={this.state.AdvanceAmount ? "inline" : "none"} paddingLeft={"1vw"}>
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={this.state.PayFromAdvance}
                                                        disabled={!this.state.TotalPayableAmount}
                                                        emed_tid={"TotalPayableAmount_TestID"}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                PayFromAdvance: e.target.checked,
                                                            }, () => {
                                                                if (this.state.PayFromAdvance) {
                                                                    if (this.state.AdvanceAmount < this.state.TotalPayableAmount) {
                                                                        this.setState({ PayableAmount: this.state.AdvanceAmount }, () => { this.CalculateDeductAmt() })
                                                                    } else {
                                                                        this.setState({ PayableAmount: this.state.TotalPayableAmount }, () => { this.CalculateDeductAmt() })
                                                                    }
                                                                } else {
                                                                    this.setState({ PayableAmount: 0 }, () => { this.CalculateDeductAmt() })
                                                                }
                                                            })
                                                        }}
                                                        size='small' />}
                                                    label={`Pay from Advance ( ${AmountFormat(this.state.AdvanceAmount)} )`} />
                                            </Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingX: "1vw", paddingBottom: '0.5vw' }}>
                                                <Typography fontSize={"0.9vw"} fontWeight={600}>{this.state.PayFromAdvance ? "Deductable Advance Amount" : "Receivable Amount"}</Typography>
                                                <OutlinedInput
                                                    inputProps={{ emed_tid: "payable_amount" }}
                                                    value={this.state.PayableAmount ? this.state.PayableAmount : ""}
                                                    disabled={!this.state.TotalPayableAmount || this.state.PayFromAdvance || this.props.CollectedIn === "CANTEEN"}
                                                    startAdornment={<InputAdornment position="start">₹</InputAdornment>}
                                                    sx={{ height: "2vw", fontSize: "0.9vw", width: "8.5vw" }}
                                                    onChange={(e) => {
                                                        const { value } = e.target
                                                        if ((CommonValidation.NumberWithDot(value) && value <= this.state.TotalPayableAmount) || value === "") {
                                                            this.setState({
                                                                PayableAmount: value
                                                            }, () => { this.CalculateDeductAmt() })
                                                        }
                                                    }} />
                                            </Box>
                                            <Divider />
                                            <Box sx={{ height: this.state.AdvanceAmount ? "33vh" : "38vh", overflowY: "scroll", paddingLeft: '1vw' }}>
                                                <Box className="eMed_CrdPop_Check_Group">
                                                    <FormControlLabel className='eMed_CrdPop_CheckBoxDiv'  control={<Checkbox emed_tid = "isCash_TestID" checked={this.state.isCash} disabled={!this.state.PayableAmount || this.state.PayFromAdvance} onChange={(e) => { this.handleCheckBox(e, "isCash") }} size='small' />} label={t("Cash")} />
                                                    <FormControlLabel className='eMed_CrdPop_CheckBoxDiv'   control={<Checkbox  emed_tid = "isCard_TestID" checked={this.state.isCard} disabled={!this.state.PayableAmount || this.state.PayFromAdvance} onChange={(e) => { this.handleCheckBox(e, "isCard") }} size='small' />} label={t("Card")} />
                                                    <FormControlLabel className='eMed_CrdPop_CheckBoxDiv'  control={<Checkbox  emed_tid = "isUPI_TestID"  checked={this.state.isUPI} disabled={!this.state.PayableAmount || this.state.PayFromAdvance} onChange={(e) => { this.handleCheckBox(e, "isUPI") }} size='small' />} label={t("UPI")} />
                                                    <FormControlLabel className='eMed_CrdPop_CheckBoxDiv'  sx={{ width: "10vw" }} control={<Checkbox emed_tid ="isBankTranfer_TestID" checked={this.state.isBankTranfer} disabled={!this.state.PayableAmount || this.state.PayFromAdvance} onChange={(e) => { this.handleCheckBox(e, "isBankTranfer") }} size='small' />} label={t("BankTransfer")} />
                                                    {this.props.CollectedIn === "CANTEEN" ? null : <FormControlLabel className='eMed_CrdPop_CheckBoxDiv'  control={<Checkbox emed_tid = "PayableAmount_TestID"  checked={this.state.isCheque} disabled={!this.state.PayableAmount || this.state.PayFromAdvance} onChange={(e) => { this.handleCheckBox(e, "isCheque") }} size='small' />} label={t("Cheque")} />}
                                                </Box>

                                                <Box component={'div'} >
                                                    {
                                                        this.state.isCash ? this.renderAmountTextFields("CashAmount", "CashAmount", ImagePaths.CashIcon.default) : null
                                                    }
                                                    {
                                                        this.state.isCard ?
                                                            <Box component={"div"} display={"flex"} flexDirection={"row"}  >
                                                                {this.renderAmountTextFields("CardAmount", "CardAmount", ImagePaths.CardIcon.default)}
                                                                {this.renderNumberTextFields("CardTransactionNumber", "CardTransactionNumber", true)}
                                                            </Box>
                                                            : null
                                                    }
                                                    {
                                                        this.state.isUPI ?
                                                            <Box component={"div"} display={"flex"} flexDirection={"row"} >
                                                                {this.renderAmountTextFields("UPIAmount", "UPIAmount", ImagePaths.UPIIcon.default)}
                                                                {this.renderNumberTextFields("UPITransactionNumber", "UPINumber", true)}
                                                            </Box>
                                                            : null
                                                    }
                                                    {
                                                        this.state.isBankTranfer ?
                                                            <Box component={"div"} display={"flex"} flexDirection={"row"} >
                                                                {this.renderAmountTextFields("BankTransferAmount", "BankTfrAmount", ImagePaths.BankTransferIcon.default)}
                                                                {this.renderNumberTextFields("BankTransactionNumber", "BankTfrNumber", true)}
                                                            </Box>
                                                            : null
                                                    }
                                                    {
                                                        this.state.isCheque ?
                                                            <Box component={"div"} display={"flex"} flexDirection={"row"}  >
                                                                {this.renderAmountTextFields("ChequeAmount", "ChequeAmount", ImagePaths.ChequeIcon.default)}
                                                                {this.renderNumberTextFields("ChequeTransactionNumber", "ChequeNumber", true)}
                                                            </Box>
                                                            : null
                                                    }
                                                    {
                                                        this.state.isInsurance ?
                                                            <Box component={"div"} display={"flex"} flexDirection={"row"} >
                                                                {this.renderAmountTextFields("InsuranceAmt", "insuranceAmt", ImagePaths.RupeeOff.default)}
                                                                {this.renderNumberTextFields("InsuranceTrfNo", "insuranceTrfNo", true)}
                                                                {this.renderNumberTextFields("CompanyName", "companyName", true)}
                                                                {this.renderNumberTextFields("PolicyNo", "policyNo", true)}
                                                            </Box>
                                                            : null
                                                    }

                                                </Box>
                                                {this.state.isCash ?
                                                    <Accordion sx={{ marginTop: '0.5vw' }}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            emed_tid={"Denomination_TestID"}
                                                        >
                                                            <Typography>Denomination</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                                                <Button emed_tid={"Reset_TestID"} size='small' variant='outlined' sx={{ textTransform: 'capitalize' }} onClick={() => {
                                                                    this.setState({
                                                                        TwoThounsandCounts: "",
                                                                        FiftyCounts: "",
                                                                        FiveHundredCounts: "",
                                                                        TwentyCounts: "",
                                                                        TwoHundredCounts: "",
                                                                        TenCounts: "",
                                                                        HundredCounts: "",
                                                                        CoinsCounts: "",
                                                                        FiveCount: "",
                                                                    })
                                                                }}>Reset</Button>
                                                            </Box>
                                                            <Box component={"div"} className="eMed_CrdPop_Denomi_div_New">
                                                                <Box component={"div"} className="eMed_CrdPop_Denomi_MainGrid_div">
                                                                    {this.renderCountsText("2000", "TwoThounsandCounts")}
                                                                    {this.renderCountsText("20", "TwentyCounts", true)}
                                                                </Box>
                                                                <Box component={"div"} className="eMed_CrdPop_Denomi_MainGrid_div">
                                                                    {this.renderCountsText("500", "FiveHundredCounts")}
                                                                    {this.renderCountsText("10", "TenCounts", true)}
                                                                </Box>
                                                                <Box component={"div"} className="eMed_CrdPop_Denomi_MainGrid_div">
                                                                    {this.renderCountsText("200", "TwoHundredCounts")}
                                                                    {this.renderCountsText("5", "FiveCount", true)}
                                                                </Box>
                                                                <Box component={"div"} className="eMed_CrdPop_Denomi_MainGrid_div">
                                                                    {this.renderCountsText("100", "HundredCounts")}
                                                                    {this.renderCountsText("1", "CoinsCounts", true)}
                                                                </Box>
                                                                <Box component={"div"} className="eMed_CrdPop_Denomi_MainGrid_div">
                                                                    {this.renderCountsText("50", "FiftyCounts")}
                                                                </Box>
                                                                <Box component={'div'} display={"flex"} flexDirection={"row"} margin={"1vw"} justifyContent={'center'}>
                                                                    <Typography>{t("Total")}<b style={{ color: (this.returnTotal() === +this.state.PayableAmount && this.state.PayableAmount !== "") ? Colors.themeDark : Colors.black, marginLeft: '1vw' }}>{`₹ ${this.returnTotal()}`}</b></Typography>
                                                                    <Typography marginLeft={"3vw"}>{t("Balance")}<b style={{ color: (this.state.PayableAmount != "" ? +this.state.PayableAmount - this.returnTotal() : 0) < 0 ? Colors.red : Colors.black, marginLeft: '1vw' }}>₹ {this.state.PayableAmount != "" ? +this.state.PayableAmount - this.returnTotal() : 0}</b></Typography>
                                                                </Box>
                                                            </Box>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                    : null}
                                            </Box>
                                            <Typography visibility={(this.state.BillDataList.some((item) => (item?.deduct_amount === 0 && item?.is_checked)) && this.state.PayableAmount) ? "visible" : "hidden"} sx={{ fontSize: '0.8vw', color: 'red', textAlign: 'center' }}>Note : No receipt will be generated for bills with a deduction of 0 Amount.</Typography>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', paddingTop: '1vw' }}>
                                                <Button emed_tid="close_make_payment" size='small' variant='outlined' sx={{ textTransform: "capitalize" }} onClick={() => { this.ClearDatas() }}>Clear</Button>
                                                {this.state.PayFromAdvance ?
                                                    <Button emed_tid={"deduct_from_advance"} size='small' variant='contained' sx={{ textTransform: "capitalize", width: '10vw' }} onClick={() => { this.setState({disable: true},()=>{this.PostBill(true)}) }} disabled={this.state.disable}>Deduct From Advance</Button>
                                                    : <Button emed_tid={"make_payment_btn"} size='small' variant='contained' sx={{ textTransform: "capitalize", width: '10vw' }} disabled={!this.CalculateAmount() || this.state.disable}
                                                     onClick={() => {
                                                        this.setState({disable: true},()=>{
                                                            this.PostBill()
                                                        })
                                                        
                                                         }}>Receive Payment </Button>
                                                }
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>}
                    </Paper>
                </Modal>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>

        )
    }
}

export default withTranslation()(CreditBillCompletePoP)