import { Box, Button, Checkbox, Chip, FormControlLabel, Modal, Radio, RadioGroup, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import React, { Component } from "react";
import { Colors } from "../../../../Styles/Colors";
import { ImagePaths } from "../../../../Utility/ImagePaths";
import { ADD, AXIS_OPTIONS, BCDVA_OPTIONS, BCNVA, CYL_OPTIONS, SPH_OPTIONS } from "../../../../Utility/Constants";
import RestAPIService from "../../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../../Utility/API/Serviceurls";
import ToastMsg from "../../../../Components/ToastMsg/ToastMsg";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment/moment";
import ShowComponents from "./ShowComponent";
import CommonValidation from "../../../../Components/CommonFunctions/CommonValidation";

const FrameList = [
    { value: 'FULL FRAME', label: 'FULL FRAME' },
    { value: 'SEMI RIMLESS FRAME', label: 'SEMI RIMLESS FRAME' },
    { value: 'RIMLESS FRAME', label: 'RIMLESS FRAME' }
]
export default class DocPreviousGlassPower extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openPopUp: false,
            patientDetails: {},
            clinicId: this.props.clinicId,
            patientId: this.props.patientId,
            appointmentId: this.props.appointmentId,
            isLocked: this.props.isLocked,
            appointmentDate: this.props.appointmentDate,
            value: "Two",
            LeftEye: {
                SPH: "",
                CYL: "",
                AXIS: "",
                BCDVA: "",
                ADD: "",
                BCNVA: "",
                comments: ""
            },
            RightEye: {
                SPH: "",
                CYL: "",
                AXIS: "",
                BCDVA: "",
                ADD: "",
                BCNVA: "",
                comments: ""
            },
            date: new Date(),
            frameType: "",
            Leftchecked: false,
            Rightchecked: false,
            postLoad: false,
            templateDataLeft: [],
            templateDataRight: [],
            disableLeft: false,
            disableRight: false,
            isEdit: false,
            PreviousData: [],
            currentData: [],
            comparePopup: false,
            CommentsRE: "",
            SPHselectedValues: "Minus",
            CYLselectedValues: "Minus",
            ADDselectedValues: "All",
            BCDVAselectedValues: "All",
        }
        this.SPH_REF = React.createRef();
        this.CYL_REF = React.createRef();
        this.AXIS_REF = React.createRef();
        this.ADD_REF = React.createRef();
        this.BCDVA_REF = React.createRef();
        this.BCNVA_REF = React.createRef();
    }

    apiCalls = () => {
        this.getPreviousGlassPower()
        this.getOldGlassPower()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLocked !== this.props.isLocked) {
            this.setState({ isLocked: this.props.isLocked })
        }
    }

    getOldGlassPower = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_PGP_PREVIOUS_GLASS_POWER + `?patient_id=${this.state.patientId}&appointment_id=${this.state.appointmentId}&appointment_date=${this.state.appointmentDate}&doctor_id=${this.props.doctorID}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            PreviousData: response.data.data
                        }, () => {
                            var left = []; var Right = []; var data = this.state.PreviousData
                            if (data[0] && data[1]) {
                                if (data[0]?.eye_type == "LE") {
                                    left.push(data[0])
                                } else {
                                    Right.push(data[0])
                                }
                                if (data[1]?.eye_type == "RE") {
                                    Right.push(data[1])
                                } else {
                                    left.push(data[1])
                                }
                            } else if (data[0]) {
                                if (data[0]?.eye_type == "LE") {
                                    left.push(data[0])
                                } else {
                                    Right.push(data[0])
                                }
                            }
                            this.setState({
                                oldDataLeft: left,
                                oldDataRight: Right
                            })
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getPreviousGlassPower = () => {
        let states = this.state
        try {
            RestAPIService.getAll(Serviceurls.DOC_PREVIOUS_GLASS_POWER + `?appointment_id=${this.state.appointmentId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        let data = []

                        data = response.data.data.previous_glass_power ? response.data.data.previous_glass_power : []

                        let temp_date = "";
                        let temp_frame_type = "";

                        for (var i = 0; i < data.length; i++) {
                            if (i == 0) {
                                temp_date = data[i].date
                                temp_frame_type = data[i].frame_type
                            } else {
                                data[i].date = temp_date
                                data[i].frame_type = temp_frame_type
                            }
                        }

                        this.setState({
                            date: temp_date ? temp_date : new Date(),
                            frameType: temp_frame_type,
                            disableLeft: false,
                            disableRight: false,
                            isEdit: false,
                            selectedText: "",
                            value: "Two",
                            Leftchecked: false,
                            Rightchecked: false,
                            NewText: "",
                            LeftEye: {
                                SPH: "",
                                CYL: "",
                                AXIS: "",
                                BCDVA: "",
                                ADD: "",
                                BCNVA: "",
                                comments: ""
                            },
                            RightEye: {
                                SPH: "",
                                CYL: "",
                                AXIS: "",
                                BCDVA: "",
                                ADD: "",
                                BCNVA: "",
                                comments: ""
                            },
                            currentData: data
                        }, () => {
                            var left = []; var Right = [];
                            if (data[0] && data[1]) {
                                if (data[0]?.eye_type == "LE") {
                                    left.push(data[0])
                                } else {
                                    Right.push(data[0])
                                }
                                if (data[1]?.eye_type == "RE") {
                                    Right.push(data[1])
                                } else {
                                    left.push(data[1])
                                }
                            } else if (data[0]) {
                                if (data[0]?.eye_type == "LE") {
                                    left.push(data[0])
                                } else {
                                    Right.push(data[0])
                                }
                            }
                            this.setState({
                                templateDataLeft: left,
                                templateDataRight: Right
                            })
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    CopyPreviousData = () => {
        var left = []; var Right = []; var data = this.state.PreviousData
        if (data[0] && data[1]) {
            if (data[0]?.eye_type == "LE") {
                left.push(data[0])
            } else {
                Right.push(data[0])
            }
            if (data[1]?.eye_type == "RE") {
                Right.push(data[1])
            } else {
                left.push(data[1])
            }
        } else if (data[0]) {
            if (data[0]?.eye_type == "LE") {
                left.push(data[0])
            } else {
                Right.push(data[0])
            }
        }

        this.setState({
            LeftEye: {
                SPH: left[0]?.SPH ? left[0]?.SPH : "",
                CYL: left[0]?.CYL ? left[0]?.CYL : "",
                AXIS: left[0]?.AXIS ? left[0]?.AXIS : "",
                BCDVA: left[0]?.BCDVA ? left[0]?.BCDVA : "",
                ADD: left[0]?.add ? left[0]?.add : "",
                BCNVA: left[0]?.BCNVA ? left[0]?.BCNVA : "",
                comments: left[0]?.comment ? left[0]?.comment : "",
                id: this.state?.templateDataLeft[0]?.id
            },
            RightEye: {
                SPH: Right[0]?.SPH ? Right[0]?.SPH : "",
                CYL: Right[0]?.CYL ? Right[0]?.CYL : "",
                AXIS: Right[0]?.AXIS ? Right[0]?.AXIS : "",
                BCDVA: Right[0]?.BCDVA ? Right[0]?.BCDVA : "",
                ADD: Right[0]?.add ? Right[0]?.add : "",
                BCNVA: Right[0]?.BCNVA ? Right[0]?.BCNVA : "",
                comments: Right[0]?.comment ? Right[0]?.comment : "",
                id: this.state?.templateDataRight[0]?.id
            },
            date: this.state.PreviousData[0]?.date,
            frameType: this.state.PreviousData[0]?.frame_type,
        }, () => { this.addPreviousGlassPower() })
    }

    onEditHandler = (data) => {
        if (data.eye_type === "RE") {
            if (this.state.templateDataLeft?.length > 0) {
                let item = this.state.templateDataLeft[0]
                this.setState({
                    LeftEye: {
                        SPH: item?.SPH,
                        CYL: item?.CYL,
                        AXIS: item?.AXIS,
                        BCDVA: item?.BCDVA,
                        ADD: item?.add,
                        BCNVA: item?.BCNVA,
                        id: item?.id,
                        comments: item.comment
                    }
                })
            }
            this.setState({
                frameType: data?.frame_type,
                date: data?.date,
                RightEye: {
                    SPH: data?.SPH,
                    CYL: data?.CYL,
                    AXIS: data?.AXIS,
                    BCDVA: data?.BCDVA,
                    ADD: data?.add,
                    BCNVA: data?.BCNVA,
                    id: data?.id,
                    comments: data?.comment
                },
                disableLeft: true,
                disableRight: false
            })
        } else {
            if (this.state.templateDataRight?.length > 0) {
                let item = this.state.templateDataRight[0]
                this.setState({
                    RightEye: {
                        SPH: item?.SPH,
                        CYL: item?.CYL,
                        AXIS: item?.AXIS,
                        BCDVA: item?.BCDVA,
                        ADD: item?.add,
                        BCNVA: item?.BCNVA,
                        id: item?.id,
                        comments: item.comment
                    }
                })
            }
            this.setState({
                frameType: data?.frame_type,
                date: data?.date,
                LeftEye: {
                    SPH: data?.SPH,
                    CYL: data?.CYL,
                    AXIS: data?.AXIS,
                    BCDVA: data?.BCDVA,
                    ADD: data?.add,
                    BCNVA: data?.BCNVA,
                    id: data?.id,
                    comments: data?.comment
                },
                disableRight: true,
                disableLeft: false
            })
        }
    }

    renderOldGlassPowerTable = () => {
        try {
            return (
                <Box sx={{ maxHeight: '15vw', overflow: 'auto', border: '1px solid lightgray' }} component={'div'}>
                    <Table stickyHeader size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Eye</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>SPH</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>CYL</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>AXIS</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>BCDVA</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>ADD</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>BCNVA</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.oldDataRight?.length > 0 ? this.state.oldDataRight?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>Right Eye</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.SPH ? item?.SPH : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.CYL ? item?.CYL : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>                                        
                                        {item?.AXIS ? item?.AXIS?.length > 10 ?
                                        <Tooltip placement='top' title={item?.AXIS}>
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.AXIS?.slice(0, 10) + '...'}</Typography>
                                        </Tooltip> :
                                        <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.AXIS}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {item?.BCDVA ? item?.BCDVA?.length > 10 ?
                                            <Tooltip placement='top' title={item?.BCDVA}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.BCDVA?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.BCDVA}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {item?.add ? item?.add?.length > 10 ?
                                            <Tooltip placement='top' title={item?.add}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.add?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.add}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {item?.BCNVA ? item?.BCNVA?.length > 10 ?
                                            <Tooltip placement='top' title={item?.BCNVA}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.BCNVA?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.BCNVA}</Typography> : '-'}
                                    </TableCell>
                                </TableRow>
                            )) : null}
                            {this.state.oldDataLeft?.length > 0 ? this.state.oldDataLeft?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>Left Eye</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.SPH ? item?.SPH : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.CYL ? item?.CYL : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>                                        
                                        {item?.AXIS ? item?.AXIS?.length > 10 ?
                                        <Tooltip placement='top' title={item?.AXIS}>
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.AXIS?.slice(0, 10) + '...'}</Typography>
                                        </Tooltip> :
                                        <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.AXIS}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {item?.BCDVA ? item?.BCDVA?.length > 10 ?
                                            <Tooltip placement='top' title={item?.BCDVA}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.BCDVA?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.BCDVA}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {item?.add ? item?.add?.length > 10 ?
                                            <Tooltip placement='top' title={item?.add}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.add?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.add}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {item?.BCNVA ? item?.BCNVA?.length > 10 ?
                                            <Tooltip placement='top' title={item?.BCNVA}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.BCNVA?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.BCNVA}</Typography> : '-'}
                                    </TableCell>
                                </TableRow>
                            )) : null}
                        </TableBody>
                    </Table>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderPreviousGlassPowerTable = () => {
        try {
            return (
                <Box sx={{ maxHeight: '15vw', overflow: 'auto', border: '1px solid lightgray' }} component={'div'}>
                    <Table stickyHeader size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Eye</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>SPH</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>CYL</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>AXIS</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>BCDVA</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>ADD</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>BCNVA</TableCell>
                                {this.state.comparePopup ? null : <TableCell align='center' width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Action</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.templateDataRight?.length > 0 ? this.state.templateDataRight?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>RE</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.SPH ? item?.SPH : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.CYL ? item?.CYL : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>                                        
                                        {item?.AXIS ? item?.AXIS?.length > 10 ?
                                        <Tooltip placement='top' title={item?.AXIS}>
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.AXIS?.slice(0, 10) + '...'}</Typography>
                                        </Tooltip> :
                                        <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.AXIS}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {item?.BCDVA ? item?.BCDVA?.length > 10 ?
                                            <Tooltip placement='top' title={item?.BCDVA}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.BCDVA?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.BCDVA}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {item?.add ? item?.add?.length > 10 ?
                                            <Tooltip placement='top' title={item?.add}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.add?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.add}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {item?.BCNVA ? item?.BCNVA?.length > 10 ?
                                            <Tooltip placement='top' title={item?.BCNVA}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.BCNVA?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.BCNVA}</Typography> : '-'}
                                    </TableCell>
                                    {this.state.comparePopup ? null : <TableCell align='center'>
                                        <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                            <Button
                                                className='Common_Btn_Min_Width'
                                                onClick={() => { this.setState({ isEdit: true, openPopUp: true, value: "Two" }, () => { this.onEditHandler(item) }) }}
                                                disabled={this.state.isLocked || this.props.isLocked}
                                            >
                                                {this.state.isLocked || this.props.isLocked ?
                                                    <Box component={'img'} src={ImagePaths.DisabledEdit.default} height={'1.2vw'} width={'1.2vw'} />
                                                    :
                                                    <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                                                }
                                            </Button>
                                        </Box>
                                    </TableCell>}
                                </TableRow>
                            )) : null}
                            {this.state.templateDataLeft?.length > 0 ? this.state.templateDataLeft?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>LE</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.SPH ? item?.SPH : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.CYL ? item?.CYL : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>                                        
                                        {item?.AXIS ? item?.AXIS?.length > 10 ?
                                        <Tooltip placement='top' title={item?.AXIS}>
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.AXIS?.slice(0, 10) + '...'}</Typography>
                                        </Tooltip> :
                                        <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.AXIS}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {item?.BCDVA ? item?.BCDVA?.length > 10 ?
                                            <Tooltip placement='top' title={item?.BCDVA}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.BCDVA?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.BCDVA}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {item?.add ? item?.add?.length > 10 ?
                                            <Tooltip placement='top' title={item?.add}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.add?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.add}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {item?.BCNVA ? item?.BCNVA?.length > 10 ?
                                            <Tooltip placement='top' title={item?.BCNVA}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.BCNVA?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.BCNVA}</Typography> : '-'}
                                    </TableCell>
                                    {this.state.comparePopup ? null : <TableCell align='center'>
                                        <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                            <Button
                                                className='Common_Btn_Min_Width'
                                                onClick={() => { this.setState({ isEdit: true, openPopUp: true, value: "One" }, () => { this.onEditHandler(item) }) }}
                                                disabled={this.state.isLocked || this.props.isLocked}
                                            >
                                                {this.state.isLocked || this.props.isLocked ?
                                                    <Box component={'img'} src={ImagePaths.DisabledEdit.default} height={'1.2vw'} width={'1.2vw'} />
                                                    :
                                                    <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                                                }
                                            </Button>
                                        </Box>
                                    </TableCell>}
                                </TableRow>
                            )) : null}
                        </TableBody>
                    </Table>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    handleDatePicker = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString('en-GB');
        return formattedDate.split("/").reverse().join("-");
    }

    checkValidation = () => {
        var { LeftEye, RightEye } = this.state
        if ((LeftEye.SPH == "" && LeftEye.CYL == "" && LeftEye.AXIS == "" && LeftEye.BCDVA == "" && LeftEye.ADD == "" && LeftEye.BCNVA == "") &&
            (RightEye.SPH == "" && RightEye.CYL == "" && RightEye.AXIS == "" && RightEye.BCDVA == "" && RightEye.ADD == "" && RightEye.BCNVA == "")) {
            return "Select Any Eye Value"
        } else {
            return null
        }
    }

    CheckEyeValues = () => {
        let states = this.state
        if (this.state.templateDataLeft.length > 0 && this.state.isEdit) {
            let item = this.state.templateDataLeft[0]
            states.LeftEye["id"] = item?.id
            this.setState({
                states,
                frameType: this.state.frameType,
                date: this.state.date
            }, () => { this.addPreviousGlassPower() })
        } else if (this.state.templateDataRight.length > 0 && this.state.isEdit) {
            let item = this.state.templateDataRight[0]
            states.RightEye["id"] = item?.id
            this.setState({
                states,
                frameType: this.state.frameType,
                date: this.state.date
            }, () => { this.addPreviousGlassPower() })
        } else {
            this.addPreviousGlassPower()
        }
    }

    addPreviousGlassPower = () => {
        var { LeftEye, RightEye, frameType, appointmentId } = this.state
        try {
            var currentDate = new Date();
            var cDate = currentDate.getDate();
            cDate = cDate < 10 ? '0' + cDate : cDate;
            var cMonth = currentDate.getMonth() + 1;
            cMonth = cMonth < 10 ? '0' + cMonth : cMonth;
            var cYear = currentDate.getFullYear();
            var date = cYear + "-" + cMonth + "-" + cDate

            let data = [];

            if (
                (LeftEye.SPH != "" || LeftEye.CYL != "" || LeftEye.AXIS != "" || LeftEye.BCDVA != "" || LeftEye.ADD != "" || LeftEye.BCNVA != "") &&
                (RightEye.SPH != "" || RightEye.CYL != "" || RightEye.AXIS != "" || RightEye.BCDVA != "" || RightEye.ADD != "" || RightEye.BCNVA != "")
            ) {
                data = [
                    {
                        appointment_id: appointmentId,
                        eye_type: "LE",
                        date: this.state.date ? this.handleDatePicker(this.state.date) : date,
                        SPH: LeftEye.SPH ? LeftEye.SPH : "",
                        CYL: LeftEye.CYL ? LeftEye.CYL : "",
                        AXIS: LeftEye.AXIS ? LeftEye.AXIS : "",
                        frame_type: frameType ? frameType : "",
                        BCDVA: LeftEye.BCDVA ? LeftEye.BCDVA : "",
                        add: LeftEye.ADD ? LeftEye.ADD : "",
                        BCNVA: LeftEye.BCNVA ? LeftEye.BCNVA : "",
                        comment: LeftEye.comments ? LeftEye.comments : ""
                    },
                    {
                        appointment_id: appointmentId,
                        eye_type: "RE",
                        date: this.state.date ? this.handleDatePicker(this.state.date) : date,
                        SPH: RightEye.SPH ? RightEye.SPH : "",
                        CYL: RightEye.CYL ? RightEye.CYL : "",
                        AXIS: RightEye.AXIS ? RightEye.AXIS : "",
                        frame_type: frameType ? frameType : "",
                        BCDVA: RightEye.BCDVA ? RightEye.BCDVA : "",
                        add: RightEye.ADD ? RightEye.ADD : "",
                        BCNVA: RightEye.BCNVA ? RightEye.BCNVA : "",
                        comment: RightEye.comments ? RightEye.comments : ""
                    }
                ]

                if (LeftEye.id) { data[0]['id'] = LeftEye.id }
                if (RightEye.id) { data[1]['id'] = RightEye.id }
            }
            else if (LeftEye.SPH != "" || LeftEye.CYL != "" || LeftEye.AXIS != "" || LeftEye.BCDVA != "" || LeftEye.ADD != "" || LeftEye.BCNVA != "") {
                data = [
                    {
                        appointment_id: appointmentId,
                        eye_type: "LE",
                        date: this.state.date ? this.handleDatePicker(this.state.date) : date,
                        SPH: LeftEye.SPH ? LeftEye.SPH : "",
                        CYL: LeftEye.CYL ? LeftEye.CYL : "",
                        AXIS: LeftEye.AXIS ? LeftEye.AXIS : "",
                        frame_type: frameType ? frameType : "",
                        BCDVA: LeftEye.BCDVA ? LeftEye.BCDVA : "",
                        add: LeftEye.ADD ? LeftEye.ADD : "",
                        BCNVA: LeftEye.BCNVA ? LeftEye.BCNVA : "",
                        comment: LeftEye.comments ? LeftEye.comments : ""
                    }
                ]
                if (LeftEye.id) { data[0]['id'] = LeftEye.id }
            }
            else if (RightEye.SPH != "" || RightEye.CYL != "" || RightEye.AXIS != "" || RightEye.BCDVA != "" || RightEye.ADD != "" || RightEye.BCNVA != "") {
                data = [
                    {
                        appointment_id: appointmentId,
                        eye_type: "RE",
                        date: this.state.date ? this.handleDatePicker(this.state.date) : date,
                        SPH: RightEye.SPH ? RightEye.SPH : "",
                        CYL: RightEye.CYL ? RightEye.CYL : "",
                        AXIS: RightEye.AXIS ? RightEye.AXIS : "",
                        frame_type: frameType ? frameType : "",
                        BCDVA: RightEye.BCDVA ? RightEye.BCDVA : "",
                        add: RightEye.ADD ? RightEye.ADD : "",
                        BCNVA: RightEye.BCNVA ? RightEye.BCNVA : "",
                        comment: RightEye.comments ? RightEye.comments : ""
                    }
                ]
                if (RightEye.id) { data[0]['id'] = RightEye.id }
            }

            let validate = this.checkValidation()
            if (validate == null) {
                this.setState({ postLoad: true })
                RestAPIService.create(data, Serviceurls.DOC_PREVIOUS_GLASS_POWER)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.successMessage(response.data.message)
                            this.handleClear()
                            this.setState({ postLoad: false, openPopUp: false, comparePopup: false, isEdit: false }, () => {
                                this.getPreviousGlassPower()
                            })
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.setState({ postLoad: false })
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.setState({ postLoad: false })
                            this.errorMessage(error.message)
                        }
                    })
            } else { this.errorMessage(validate) }
        } catch (error) {
            this.setState({ postLoad: false })
            this.errorMessage(error.message)
        }
    }

    renderNewText = (Heading, values, Tab) => {
        let states = this.state
        return (
            <div style={{ display: "flex", flexDirection: "row", marginLeft: "0.5vw" }}>
                <TextField
                    label={(`Add ${Heading}`)}
                    size="small"
                    style={{ marginTop: "0.5vw", width: "20vw" }}
                    variant="outlined"
                    value={states.NewText}
                    onChange={(event) => {
                        let value = event.target.value
                        this.setState({ NewText: value })
                    }}
                />
                <div style={{ marginLeft: "0.5vw", marginTop: "0.65vw", cursor: "pointer" }}
                    onClick={() => {
                        if (this.state.NewText) {
                            let data = { label: CommonValidation.removeSpace(this.state.NewText), value: CommonValidation.removeSpace(this.state.NewText) }
                            values.push(data)
                            states[Tab][Heading] = this.state.NewText
                            this.setState({ states }, () => { this.setState({ selectedText: "", NewText: "" }) })
                        }
                    }}>
                    <img className="eMed_Tick_img" style={{ height: "2vw", width: "2vw" }} src={ImagePaths.Tick.default} alt={"Tick"} />
                </div>
                <div style={{ marginLeft: "0.5vw", marginTop: "0.65vw", cursor: "pointer" }}
                    onClick={() => {
                        this.setState({ selectedText: "", NewText: "" })
                    }}>
                    <img className="eMed_Tick_img" style={{ height: "2vw", width: "2vw" }} src={ImagePaths.CancelIcon.default} alt={"Close"} />
                </div>
            </div>
        )
    }


    renderFrameType = (Heading, values, key, Tab) => {
        let states = this.state
        return (
            <div>
                <div style={{ marginTop: "0.5vw", fontWeight: "600" }}>{Heading}</div>
                <div style={{ display: "flex", flexWrap: "wrap", marginTop: "0.5vw" }}>
                    {
                        values && values.map((item, index) => (
                            <Chip
                                className={`eMed_chip ${item.label === key ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                style={{ width: "9vw" }}
                                label={item?.label}
                                variant={item.label === key ? 'contained' : 'outlined'}
                                key={index}
                                onClick={() => {
                                    if (item.label === key) {
                                        states[Tab] = ""
                                        this.setState({ states })
                                    } else {
                                        states[Tab] = item?.value
                                        this.setState({ states })
                                    }
                                }}
                                clickable
                            />
                        ))
                    }
                </div>
            </div>
        )
    }

    renderGlassPower = (Heading, values, key, Tab, AddButton, ref, scrollTo) => {
        let states = this.state
        let sortedValue = values
        let { SPHselectedValues, CYLselectedValues,ADDselectedValues ,BCDVAselectedValues} = this.state
        if(Heading == "SPH"){
            if(SPHselectedValues != "All"){
                sortedValue = sortedValue.filter((item) => SPHselectedValues === "plus"  ? item?.value >= 0  :  SPHselectedValues === "Minus" ? item?.value <= 0 : sortedValue)
            }
        }
        else if(Heading == "CYL"){
            if(CYLselectedValues != "All"){
                sortedValue = sortedValue.filter((item) => CYLselectedValues === "plus"  ?item?.value >= 0  : CYLselectedValues === "Minus" ? item?.value <= 0 : sortedValue)
            }
        }
        else if(Heading == "ADD"){
            if(ADDselectedValues != "All"){
                sortedValue = sortedValue.filter((item) => ADDselectedValues === "plus"  ? item?.value > 0  : ADDselectedValues === "Minus" ? item?.value < 0 : sortedValue )
            }
        }
        else if(Heading == "BCDVA"){
            if(BCDVAselectedValues != "All"){
                sortedValue = sortedValue.filter((item) => BCDVAselectedValues === "plus"  ? item?.value > 0  : BCDVAselectedValues === "Minus" ? item?.value < 0 : sortedValue)
            }
        }  
     
        return (
            <div>
                <div style={{ marginTop: "0.5vw", fontWeight: "600" }}>{Heading}</div>
                <Box component={'div'} sx={{ display: "flex", flexWrap: "wrap", marginLeft: "1vw" }}>
                        {
                           ( Heading === "SPH" ||   Heading === "CYL" ||  Heading === "ADD"||Heading === "BCDVA" ) ?
                                <RadioGroup
                                    value={ Heading === "SPH"  ? this.state.SPHselectedValues  :Heading === "CYL"  ? this.state.CYLselectedValues :Heading === "ADD"  ?this.state.ADDselectedValues:this.state.BCDVAselectedValues}
                                    sx={{ display: 'flex', flexDirection: 'row' , marginTop :"-4vh", marginLeft :"3vw"  }}
                                    onChange={(e, values) => {
                                        if(Heading === "SPH"){
                                            this.setState({ SPHselectedValues: e.target.value })
                                        }
                                        else if(Heading === "CYL"){
                                            this.setState({ CYLselectedValues: e.target.value })
                                        }
                                        else if(Heading === "ADD"){
                                            this.setState({ ADDselectedValues: e.target.value })
                                        }
                                        else if(Heading === "BCDVA"){
                                            this.setState({ BCDVAselectedValues: e.target.value })
                                        }
                                        
                                    }}
                                >
                                    {Heading === 'SPH' || Heading === 'CYL' ? null : <FormControlLabel control={<Radio inputProps = {{emed_tid : "All_testID"}} size='small' value={"All"}  />} label="All" />}
                                    <FormControlLabel control={<Radio inputProps = {{emed_tid : "plus_testID"}} size='small' value={"plus"}  />} label="+" />
                                    <FormControlLabel control={<Radio inputProps = {{emed_tid : "Minus_testID"}} size='small' value={"Minus"} />} label="-" />
                                </RadioGroup> : 
                               null
                        }
                    </Box>
                <div  ref={ref}  style={{ display: "flex", flexWrap: "wrap", marginTop: "0.5vw" }}>
                    {
                        sortedValue && sortedValue.map((item, index) => (
                            <Chip
                                className={`eMed_chip ${item.label === key ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                style={{ width: item?.label.length > 6 ? "5vw" : "4vw" }}
                                label={item?.label?.length > 6 ? <Tooltip placement='top' title={item?.label}><div style={{ fontSize: '0.7vw' }}>{item?.label?.slice(0, 5)+ '...'}</div></Tooltip> : item?.label}
                                variant={item.label === key ? 'contained' : 'outlined'}
                                key={index}
                                onClick={() => {
                                    if (Tab == "LeftEye" && this.state.Leftchecked) {
                                        this.CopyLefttoRight(!this.state.Leftchecked)
                                    } else if (Tab == "RightEye" && this.state.Rightchecked) {
                                        this.CopyRighttoLeft(!this.state.Rightchecked)
                                    }

                                    if (item.label === key) {
                                        states[Tab][Heading] = ""
                                        this.setState({ states })
                                    } else {
                                        states[Tab][Heading] = item?.value
                                        this.setState({ states })
                                    }
                                    this.scrollToRef(scrollTo)
                                }}
                                clickable
                            />
                        ))
                    }
                </div>
                <div>
                    {AddButton && this.state.selectedText != Heading ?
                        <Button
                            sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                            variant='contained'
                            onClick={() => { this.setState({ selectedText: Heading, NewText: "" }) }}
                        >Add</Button>
                        : null}
                </div>
            </div>
        )
    }

    renderRightSideValues = (Heading, value) => {
        return (
            <div style={{ marginLeft: "0.85vw", marginTop: "0.75vw" }}>
                <div>{Heading}</div>
                <div className="eMed_BoxShadow">
                    {value?.length > 13 ? <Tooltip title={value} placement='top' arrow><div>{value?.slice(0, 10) + "..."}</div></Tooltip>: value}
                </div>
            </div>
        )
    }

    CopyLefttoRight = (value) => {
        let { LeftEye } = this.state
        let states = this.state
        if (value) {
            states.RightEye["SPH"] = LeftEye.SPH
            states.RightEye["CYL"] = LeftEye.CYL
            states.RightEye["AXIS"] = LeftEye.AXIS
            states.RightEye["ADD"] = LeftEye.ADD
            states.RightEye["BCDVA"] = LeftEye.BCDVA
            states.RightEye["BCNVA"] = LeftEye.BCNVA
            this.setState({
                Leftchecked: value,
                Rightchecked: false,
                value: this.state.isEdit ? "One" : "Two",
                states
            })
        } else {
            states.RightEye["SPH"] = ""
            states.RightEye["CYL"] = ""
            states.RightEye["AXIS"] = ""
            states.RightEye["ADD"] = ""
            states.RightEye["BCDVA"] = ""
            states.RightEye["BCNVA"] = ""
            this.setState({
                Leftchecked: value,
                Rightchecked: false,
                states
            })
        }
    }

    CopyRighttoLeft = (value) => {
        let { RightEye } = this.state
        let states = this.state
        if (value) {
            states.LeftEye["SPH"] = RightEye.SPH
            states.LeftEye["CYL"] = RightEye.CYL
            states.LeftEye["AXIS"] = RightEye.AXIS
            states.LeftEye["ADD"] = RightEye.ADD
            states.LeftEye["BCDVA"] = RightEye.BCDVA
            states.LeftEye["BCNVA"] = RightEye.BCNVA
            this.setState({
                Rightchecked: value,
                Leftchecked: false,
                value: this.state.isEdit ? "Two" : "One",
                states
            })
        } else {
            states.LeftEye["SPH"] = ""
            states.LeftEye["CYL"] = ""
            states.LeftEye["AXIS"] = ""
            states.LeftEye["ADD"] = ""
            states.LeftEye["BCDVA"] = ""
            states.LeftEye["BCNVA"] = ""
            this.setState({
                Rightchecked: value,
                Leftchecked: false,
                states
            })
        }
    }

    handleClear = () => {
        if (this.state.isEdit) {
            this.setState({
                frameType: "",
                Leftchecked: false,
                Rightchecked: false,
                LeftEye: {
                    SPH: "",
                    CYL: "",
                    AXIS: "",
                    BCDVA: "",
                    ADD: "",
                    BCNVA: "",
                    comments: "",
                    id: this.state.LeftEye.id
                },
                RightEye: {
                    SPH: "",
                    CYL: "",
                    AXIS: "",
                    BCDVA: "",
                    ADD: "",
                    BCNVA: "",
                    comments: "",
                    id: this.state.RightEye.id
                },
                date: new Date(),
            })
        } else {
            this.setState({
                LeftEye: {
                    SPH: "",
                    CYL: "",
                    AXIS: "",
                    BCDVA: "",
                    ADD: "",
                    BCNVA: "",
                    comments: ""
                },
                RightEye: {
                    SPH: "",
                    CYL: "",
                    AXIS: "",
                    BCDVA: "",
                    ADD: "",
                    BCNVA: "",
                    comments: ""
                },
                Leftchecked: false,
                Rightchecked: false,
                disableLeft: false,
                disableRight: false,
                postLoad: false,
                frameType: "",
                value: "Two",
                date: new Date(),
                isEdit: false
            })
        }
    }

    getPreviousGlassPowerPrint = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_PREVIOUS_GLASS_POWER + `?appointment_id=${this.state.appointmentId}&export_type=pdf`)
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    PreviousPowerPopup = () => {
        return (
            <div>
                <Modal open={this.state.comparePopup}>
                    <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up' style={{ width: "65vw", height: "55vh", maxHeight: "100%" }}>
                        <Box component={'div'} p={'0.75vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={{ borderBottom: "1px solid gray" }}>
                            <Typography fontSize={'0.95vw'} fontWeight={600} color={Colors.SecondaryText}>View Previous Glass Power</Typography>
                            <Button
                                className='Common_Btn_Min_Width'
                                onClick={() => this.setState({ comparePopup: false })}
                            >
                                <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                            </Button>
                        </Box>
                        <Box component={'div'} style={{ paddingLeft: "0.5vw", paddingRight: "0.5vw" }}>
                            <Box component={'div'}>
                                <div style={{ marginTop: "0.65vw" }}>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "0.5vw" }}>
                                        <Typography fontSize={'0.95vw'} fontWeight={600} color={Colors.SecondaryText}>Current Glass Power</Typography>
                                        <div className="eMed_icon_styles" style={{ backgroundColor: Colors.ThemeColor, marginLeft: "0.65vw", marginTop: "-0.2vw" }} onClick={() => { this.CopyPreviousData() }}>
                                            <Tooltip placement='top' title='Copy'>
                                                <Box component={'img'} src={ImagePaths.copy.default} height={'1.5vw'} width={'1.5vw'} />
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div>{this.renderOldGlassPowerTable()}</div>
                                </div>
                            </Box>
                            <Box component={'div'} sx={{ marginTop: "0.65vw" }}>
                                <div style={{ marginTop: "0.65vw" }}>
                                    <Typography fontSize={'0.95vw'} fontWeight={600} style={{ marginBottom: "0.5vw" }} color={Colors.SecondaryText}>New Glass Power</Typography>
                                    {this.renderPreviousGlassPowerTable()}
                                </div>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            </div>
        )
    }

    scrollToRef = (ref) => {
        ref?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    }

    PreviousGlassPopup = () => {
        let { LeftEye, RightEye, selectedText } = this.state
        return (
            <div>
                <Modal open={this.state.openPopUp}>
                    <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up' style={{ width: "78vw", height: "90vh", maxHeight: "100%" }}>
                        <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Previous Glass Power</Typography>
                            <Button
                                className='Common_Btn_Min_Width'
                                onClick={() => this.setState({ openPopUp: false, isEdit: false }, () => { this.handleClear() })}
                            >
                                <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                            </Button>
                        </Box>
                        <Box component={'div'}>
                            <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                <div style={{ width: "60%" }}>
                                    <Tabs
                                        value={this.state.value}
                                        onChange={(e, newValue) => this.setState({
                                            value: newValue
                                        }, () => this.scrollToRef(this.SPH_REF))}
                                    >
                                        <Tab value="Two" disabled={this.state.disableRight} label={"Right Eye"} className='eMed_tab_header' />
                                        <Tab value="One" disabled={this.state.disableLeft} label={"Left Eye"} className='eMed_tab_header' />
                                    </Tabs>
                                    {
                                        this.state.value === "One" ?
                                            <Box component={"div"} style={{ padding: "0.75vw" }}>
                                                <Box className="eMedhub_Shadow_Properties" sx={{ width: "100%", height: "70vh", paddingLeft: "0.5vw", overflow: "scroll" }}>
                                                    <div>
                                                        {this.renderGlassPower("SPH",SPH_OPTIONS, LeftEye.SPH, "LeftEye", null, this.SPH_REF, this.CYL_REF)}
                                                        {this.renderGlassPower("CYL", CYL_OPTIONS, LeftEye.CYL, "LeftEye", null, this.CYL_REF, this.AXIS_REF)}
                                                        {this.renderGlassPower("AXIS", AXIS_OPTIONS, LeftEye.AXIS, "LeftEye", null, this.AXIS_REF, this.ADD_REF)}
                                                        {this.renderGlassPower("ADD",ADD, LeftEye.ADD, "LeftEye", true, this.ADD_REF, this.BCDVA_REF)}
                                                        {selectedText == "ADD" ? this.renderNewText("ADD", ADD, "LeftEye") : null}
                                                        {this.renderGlassPower("BCDVA",  BCDVA_OPTIONS, LeftEye.BCDVA, "LeftEye", true, this.BCDVA_REF, this.BCNVA_REF)}
                                                        {selectedText == "BCDVA" ? this.renderNewText("BCDVA", BCDVA_OPTIONS, "LeftEye") : null}
                                                        {this.renderGlassPower("BCNVA", BCNVA, LeftEye.BCNVA, "LeftEye", true)}
                                                        {selectedText == "BCNVA" ? this.renderNewText("BCNVA", BCNVA, "LeftEye", this.BCNVA_REF, this.BCNVA_REF) : null}
                                                    </div>
                                                </Box>
                                            </Box>
                                            : this.state.value === "Two" ?
                                                <Box component={"div"} style={{ padding: "0.75vw" }}>
                                                    <Box className="eMedhub_Shadow_Properties" sx={{ width: "100%", height: "70vh", paddingLeft: "0.5vw", overflow: "scroll" }}>
                                                        <div>
                                                            {this.renderGlassPower("SPH",SPH_OPTIONS, RightEye.SPH, "RightEye", null, this.SPH_REF, this.CYL_REF)}
                                                            {this.renderGlassPower("CYL",CYL_OPTIONS, RightEye.CYL, "RightEye", null, this.CYL_REF, this.AXIS_REF)}
                                                            {this.renderGlassPower("AXIS", AXIS_OPTIONS, RightEye.AXIS, "RightEye", null, this.AXIS_REF, this.ADD_REF)}
                                                            {this.renderGlassPower("ADD", ADD, RightEye.ADD, "RightEye", true, this.ADD_REF, this.BCDVA_REF)}
                                                            {selectedText == "ADD" ? this.renderNewText("ADD", ADD, "RightEye") : null}
                                                            {this.renderGlassPower("BCDVA", BCDVA_OPTIONS, RightEye.BCDVA, "RightEye", true, this.BCDVA_REF, this.BCNVA_REF)}
                                                            {selectedText == "BCDVA" ? this.renderNewText("BCDVA", BCDVA_OPTIONS, "RightEye") : null}
                                                            {this.renderGlassPower("BCNVA",  BCNVA, RightEye.BCNVA, "RightEye", null, this.BCNVA_REF, this.BCNVA_REF)}
                                                            {selectedText == "BCNVA" ? this.renderNewText("BCNVA", BCNVA, "RightEye", true) : null}
                                                        </div>
                                                    </Box>
                                                </Box>
                                                : null
                                    }
                                </div>
                                <div style={{ width: "40%" }}>
                                    <Box component={'div'} style={{ marginTop: "4.4vw", marginLeft: "1vw", height: "67vh" }}>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                            <div style={{ fontWeight: "600", marginBottom: "0.75vw" }}>{this.state.value === "One" ? "Left Eye" : "Right Eye"}</div>
                                            <div style={{ marginRight: "1vw", marginTop: "-1vw" }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        value={this.state.date ? moment(this.state.date) : null}
                                                        inputFormat='DD-MM-YYYY'
                                                        style={{ width: '20%' }}
                                                        label={"Select Date"}
                                                        maxDate={new Date()}
                                                        onChange={
                                                            (newDate) => {
                                                                if (newDate == null) {
                                                                    this.setState({
                                                                        date: new Date()
                                                                    });
                                                                } else {
                                                                    this.setState({
                                                                        date: newDate.$d
                                                                    });
                                                                }
                                                            }}
                                                        renderInput={(params) => <TextField size='small' onChange={(newDate) => { if (newDate.$d == "Invalid Date") { this.setState({ date: this.state.date }) } }} {...params} />}
                                                    />
                                                </LocalizationProvider>
                                            </div>
                                        </div>
                                        {this.state.value === "One" ?
                                            <div>
                                                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", marginLeft: "-1vw" }}>
                                                    {this.renderRightSideValues("SPH", LeftEye.SPH)}
                                                    {this.renderRightSideValues("CYL", LeftEye.CYL)}
                                                    {this.renderRightSideValues("AXIS", LeftEye.AXIS)}
                                                    {this.renderRightSideValues("ADD", LeftEye.ADD)}
                                                    {this.renderRightSideValues("BCDVA", LeftEye.BCDVA)}
                                                    {this.renderRightSideValues("BCNVA", LeftEye.BCNVA)}
                                                </div>
                                                <Box sx={{ marginTop: "1vw" }}>
                                                    <FormControlLabel checked={this.state.Leftchecked} control={<Checkbox checked={this.state.Leftchecked} onClick={(e) => { this.CopyLefttoRight(!this.state.Leftchecked) }} />} label="Copy to Right Eye (RE)" />
                                                </Box>
                                            </div> :
                                            <div>
                                                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", marginLeft: "-1vw" }}>
                                                    {this.renderRightSideValues("SPH", RightEye.SPH)}
                                                    {this.renderRightSideValues("CYL", RightEye.CYL)}
                                                    {this.renderRightSideValues("AXIS", RightEye.AXIS)}
                                                    {this.renderRightSideValues("ADD", RightEye.ADD)}
                                                    {this.renderRightSideValues("BCDVA", RightEye.BCDVA)}
                                                    {this.renderRightSideValues("BCNVA", RightEye.BCNVA)}
                                                </div>
                                                <Box sx={{ marginTop: "1vw" }}>
                                                    <FormControlLabel checked={this.state.Rightchecked} control={<Checkbox checked={this.state.Rightchecked} onClick={(e) => { this.CopyRighttoLeft(!this.state.Rightchecked) }} />} label="Copy to Left Eye (LE)" />
                                                </Box>
                                            </div>}
                                        <div style={{ marginTop: "1vw" }}>
                                            {this.renderFrameType("Select Frame Type", FrameList, this.state.frameType, "frameType")}
                                        </div>
                                        <div style={{ marginTop: "1vw" }}>
                                            <div style={{ marginTop: "0.5vw", fontWeight: "600" }}>{"Comments"}</div>
                                            <div >
                                                <TextField
                                                    label={("Enter Comments")}
                                                    style={{ height: "5vw", marginTop: "0.5vw", width: "95%" }}
                                                    multiline={true}
                                                    rows={3}
                                                    variant="outlined"
                                                    value={this.state.value === "One" ? LeftEye.comments : RightEye.comments}
                                                    onChange={(event) => {
                                                        var value = event.target.value
                                                        if (this.state.value === "One") {
                                                            if (value?.length <= 250) {
                                                                LeftEye.comments = value
                                                                this.setState({
                                                                    LeftEye
                                                                })
                                                            } else { this.errorMessage('Allowed only 250 characters') }
                                                        } else {
                                                            if (value?.length <= 250) {
                                                                RightEye.comments = event.target.value
                                                                this.setState({
                                                                    RightEye
                                                                })
                                                            }
                                                            else { this.errorMessage('Allowed only 250 characters') }
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Box>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-end", marginTop: "-0.75vw", marginRight: "0.75vw" }}>
                                <Button
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                                    variant='outlined'
                                    onClick={() => { this.handleClear() }}
                                >Clear</Button>
                                <Button
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                                    disabled={this.state.postLoad}
                                    variant='contained'
                                    onClick={() => { this.CheckEyeValues() }}
                                >Add</Button>
                            </div>
                        </Box>
                    </Box>
                </Modal>
            </div>
        )
    }

    renderBottomArea = (Title, value) => {
        return (
            <div style={{ marginRight: "0.5vw", width: "12vw", marginLeft: "0.5vw" }}>
                <div>{Title}</div>
                {value && value?.length > 15 ?
                    <Tooltip placement='top' title={value}><div style={{ fontWeight: "600", color: "black" }}>{value?.slice(0, 15) + '...'}</div></Tooltip> :
                    <div style={{ fontWeight: "600", color: "black", fontSize: "0.9vw" }}>{value ? value : "-"}</div>
                }
            </div>
        )
    }

    render() {
        let showDate = this.state.templateDataRight.length > 0 ? this.state.templateDataRight[0]?.date : this.state.templateDataLeft.length > 0 ? this.state.templateDataLeft[0]?.date : null
        let ShowFrame = this.state.templateDataRight.length > 0 ? this.state.templateDataRight[0]?.frame_type : this.state.templateDataLeft.length > 0 ? this.state.templateDataLeft[0]?.frame_type : null
        return (
            <ShowComponents onIntersection={this.apiCalls.bind(this)}>
                <Box component={'div'} sx={{ backgroundColor: Colors.white }}>
                    <Box component={'div'} display={'flex'} alignItems={'center'} p={'0.5vw'} justifyContent={'space-between'} sx={{ backgroundColor: Colors.Background }} height={'3.5vw'}>
                        <Typography fontWeight={600}>Previous Glass Power</Typography>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <Button
                                className='Common_Btn_Min_Width'
                                onClick={() => this.getPreviousGlassPowerPrint()}
                                style={{ marginTop: "-0.15vw" }}
                                disabled={this.state.templateDataRight?.length > 0 || this.state.templateDataLeft?.length > 0 ? false : true}
                            >
                                <Tooltip placement='top' title='Print'>
                                    <Box component={'img'} src={ImagePaths.PrintIcons.default} height={'2vw'} width={'2vw'} />
                                </Tooltip>
                            </Button>
                            <div className="eMed_icon_styles" style={{ backgroundColor: this.state.PreviousData?.length > 0 && !this.props.isLocked ? Colors.ThemeColor : Colors.disableComponentColor }} onClick={() => { if (this.state.PreviousData?.length > 0 && !this.props.isLocked) { this.CopyPreviousData() } }}>
                                <Tooltip placement='top' title='Copy Previous Glass Power'>
                                    <Box component={'img'} src={ImagePaths.copy.default} height={'1.5vw'} width={'1.5vw'} />
                                </Tooltip>
                            </div>
                            <div className="eMed_icon_styles" style={{ backgroundColor: (this.state.PreviousData?.length > 0 && this.state.currentData?.length > 0) && !this.props.isLocked ? Colors.ThemeColor : Colors.disableComponentColor }} onClick={() => { if (this.state.PreviousData?.length > 0 && this.state.currentData?.length > 0 && !this.props.isLocked) { this.setState({ comparePopup: true }) } }}>
                                <Tooltip placement='top' title='Compare'>
                                    <Box component={'img'} src={ImagePaths.EyeView.default} height={'1.5vw'} width={'1.5vw'} />
                                </Tooltip>
                            </div>
                            <Button
                                disabled={this.state.isLocked || this.props.isLocked || this.state.currentData.length > 1}
                                variant='contained'
                                sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mt: '0.2vw', ml: '0.5vw' }}
                                onClick={() => {
                                    this.setState({
                                         openPopUp: true ,
                                         SPHselectedValues :"Minus",
                                         CYLselectedValues :"Minus",
                                         ADDselectedValues :"All",
                                         BCDVAselectedValues :"All",
                                        })
                                }}
                            >Add</Button>
                        </div>
                    </Box>
                    <Box component={'div'} p={'0.5vw'}>
                        <Box component={'div'}>
                            {this.renderPreviousGlassPowerTable()}
                        </Box>
                    </Box>
                    <Box component={'div'} p={'0.5vw'} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                        {this.state.templateDataRight.length > 0 || this.state.templateDataLeft.length > 0 ? this.renderBottomArea("Date", (showDate != null && showDate != "Invalid Date" && typeof showDate === 'string' ? showDate?.split("-").reverse().join("-") : "-")) : null}
                        {this.state.templateDataRight.length > 0 || this.state.templateDataLeft.length > 0 ? this.renderBottomArea("Frame Type", ShowFrame) : null}
                        {/* {this.state.templateDataRight[0]?.comment ? this.renderBottomArea("RE - Comments", this.state.templateDataRight[0]?.comment) : null}
                        {this.state.templateDataLeft[0]?.comment ? this.renderBottomArea("LE - Comments", this.state.templateDataLeft[0]?.comment) : null} */}
                    </Box>
                    {this.PreviousGlassPopup()}
                    {this.PreviousPowerPopup()}
                    {this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                </Box>
            </ShowComponents>
        )
    }

}